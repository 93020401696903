import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import axios from "../../services/axios/axios";
import endpoints from "../../services/api/endpoints";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import PageContent from "../PageContent/PageContent";
import PaywallError from "../../error/paywallError";
import AuthError from "../../error/authError";
import {isAdmin} from "../../services/User/User";
import {Link} from "react-router-dom";
import routes from "../../routes";
import NutritionInfo from "../NutritionInfo/NutritionInfo";
import FoodPreference from "../FoodPreference/FoodPreference";

class ProductDetails extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            product: false,
            errorMessage: null,
            isAdmin: isAdmin(),
            notFound: false,
        };
    };

    componentDidMount = () => {
        const {onNotLoggedIn, onPaywall, onMetaUpdate} = this.props

        const currentState = {
            ...this.state
        };

        axios.get(endpoints.productBySlug + "/" + this.props.match.params.slug)
            .then((response) => {
                currentState.product = response.data;
                currentState.isLoaded = true;
                this.setState(currentState)
                onMetaUpdate({
                    metaTitle: currentState.product.name,
                    metaCanonical: routes.products + "/" + currentState.product.slug,
                    metaDescription: "Wartości odżywcze produktu w 100g.: Energia: " + currentState.product.kcal + "kcal. Białko: " + currentState.product.protein.toFixed(2) + "g. " +
                        "Tłuszcz: " + currentState.product.fat.toFixed(2) + "g. " +
                        "Węglowodany: " + currentState.product.carbohydrate.toFixed(2) + "g. " +
                        "Błonnik: " + currentState.product.fiber.toFixed(2) + "g."
                })
            })
            .catch((error) => {
                if (error instanceof PaywallError) {
                    currentState.errorMessage = "Dostęp wymaga aktywnej subskrypcji.";
                    onPaywall()
                } else if (error instanceof AuthError) {
                    currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                    onNotLoggedIn()
                } else {
                    if (error.response && error.response.status && error.response.status === 404) {
                        currentState.errorMessage = "Produkt nie znaleziony";
                        currentState.notFound = true;
                    } else {
                        currentState.errorMessage = "podczas pobierania danych o potrawie wystąpił błąd";
                    }
                }
                this.setState(currentState)
            });
    };

    render = () => {
        const {isLoaded, errorMessage, product, notFound} = this.state;

        if (!isLoaded) {
            if (errorMessage) {
                return <ErrorMessage message={errorMessage} withoutRefreshButton={notFound}/>
            } else {
                return <Loader/>
            }
        }
        return <PageContent disableMetaUpdate={true}
                            title={product.name}
        >
            {this.renderProductContent()}
        </PageContent>
    }

    renderProductContent() {
        const {product} = this.state;
        const {onNotLoggedIn} = this.props;
        const admin = isAdmin()

        return <>
            <div className={"dish_recipe"}>
                <FoodPreference objectId={product.id} objectType={"product"} onNotLoggedIn={onNotLoggedIn}/>
                <hr/>
                {admin ? <p>
                    <Link className={"btn_orange"} to={routes.adminProductForm + '/' + product.id}>edytuj</Link>
                </p> : ""}
            </div>

            <div className={"dish_recipe"} dangerouslySetInnerHTML={{__html: product.description}}>
            </div>

            <div className={"dish_components"}>
                <p className={'text-center'}>
                    <strong>Wartości odżywcze w 100g:</strong>
                </p>
                <NutritionInfo nutritionInfo={product}/>
            </div>
            <hr/>
        </>
    }
}

export default ProductDetails;
