import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {buySubscription, getSubscriptions, getUserActiveSubscriptions} from "../../services/api/api";
import orangeImg from "../../assets/img/oranges.jpg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import AuthError from "../../error/authError";
import ErrorWithCode from "../../error/errorWithCode";
import {isTempUser} from "../../services/User/User";
import routes from "../../routes";

class Subscriptions extends AbstractComponent {
    metaTitle = "Cennik"
    metaDescription = "Wykup pełny dostęp do wszystkich funkcjonalności Dieterian.com już od 7,42 za miesiąc i zacznij zmieniać swoje życie teraz!!"

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            items: [],
            activeUserSubscription: null,
            errorMessage: null,
        };
    }

    componentDidMount = async () => {
        const {onMetaUpdate} = this.props;
        onMetaUpdate({
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            metaCanonical: routes.subscriptions
        })

        await this.loadItems()
    }

    loadItems = async () => {
        const {isLoggedIn} = this.props;
        this.changeState("isLoaded", false);
        const currentState = {
            ...this.state
        }

        try {
            currentState.items = await getSubscriptions();
            if (isLoggedIn) {
                const userActiveSubscriptions = await getUserActiveSubscriptions(1);
                if (userActiveSubscriptions.length > 0) {
                    currentState.activeUserSubscription = userActiveSubscriptions[0]
                }
                currentState.isTempUser = isTempUser()
            }
        } catch (error) {
            currentState.errorMessage = "pobieranie danych nie powiodło się";
            console.log(error);
        }
        currentState.isLoaded = true;
        this.setState(currentState)
    }

    renderItem = (item) => {
        const {activeUserSubscription} = this.state;

        let validTo = activeUserSubscription === null ? new Date() : new Date(activeUserSubscription.valid_to);
        validTo.setDate(validTo.getDate() + item.duration_days);

        const promotionalPrice = item.old_price ?
            <><span className={"promotional-price"}><del>{(item.old_price / 100).toFixed(2)} PLN</del></span><br/></> :
            "";

        return (
            <div
                key={item.type}
                className={"col-lg-4"}
            >
                <div className={"subscription-item"}>
                    <div className={"subscription-title"}>
                        {item.name}
                        {item.is_hot ? <span className="subscription-promotional-text-badge">bestseller</span> : <></>}
                    </div>

                    <div className={"subscription-description"}>
                        {item.description}
                    </div>
                    <div className={"subscription-price"}>
                        {item.promotional_text ?
                            <span className="subscription-promotional-text">{item.promotional_text}</span> :
                            <span>&nbsp;</span>}
                        {promotionalPrice} {(item.price / 100).toFixed(2)} PLN <br/>
                        {
                            item.duration_days > 30 ?
                                <span className={"subscription-price-monthly"}>
                                {(item.price / item.duration_days * 30 / 100).toFixed(2)} PLN / 30 dni <br/>
                            </span> :
                                <span className={"subscription-price-monthly"}>&nbsp;<br/></span>
                        }
                    </div>
                    <p className={'text-center text-muted'}>najniższa cena z ostatnich 30
                        dni: <br/> {(item.price / 100).toFixed(2)} PLN</p>
                    <button className={"btn_default"}
                            data-type={item.type}
                            onClick={this.handleBuy}>
                        {activeUserSubscription ? 'Przedłuż swój dostęp do: ' : 'Wykup dostęp do: '}
                        <br/>{validTo.toISOString().split('T')[0]}
                    </button>
                </div>
            </div>
        )
    }

    handleBuy = async (e) => {
        const {onNotLoggedIn} = this.props
        e.preventDefault()
        const currentState = {...this.state}
        currentState.isLoaded = false;
        this.setState(currentState)
        try {
            const {type} = e.target.dataset;
            const paymentData = await buySubscription(type);
            document.forms['payment_form'].action = paymentData.payment_gateway_url;
            document.forms['payment_form']['id'].value = paymentData.id;
            document.forms['payment_form']['amount'].value = paymentData.amount;
            document.forms['payment_form']['description'].value = paymentData.description;
            document.forms['payment_form']['crc'].value = paymentData.crc;
            document.forms['payment_form']['md5sum'].value = paymentData.md5sum;
            document.forms['payment_form']['online'].value = paymentData.online;
            document.forms['payment_form']['return_url'].value = paymentData.return_url;
            document.forms['payment_form']['return_error_url'].value = paymentData.return_error_url;
            document.forms['payment_form']['language'].value = paymentData.language;

            document.getElementById('payment_form').submit();
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else if (error instanceof ErrorWithCode && error.code === 'temp_user') {
                window.location.href = routes.register + '?redirectUrl=' + routes.subscriptions;
            } else {
                currentState.errorMessage = "operacja nie powiodła się";
                console.log(error)
            }
        }

        this.setState(currentState)
    }

    renderForm = () => {
        return <form
            method="post"
            id="payment_form"
        >
            <input type="hidden" name="id" value=""/>
            <input type="hidden" name="amount" value=""/>
            <input type="hidden" name="description" value=""/>
            <input type="hidden" name="crc" value=""/>
            <input type="hidden" name="md5sum" value=""/>
            <input type="hidden" name="online" value=""/>
            <input type="hidden" name="return_url" value=""/>
            <input type="hidden" name="return_error_url" value=""/>
            <input type="hidden" name="language" value=""/>
        </form>
    }

    render = () => {
        const {onLogin} = this.props;
        const {items, isLoaded, errorMessage, activeUserSubscription, isTempUser} = this.state;
        if (errorMessage) {
            return <ErrorMessage message={errorMessage}/>
        }

        return (
            <div className="content_container">
                <h2>{this.metaTitle}</h2>

                {activeUserSubscription ? <p className={"seo_text text-center"}>Aktualna subskrypcja jest ważna
                    do: <strong>{new Date(activeUserSubscription.valid_to).toISOString().split('T')[0]}</strong>
                </p> : ''}

                <p className={"seo_text"}>
                    Aby móc korzystać w pełni z możliwości Dieterian.com wymagane jest
                    posiadanie subskrypcji.
                    Płatności za poniższe pakiety są jednorazowe (nie są cykliczne
                    - <strong>nie pobieramy danych odnośnie kart płatnicznych</strong>).
                    Płatność jest realizowana przez <a href={'https://tpay.com/'}
                                                       target={'_blank'}
                                                       rel={'noopener noreferrer'}>tpay.com</a>.
                    Przed wygaśnięciem subskrypcji przypomnimy Ci o tym drogą mailową, abyś
                    nie stracił(a) dostępu do swoich danych. Każdorazowo jest to <strong>Twoja
                    decyzja</strong> czy chcesz przedłużyć dostęp, <strong>nie pobieramy
                    opłat
                    automatycznie</strong>.
                    W każdej chwili możesz przedłużyć ważność swojego pakietu, dokupując
                    kolejny. W razie jakichkolwiek pytań prosimy o kontakt:
                    kontakt@dieterian.com. <br/><br/>
                    <strong>Dziękujemy Ci że jesteś z nami!</strong>
                </p>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle no_margin_bottom">
                    <div className="container">
                        <div className="row">
                            {isLoaded ?
                                items.map((item) => {
                                    return this.renderItem(item)
                                }) :
                                <Loader/>
                            }
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom no_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                        {this.renderForm()}
                    </div>
                </div>
            </div>
        )
    }
}

export default Subscriptions;
