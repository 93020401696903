import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function Editor(props) {
    const {value, onChange, id} = props;

    const onReactQuillChange = (value) => {
        onChange({
            target: {
                nodeName: 'Editor',
                type: 'Editor',
                value: value,
                dataset: {
                    id: id,
                }
            }
        })
    }

    return <ReactQuill
        theme="snow"
        value={value}
        onChange={onReactQuillChange}
    />;
}

export default Editor;