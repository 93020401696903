import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {createShoppingList, deleteShoppingList, getShoppingLists} from "../../services/api/api";
import {Link} from "react-router-dom";
import routes from "../../routes";
import orangeImg from "../../assets/img/oranges.jpg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import PaywallError from "../../error/paywallError";
import AuthError from "../../error/authError";

class ShoppingLists extends AbstractComponent {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            page: 1,
            noMoreItems: false,
            items: [],
            errorMessage: null,
        };
    }

    componentDidMount = async () => {
        const {onMetaUpdate} = this.props
        await this.loadItems()
        onMetaUpdate({metaTitle: 'Twoje listy zakupów'})
    }

    loadItems = async () => {
        const {onPaywall, onNotLoggedIn} = this.props
        this.changeState("isLoaded", false);
        const currentState = {
            ...this.state
        };

        try {
            const {page} = this.state;
            const resp = await getShoppingLists(page);
            if (resp.items.length > 0) {
                currentState.items = currentState.items.concat(...resp.items);
            }
            if (currentState.items.length === resp.count) {
                currentState.noMoreItems = true;
            } else {
                currentState.page++;
            }

        } catch (error) {
            if (error instanceof PaywallError) {
                onPaywall()
            }
            if (error instanceof AuthError) {
                onNotLoggedIn()
            }
            currentState.errorMessage = "pobieranie danych nie powiodło się";
        }
        currentState.isLoaded = true;
        this.setState(currentState)
    };

    handleDelete = async (objectIdToRemove) => {
        const {onPaywall} = this.props
        const currentState = {...this.state};
        try {
            this.changeState("isLoaded", false);
            await deleteShoppingList(objectIdToRemove);
            const items = this.state.items.filter((item) => {
                return item.id !== objectIdToRemove;
            });
            currentState.isLoaded = true;
            currentState.items = items;
            this.setState(currentState);
        } catch (error) {
            if (error instanceof PaywallError) {
                onPaywall()
            }
            currentState.errorMessage = "operacja nie powiodła sie";
            currentState.isLoaded = true;
            this.setState(currentState);
        }
    };

    handleCreate = async () => {
        const {onPaywall} = this.props
        const currentState = {...this.state};

        try {
            this.changeState("isLoaded", false);

            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const fullDate = dd + '-' + mm + '-' + yyyy

            const name = "Lista zakupów z dnia " + fullDate;

            const resp = await createShoppingList(
                name
            );

            currentState.redirectTo = routes.shoppingLists + '/' + resp.id;
        } catch (error) {
            if (error instanceof PaywallError) {
                onPaywall()
            }
            currentState.errorMessage = "nie udało się zainicjować listy zakupów";
        }
        currentState.isLoaded = true;
        this.setState(currentState)
    };


    render = () => {
        const {errorMessage, items, isLoaded} = this.state

        if (!isLoaded) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }

        const loadMoreItems = this.state.noMoreItems ?
            "" :
            <div>
                <button
                    className={"action-button action-button-green"}
                    onClick={this.loadItems}
                >
                    Pokaż więcej
                </button>
            </div>;

        return (
            <div className="content_container">
                {this.renderRedirect()}
                <h2>Twoje listy zakupów</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"prl_box_mod"}>
                                <button
                                    onClick={this.handleCreate}
                                    className={"btn_green"}
                                >
                                    Dodaj
                                </button>
                                <hr/>
                                <ul className="list-group list-group-flush">
                                    {items.map((item) => {
                                        return <li
                                            className={"list-group-item"}
                                            key={item.id}
                                        >
                                            <Link to={routes.shoppingLists + "/" + item.id}>
                                                {item.name} ({new Date(item.created_at).toISOString().split('T')[0]})
                                            </Link>
                                            <button
                                                className={"action-button action-button-red"}
                                                onClick={() => {
                                                    if (window.confirm('Czy na pewno chcesz usunąć tą pozycję?')) {
                                                        this.handleDelete(item.id);
                                                    }
                                                }}
                                            >
                                                usuń
                                            </button>
                                        </li>
                                    })}
                                </ul>
                                {errorMessage}
                                {loadMoreItems}
                                <hr/>
                                <button
                                    onClick={this.handleCreate}
                                    className={"btn_green"}
                                >
                                    Dodaj
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShoppingLists;
