import React from "react";

const RoundedButton = props => {
    const {isActive} = props

    return (
        <button className={"rounded_button" + (isActive ? " active" : "")}>
            {props.children}
        </button>
    );
};

export default RoundedButton;