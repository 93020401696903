import React from "react";
import {VictoryPie, VictoryTheme} from "victory";
import {Badge} from "react-bootstrap";

const NutritionInfo = props => {
    const {nutritionInfo} = props;

    return (
        <div className={"container"} itemProp="nutrition"
             itemScope={true} itemType="http://schema.org/NutritionInformation">
            <div className={"row"}>
                <div className={"col-md-8"}>
                    <h3 itemProp="calories">{nutritionInfo.kcal} kcal</h3>
                    {nutritionInfo.weight ? <Badge bg={"warning"} pill>ok. {nutritionInfo.weight} g</Badge> : ""}
                    <p className={"text-center"}>
                        <strong>Białko:</strong><br/>
                        <Badge bg={"warning"} pill
                               itemProp="proteinContent">{Math.round(nutritionInfo.protein * 100) / 100} g</Badge>
                        <Badge bg="success" pill>{nutritionInfo.protein_energy_percentage}% energii</Badge><br/>
                    </p>
                    <p className={"text-center"}>
                        <strong>Tłuszcz:</strong><br/>
                        <Badge bg={"warning"} pill
                               itemProp="fatContent">{Math.round(nutritionInfo.fat * 100) / 100} g</Badge>
                        <Badge bg="success" pill>{nutritionInfo.fat_energy_percentage}% energii</Badge><br/>
                    </p>
                    <p className={"text-center"}>
                        <strong>Węglowodany:</strong><br/>
                        <Badge bg={"warning"} pill><span
                            itemProp="carbohydrateContent">{Math.round(nutritionInfo.carbohydrate * 100) / 100} </span>
                            (<span
                                itemProp="fiberContent">{Math.round(nutritionInfo.fiber * 100) / 100} g</span> błonnik)</Badge>
                        <Badge bg="success" pill>{nutritionInfo.carbohydrate_energy_percentage}% energii</Badge><br/>
                    </p>
                </div>
                <div className={"col-md-4 no-print"}>
                    {nutritionInfo.kcal > 0 ?
                        (<VictoryPie
                            colorScale={["#99CA46", "red", "#FFA500"]}
                            animate={{
                                duration: 2000
                            }}
                            data={[
                                {
                                    x: "B",
                                    y: nutritionInfo.protein_energy_percentage,
                                },
                                {
                                    x: "T",
                                    y: nutritionInfo.fat_energy_percentage
                                },
                                {
                                    x: "W",
                                    y: nutritionInfo.carbohydrate_energy_percentage
                                },
                            ]}
                            theme={VictoryTheme.material}
                        />)
                        : ''}
                </div>
            </div>
        </div>
    );
};

export default NutritionInfo;