import React from "react";
import PageContent from "./PageContent";
import routes from "../../routes";

const AboutUs = props => {
    const {title, seoText, onMetaUpdate} = props;

    return (
        <PageContent
            title={title}
            seoText={seoText}
            onMetaUpdate={onMetaUpdate}
            route={routes.aboutUs}
        >
            <p>
                Dieterian.com to serwis, na którym możesz ułożyć dietę na Twoich zasadach. Możesz wymieniać potrawy,
                dodawać wykluczenia alergenów lub po prostu produktów / potraw których nie lubisz. Dieta wegetariańska /
                wegańska / bezglutenowa / bez nabiału, mleka? Nie ma problemu, jak najbardziej pomożemy Ci ułożyć
                jadłospis dostosowany do Twojego smaku!
            </p>
            <p>
                Możesz również tworzyć własne przepisy za pomocą kalkulatora kalorii i używać ich w generatorze diet.
            </p>
            <p>
                Nasz algorytm stara się, aby w utworzonej przez Ciebie diecie procent energii (kcal) z poszczególnych
                makroelementów mieścił się w przedziałach:<br/>
                Białko: 15 - 25%<br/>
                Tłuszcze: 20 - 30%<br/>
                Węglowodany: 45 - 65%<br/>
            </p>
            <p>
                Możesz oczywiście dostosować te proporcje pod cele, które chcesz osiągnąć w zaawansowanych ustawieniach.
            </p>
            <p>
                <b>Wierzymy w Ciebie, zacznij swoją metamorfozę już dziś!</b>
            </p>
        </PageContent>
    );
};

export default AboutUs;