import axios from "../axios/axios";
import endpoints from "./endpoints";
import encodeQueryData from "../../utils/url";
import AuthError from "../../error/authError";
import PaywallError from "../../error/paywallError";
import ErrorWithCode from "../../error/errorWithCode";

export async function getFoodPreferencesFullMap() {
    try {
        const res = await axios.get(endpoints.foodPreferencesFullMap);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("fetching getFoodPreferencesFullMap failed: " + error);
    }
}

export async function setFoodPreference(objectTypeId, objectId, liked) {
    try {
        const res = await axios.post(endpoints.addFoodPreference, {
            "object_type_id": objectTypeId,
            "object_id": objectId,
            "liked": liked
        });
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("adding food preference failed: " + error);
    }
}

export async function getFoodPreferences(page, liked, limit) {
    try {
        const queryParams = encodeQueryData({
            "page": page,
            "liked": liked,
            "limit": limit
        });
        const res = await axios.get(endpoints.indexFoodPreference + "?" + queryParams,);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("fetching food preferences failed: " + error);
    }
}

export async function getDishes(page, limit, filters) {
    try {
        const queryParams = encodeQueryData({
            ...filters,
            "page": page,
            "limit": limit
        });
        const res = await axios.get(endpoints.dishIndex + "?" + queryParams,);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("fetching dishes failed: " + error);
    }
}

export async function getProducts(page, limit, filters) {
    try {
        const queryParams = encodeQueryData({
            ...filters,
            "page": page,
            "limit": limit
        });
        const res = await axios.get(endpoints.productIndex + "?" + queryParams,);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("fetching products failed: " + error);
    }
}

export async function getUsers(page, limit, filters) {
    try {
        const queryParams = encodeQueryData({
            ...filters,
            "page": page,
            "limit": limit
        });
        const res = await axios.get(endpoints.adminUserIndex + "?" + queryParams,);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("fetching users failed: " + error);
    }
}

export async function getMealAlternatives(dietId, dishSetId, mealId) {
    try {
        const res = await axios.get(endpoints.indexMealAlternatives + "/" + dietId + "/" + dishSetId + "/" + mealId);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("fetching meal alternatives failed: " + error);
    }
}

export async function deleteFoodPreference(objectType, objectId) {
    try {
        const res = await axios.delete(endpoints.deleteFoodPreference + "/" + objectType + "/" + objectId,);
        return Object.keys(res.data).map(function (key) {
            return res.data[key]
        });
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("deleting food preference failed: " + error);
    }
}

export async function generateDiet(kcal, dietParams) {
    try {
        const postData = {
            "ordinal_number": parseInt(dietParams.ordinalNumber),
            "meal_count": parseInt(dietParams.mealCount),
            "total_kcal_needs": parseInt(dietParams.kcal),
            "lactose_free": dietParams.lactoseFree,
            "dairy_free": dietParams.dairyFree,
            "gluten_free": dietParams.glutenFree,
            "vegetarian": dietParams.vegetarian,
            "vegan": dietParams.vegan
        };

        if (dietParams['diet_id']) {
            postData.diet_id = dietParams['diet_id'];
        }

        if (dietParams.energy_source_parameters) {
            postData.energy_source_parameters = {
                fat_energy_percentage: {
                    min: parseInt(dietParams.energy_source_parameters.fat_energy_percentage.min),
                    max: parseInt(dietParams.energy_source_parameters.fat_energy_percentage.max)
                },
                protein_energy_percentage: {
                    min: parseInt(dietParams.energy_source_parameters.protein_energy_percentage.min),
                    max: parseInt(dietParams.energy_source_parameters.protein_energy_percentage.max)
                }
            }
        }

        const res = await axios.post(endpoints.generateDiet, postData);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("generating diet failed: " + error);
    }
}

export async function updateDishSetItem(dietId, dishSetId, mealId, dishId, portionMultiplier) {
    try {
        const postData = {
            diet_id: dietId,
            dish_set_id: dishSetId,
            meal_id: mealId,
            dish_id: dishId,
            portion_multiplier: portionMultiplier
        };

        const res = await axios.put(endpoints.updateDishSetItem, postData);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("updating dish set item failed: " + error);
    }
}

export async function deleteDishSet(dietId, dishSetId) {
    try {
        const res = await axios.delete(`${endpoints.deleteDishSet}/${dietId}/${dishSetId}`);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("deleting dish set failed: " + error);
    }
}

export async function showDiet(id) {
    try {
        const res = await axios.get(`${endpoints.showDiet}/${id}`);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("getting diet failed: " + error);
    }
}

export async function saveDiet(dietId, name, draft) {
    try {
        await axios.put(endpoints.saveDiet, {
            "id": dietId,
            "name": name,
            "draft": draft === true
        });
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("saving diet failed: " + error);
    }
}

export async function getDiets(page) {
    try {
        const res = await axios.get(`${endpoints.dietIndex}?page=${page}`);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("getting diets failed: " + error);
    }
}

export async function deleteDiet(id) {
    try {
        await axios.delete(endpoints.deleteDiet + "/" + id,);
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("deleting food preference failed: " + error);
    }
}

export async function getGoogleAuthRedirectUrl(state) {
    try {
        const res = await axios.get(endpoints.googleAuthRedirectUrl + '?state=' + state);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("getting google auth redirect url failed: " + error);
    }
}

export async function googleLogin(code) {
    try {
        const postData = {
            code: code,
        };

        const res = await axios.post(endpoints.googleAuthLogin, postData);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("google auth login failed: " + error);
    }
}

export async function googleRegister(params) {
    try {
        const res = await axios.post(
            endpoints.googleAuthRegister,
            addUtmData(params)
        );

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("google auth register failed: " + error);
    }
}

export async function googleConnect(code) {
    try {
        const postData = {
            code: code,
        };

        const res = await axios.post(endpoints.googleAuthConnect, postData);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("google auth connect failed: " + error);
    }
}

export async function googleDisconnect() {
    try {
        await axios.post(endpoints.googleAuthDisconnect,);
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("google auth disconnect failed: " + error);
    }
}

export async function getFacebookAuthRedirectUrl(state) {
    try {
        const res = await axios.get(endpoints.facebookAuthRedirectUrl + '?state=' + state);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("getting facebook auth redirect url failed: " + error);
    }
}

export async function facebookLogin(code) {
    try {
        const postData = {
            code: code,
        };

        const res = await axios.post(endpoints.facebookAuthLogin, postData);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("facebook auth login failed: " + error);
    }
}

export async function facebookRegister(params) {
    try {
        const res = await axios.post(
            endpoints.facebookAuthRegister,
            addUtmData(params)
        );

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("facebook auth register failed: " + error);
    }
}

export async function facebookConnect(code) {
    try {
        const postData = {
            code: code,
        };

        const res = await axios.post(endpoints.facebookAuthConnect, postData);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("facebook auth connect failed: " + error);
    }
}

export async function facebookDisconnect() {
    try {
        await axios.post(endpoints.facebookAuthDisconnect,);
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("facebook auth disconnect failed: " + error);
    }
}

export async function activateAccount(token) {
    try {
        const res = await axios.post(endpoints.activateAccount, {token: token});
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("activateAccount failed: " + error);
    }
}

export async function register(data) {
    try {
        const res = await axios.post(endpoints.register, data);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("registration failed: " + error);
    }
}

export async function getSubscriptions() {
    try {
        const utmData = getUtmData()
        const res = await axios.get(endpoints.subscriptions + "?utm_medium=" + utmData.utm_medium + "&utm_source=" + utmData.utm_source);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }

        throw new Error("fetching subscriptions failed: " + error);
    }
}

export async function getUserActiveSubscriptions(limit) {
    try {
        const res = await axios.get(`${endpoints.userActiveSubscriptions}?limit=${limit}`);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("fetching user subscriptions failed: " + error);
    }
}

export async function buySubscription(type) {
    try {
        const res = await axios.post(endpoints.buySubscription, {
            "type": type
        });
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || ErrorWithCode) {
            throw error;
        }
        throw new Error("buying subscription failed: " + error);
    }
}

export async function getProgressLog(page) {
    try {
        const res = await axios.get(`${endpoints.progressLog}?page=${page}`);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("fetching user progress log failed: " + error);
    }
}

export async function deleteProgressLogItem(id) {
    try {
        await axios.delete(`${endpoints.progressLog}/${id}`);
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("deleting user progress log item failed: " + error);
    }
}

export async function createProgressLogItem(weight, fat, muscle, water, date, note) {
    const params = prepareProgressLogItemParams(weight, fat, muscle, water, date, note);

    try {
        await axios.post(endpoints.progressLog, params);
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("creating user progress log item failed: " + error);
    }
}

export async function updateProgressLogItem(id, weight, fat, muscle, water, date, note) {
    const params = prepareProgressLogItemParams(weight, fat, muscle, water, date, note);

    try {
        await axios.put(`${endpoints.progressLog}/${id}`, params);
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("updating user progress log item failed: " + error);
    }
}

export async function getShoppingLists(page) {
    try {
        const res = await axios.get(`${endpoints.shoppingListIndex}?page=${page}`);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("fetching shopping lists failed: " + error);
    }
}

export async function getShoppingList(id) {
    try {
        const res = await axios.get(`${endpoints.shoppingListShow}/${id}`);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("fetching shopping list failed: " + error);
    }
}

export async function deleteShoppingList(id) {
    try {
        await axios.delete(`${endpoints.shoppingListDelete}/${id}`);
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("deleting shopping list failed: " + error);
    }
}

export async function deleteShoppingListItem(listId, id) {
    try {
        await axios.delete(`${endpoints.shoppingListItemDelete}/${listId}/${id}`);
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("deleting shopping list index failed: " + error);
    }
}

export async function updateShoppingList(id, name, completed) {
    try {
        await axios.put(`${endpoints.shoppingListUpdate}/${id}`, {name: name, completed: completed});
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("updating shopping list failed: " + error);
    }
}

export async function createShoppingList(name, dietId, dayNumbers = null) {
    try {
        const response = await axios.post(`${endpoints.shoppingListCreate}`, {
            name: name,
            diet_id: dietId,
            day_numbers: dayNumbers
        });

        return response.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("creating shopping list failed: " + error);
    }
}

export async function createShoppingListItem(listId, name, amount, optional) {
    try {
        const data = {name: name}
        if (amount) {
            data.amount = parseFloat(amount);
        }
        if (optional) {
            data.optional = optional;
        }
        await axios.post(`${endpoints.shoppingListItemCreate}/${listId}`, data);
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("creating shopping list item failed: " + error);
    }
}

export async function updateShoppingListItem(listId, id, name, amount, optional, done) {
    try {
        const data = {name, amount: parseFloat(amount), optional, done}
        await axios.put(`${endpoints.shoppingListItemUpdate}/${listId}/${id}`, data);
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("updating shopping list item failed: " + error);
    }
}

const prepareProgressLogItemParams = (weight, fat, muscle, water, date, note) => {
    const params = {
        "weight": weight,
        "note": note
    }

    if (fat) {
        params.fat_percentage = fat;
    }

    if (muscle) {
        params.muscle_percentage = muscle;
    }

    if (water) {
        params.water_percentage = water;
    }

    if (date) {
        params.created_at = new Date(date).toISOString();
    }

    return params;
}

export async function tempDish() {
    try {
        const res = await axios.post(`${endpoints.tempDish}`);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("fetching temp dish failed: " + error);
    }
}

export async function resetTempDish() {
    try {
        const res = await axios.post(`${endpoints.resetTempDish}`);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("resetting temp dish failed: " + error);
    }
}

export async function saveTempDish(categories) {
    try {
        const res = await axios.post(`${endpoints.saveTempDish}`, {
            categories: categories
        });
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof ErrorWithCode || error instanceof PaywallError) {
            throw error;
        }

        throw new Error("saving temp dish failed: " + error);
    }
}

export async function productAutocomplete(phrase) {
    try {
        const res = await axios.get(`${endpoints.productAutocomplete}?q=${phrase}`);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("fetching product autocomplete failed: " + error);
    }
}

export async function addTempDishComponent(productId, amount) {
    try {
        const res = await axios.post(endpoints.tempDishComponent, {
            product_id: productId,
            amount: amount,
        });
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("addTempDishComponent failed: " + error);
    }
}

export async function tempDishFromDish(dishId) {
    try {
        const res = await axios.post(endpoints.tempDishFromDish(dishId));
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof PaywallError) {
            throw error;
        }
        throw new Error("tempDishFromDish failed: " + error);
    }
}

export async function deleteTempDishComponent(productId) {
    try {
        const res = await axios.delete(`${endpoints.tempDishComponent}/${productId}`);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("delete temp dish component failed: " + error);
    }
}

export async function changePassword(currentPassword, newPassword) {
    await axios.put(endpoints.changePassword, {
        current_password: currentPassword,
        new_password: newPassword
    });
}

export async function resetPassword(token, password) {
    await axios.post(endpoints.resetPassword, {
        token: token,
        new_password: password
    });
}

export async function login(email, password) {
    const res = await axios.post(endpoints.login, {
        email: email,
        password: password
    });
    return res.data;
}

export async function tempRegister() {
    const res = await axios.post(
        endpoints.tempRegister,
        getUtmData()
    );
    return res.data;
}

export async function getDishStats() {
    const res = await axios.get(endpoints.dishStats);
    return res.data;
}

export async function getDishBySlug(slug) {
    try {
        const res = await axios.get(`${endpoints.dishBySlug}/${slug}`);

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("getting dish failed: " + error);
    }
}

export async function adminGetDishById(id) {
    try {
        const res = await axios.get(endpoints.adminGetDishById(id));

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("getting dish failed: " + error);
    }
}

export async function adminGetProductById(id) {
    try {
        const res = await axios.get(endpoints.adminGetProductById(id));

        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("getting product failed: " + error);
    }
}

export async function upsertDish(data) {
    try {
        const res = await axios.post(endpoints.adminUpsertDish, data);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("upserting dish failed: " + error.response.data.error);
    }
}

export async function recipeProposal(dishId) {
    try {
        const res = await axios.get(endpoints.adminDishRecipeProposal(dishId));
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("upserting dish failed: " + error.response.data.error);
    }
}

export async function updateDishByAuthor(data) {
    try {
        const res = await axios.post(endpoints.updateDishByAuthor, data);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError || error instanceof ErrorWithCode) {
            throw error;
        }
        throw new Error("updating dish failed: " + error.response.data.error);
    }
}

export async function upsertProduct(data) {
    try {
        const res = data.id ?
            await axios.put(endpoints.adminUpdateProduct(data.id), data) :
            await axios.post(endpoints.adminAddProduct, data)
        ;
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("upserting product failed: " + error.response.data.error);
    }
}

export async function upsertDishComponent(dishId, productId, amount, amountDesc) {
    try {
        const res = await axios.post(endpoints.adminUpsertDishComponent(dishId), {
            product_id: productId,
            amount: amount,
            amount_description: amountDesc,
        });
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("addTempDishComponent failed: " + error);
    }
}

export async function deleteDishComponent(dishId, productId) {
    try {
        const res = await axios.delete(endpoints.adminDeleteDishComponent(dishId, productId));
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("delete temp dish component failed: " + error);
    }
}

export async function adminGetCategories() {
    try {
        const res = await axios.get(endpoints.adminGetCategories);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("admin: getting categories failed: " + error);
    }
}

export async function getUnits() {
    try {
        const res = await axios.get(endpoints.getUnits);
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("admin: getting units failed: " + error);
    }
}

export async function adminDeleteProduct(id) {
    try {
        await axios.delete(endpoints.adminDeleteProduct(id));
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("admin: deleting product failed: " + error);
    }
}

export async function adminDeleteUser(id) {
    try {
        await axios.delete(endpoints.adminDeleteUser(id));
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("admin: deleting user failed: " + error);
    }
}

export async function adminDeleteDish(id) {
    try {
        await axios.delete(endpoints.adminDeleteDish(id));
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("admin: deleting dish failed: " + error);
    }
}

export async function duplicateDish(id) {
    try {
        const res = await axios.post(endpoints.adminDuplicateDish(id));
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("duplicateDish failed: " + error);
    }
}

export async function createDishVariant(id) {
    try {
        const res = await axios.post(endpoints.adminCreateDishVariant(id));
        return res.data;
    } catch (error) {
        if (error instanceof AuthError) {
            throw error;
        }
        throw new Error("createDishVariant failed: " + error);
    }
}

export function addUtmData(params) {
    const utmData = getUtmData()

    params.utm_source = utmData.utm_source;
    params.utm_medium = utmData.utm_medium;

    return params;
}

function getUtmData() {
    return {"utm_source": getCookieValue("utm_source"), "utm_medium": getCookieValue("utm_medium")}
}

const getCookieValue = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)