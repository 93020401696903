import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {activateAccount} from "../../services/api/api";
import Loader from "../Loader/Loader";
import PageContent from "../PageContent/PageContent";

class ActivateAccount extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    componentDidMount = async () => {
        const {onSetTokens} = this.props

        const currentState = {
            ...this.state
        };

        const token = this.props.match.params.token
        try {
            const resp = await activateAccount(token)
            onSetTokens(resp)

            currentState.isCompleted = true;
            currentState.isSuccessful = true;

            this.setState(currentState)
        } catch (error) {
            console.log(error);

            currentState.isCompleted = true;
            currentState.isSuccessful = false;

            this.setState(currentState)
        }
    };

    getInitialState = () => {
        return {
            isCompleted: false,
            isSuccessful: false,
        };
    };

    render = () => {
        const {onMetaUpdate} = this.props
        const {isCompleted, isSuccessful} = this.state

        if (!isCompleted) {
            return <Loader/>
        }

        const title = isSuccessful ? "Twoje konto zostało aktywowane" : "Podczas aktywacji wystąpił błąd"
        const content = isSuccessful ? "Możesz już zalogować się danymi użytymi podczas rejestracji." : "Być może już wcześniej kliknąłeś / kliknęłaś w link aktywacyjny? Jeśli tak - zaloguj się proszę. Jeśli nie - skontaktuj się z nami wysyłająć maila na support@dieterian.com i opisz co się stało - pomożemy :)."

        return <PageContent title={title} onMetaUpdate={onMetaUpdate}>{content}</PageContent>
    }
}

export default ActivateAccount;
