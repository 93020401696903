import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {createShoppingList, deleteDishSet, generateDiet, saveDiet, showDiet} from "../../services/api/api";
import routes from "../../routes";
import RoundedButton from "../Buttons/RoundedButton";
import {Link} from "react-router-dom";
import orangeImg from "../../assets/img/oranges.jpg";
import {faArrowAltCircleRight, faCalendarPlus, faEdit, faSave, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MenuDay from "./MenuDay";
import AuthError from "../../error/authError";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import {getDietGeneratorParamsFromState} from "../../services/DietGenerator/DietGenerator";
import PaywallError from "../../error/paywallError";
import {Redirect} from "react-router";
import {faListAlt} from "@fortawesome/free-regular-svg-icons/faListAlt";
import logoImg from "../../assets/img/logo.png";

class DietGenerator extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const fullDate = dd + '-' + mm + '-' + yyyy

        return {
            isLoading: true,
            kcal: 2000,
            mealCount: 5,
            glutenFree: false,
            dairyFree: false,
            lactoseFree: false,
            vegetarian: false,
            vegan: false,
            errorMessage: null,
            ordinalNumber: 1,
            overrideDietParams: false,
            proteinRangeMin: 12,
            proteinRangeMax: 16,
            fatRangeMin: 15,
            fatRangeMax: 30,
            mealAlternatives: null,
            showSaveForm: false,
            dietName: 'Dieta z dnia ' + fullDate,
            draft: false,
            redirectTo: false,
            dietData: null,
        };
    }

    componentDidMount = async () => {
        const {onMetaUpdate} = this.props

        if (this.props.dietId !== undefined) {
            await this.loadDiet();
        } else {
            this.changeState("isLoading", false);
        }
        const {dietData} = this.state

        onMetaUpdate({
            metaTitle: dietData ? dietData.diet.name : "Generowanie diety",
        })
    }

    async componentDidUpdate(prevProps) {
        const {dishSetId} = this.props;
        if (dishSetId !== prevProps.dishSetId) {
            await this.loadDiet(true);
        }
    }

    getCurrentMenuFromState = () => {
        const {dietData, ordinalNumber} = this.state;
        const menuIndex = ordinalNumber - 1;

        if (!dietData || !dietData.menus) {
            return null;
        }

        return dietData.menus[menuIndex];
    }

    handleGenerate = async (event) => {
        event.preventDefault();
        await this.doGenerate(false);
    }

    handleAddNextDay = async () => {
        await this.doGenerate(true);
    }

    handleSave = async (event) => {
        const {onNotLoggedIn, onPaywall} = this.props;
        const {dietData, dietName, draft} = this.state;
        event.preventDefault();
        const currentState = {...this.state};
        currentState.isLoading = true;
        this.setState(currentState);
        if (currentState.showSaveForm === true) {
            if (!dietName) {
                currentState.errorMessage = "Proszę wpisać nazwę"
            } else {
                try {
                    await saveDiet(dietData.diet.id, dietName, draft);
                    window.location.href = routes.showDiet + '/' + dietData.diet.id;
                } catch (error) {
                    if (error instanceof AuthError) {
                        onNotLoggedIn()
                    } else if (error instanceof PaywallError) {
                        onPaywall()
                    } else {
                        currentState.errorMessage = 'wystąpił błąd, spróbuj ponownie'
                    }
                }
            }
        } else {
            currentState.showSaveForm = true;
        }

        currentState.isLoading = false;
        this.setState(currentState);
    }

    handleCloseSaveForm = (event) => {
        event.preventDefault();
        this.changeState("showSaveForm", false);
    }

    loadDiet = async () => {
        const {dietId} = this.props;
        let {dishSetId} = this.props;
        const currentState = {...this.state}
        currentState.isLoading = true;
        this.setState(currentState);
        try {
            const dietData = await showDiet(dietId);
            let ordinalNumber = 1;
            if (dietData.menus) {
                Object.keys(dietData.menus).forEach((index) => {
                    if (dishSetId === undefined || dishSetId === 'undefined') {
                        dishSetId = dietData.menus[index]['dish_set_id'];
                    }
                    if (dietData.menus[index]['dish_set_id'] === dishSetId) {
                        ordinalNumber = parseInt(index) + 1;
                    }
                });
            } else {
                dishSetId = null
            }

            this.updateUrl(dietId, dishSetId);

            currentState.glutenFree = dietData.diet.menu_parameters.gluten_free
            currentState.lactoseFree = dietData.diet.menu_parameters.lactose_free
            currentState.dairyFree = dietData.diet.menu_parameters.dairy_free
            currentState.vegan = dietData.diet.menu_parameters.vegan
            currentState.vegetarian = dietData.diet.menu_parameters.vegetarian
            currentState.mealCount = dietData.diet.menu_parameters.meal_count;
            currentState.kcal = dietData.diet.menu_parameters.total_kcal_needs;

            currentState.dietData = dietData;

            currentState.ordinalNumber = ordinalNumber;
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = "Aby kontuować, zaloguj się proszę.";
            } else {
                currentState.errorMessage = "Ładowanie diety nie powiodło się, spróbuj odświeżyć stronę.";
            }
        }
        currentState.isLoading = false;

        this.setState(currentState);
    }

    doGenerate = async (nextDay = false) => {
        const {onNotLoggedIn, onPaywall} = this.props

        const currentState = {...this.state}

        const dietParams = getDietGeneratorParamsFromState(currentState)
        if (nextDay) {
            const {dietData} = this.state;
            dietParams.ordinalNumber = dietData.menus.length + 1;
        }
        try {
            currentState.dietData = await generateDiet(this.state.kcal, dietParams);

            currentState.ordinalNumber = dietParams.ordinalNumber;
            this.setState(currentState)
            window.location.href = routes.generateDietFullPath(currentState.dietData.diet.id, currentState.dietData.menus[dietParams.ordinalNumber - 1].dish_set_id)
        } catch (error) {
            if (error instanceof PaywallError) {
                onPaywall()
            } else if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else {
                currentState.errorMessage = 'Nie udało się wygenerować diety dla podanych parametrów. Proszę spróbuj ponownie lub zmień parametry diety.'
            }
        }

        this.setState(currentState)
    }

    updateUrl(dietId, dishSetId) {
        const route = this.getRoute()
        let path = `${route}/${dietId}`;
        if (dishSetId) {
            path += `/${dishSetId}`
        }
        if (!window.location.href.endsWith(path)) {
            window.history.pushState('', '', path);
        }
    }

    handleDeleteDishSet = async (dietId, dishSetId) => {
        const {ordinalNumber} = this.state;
        const currentState = {...this.state}
        this.changeState("isLoading", true)

        try {
            currentState.dietData = await deleteDishSet(dietId, dishSetId);
            if (!currentState.dietData.menus) {
                currentState.ordinalNumber = 1
                this.updateUrl(dietId)
            } else if (currentState.dietData.menus.length < ordinalNumber) {
                currentState.ordinalNumber = currentState.dietData.menus.length;
            }
        } catch (error) {
            currentState.errorMessage = "Podczas usuwania diety wystąpił błąd."
        }
        currentState.isLoading = false
        this.setState(currentState)
    }

    //render

    render() {
        const {isLoading, errorMessage, showSaveForm, dietData} = this.state;

        if (isLoading) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }

        let dayTabs = ""
        let jsx = "";
        let jsxBottom = "";
        let controls = "";
        if (isLoading && dietData === null) {
            jsx = <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Ładowanie...</span>
                </div>
            </div>
            jsxBottom = jsx
        } else if (showSaveForm === true) {
            jsxBottom = this.renderSaveDietForm()
        } else if (this.getCurrentMenuFromState()) {
            dayTabs = this.renderDayTabs()
            controls = this.renderControls()
            jsxBottom = this.renderDishSet()
            jsx = <h2>{dietData.diet.name}</h2>
        } else {
            return <Redirect to={routes.generateDiet}/>
        }

        return (
            <div className="content_container diet_generator">
                <div className={"logo-print"}>
                    <img src={logoImg} alt="Dieterian"/>
                </div>
                {this.renderRedirect()}

                {jsx}
                {dayTabs}

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                {jsxBottom}
                                {controls}
                                <hr/>
                                <button className={"btn_green no-print"} onClick={this.scrollToTop}>Do góry</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }

    renderSaveDietForm = () => {
        const {dietName} = this.state;
        return (
            <>
                <form onSubmit={this.handleSave}>
                    <div className={"form-group"}>
                        <label htmlFor={"dietName"}>Nazwa diety</label>
                        <input
                            className={"form-control"}
                            type="text"
                            data-id={"dietName"}
                            id={"dietName"}
                            value={dietName}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className={"form-group"}>
                        <button className={"btn_green"}>
                            <FontAwesomeIcon
                                icon={faSave}
                                className={"text-success"}
                            /> Zapisz
                        </button>

                    </div>
                </form>
                <button
                    className={"btn_orange"}
                    onClick={this.handleCloseSaveForm}
                >
                    Anuluj
                </button>
            </>
        )
    }

    renderDishSet = () => {
        const menu = this.getCurrentMenuFromState();
        const {readOnly, onNotLoggedIn} = this.props
        return <MenuDay
            menu={menu}
            readOnly={readOnly}
            onNotLoggedIn={onNotLoggedIn}
        />
    }

    renderDayTabs = () => {
        const {dietData, ordinalNumber} = this.state;
        const route = this.getRoute();

        return (
            <div className="container">
                <div className={"row"}>
                    <div className={"col text-center"}>
                        {
                            Object.keys(dietData.menus).map((key, index) => {
                                const url = `${route}/${dietData.diet.id}/${dietData.menus[index].dish_set_id}`;
                                return (
                                    <div key={index} className={"diet_days"}>
                                        <Link to={url}>
                                            <RoundedButton
                                                isActive={parseInt(key) + 1 === parseInt(ordinalNumber)}>Dzień {parseInt(key) + 1}
                                            </RoundedButton>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    prepareShoppingList = async () => {
        try {
            const {dietName, dietData} = this.state;
            const result = await createShoppingList(dietName, dietData.diet.id, null)
            this.handleRedirect(routes.shoppingLists + '/' + result.id)
        } catch (error) {
            console.log(error);
        }
    }

    renderControls = () => {
        const {readOnly} = this.props;
        const {dietData, ordinalNumber} = this.state;
        if (dietData === null) {
            return null
        }
        const currentDishSetId = dietData.menus[ordinalNumber - 1].dish_set_id;

        return <div className="container no-print">
            <div className="row">
                <div className={"col"}>
                    <hr/>
                </div>
            </div>
            {readOnly ?
                <div className="row ">
                    <div className={"col"}>
                        <hr/>
                        <Link
                            to={routes.generateDietFullPath(dietData.diet.id, currentDishSetId)}
                            className={"btn_orange"}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                className={"text-warning"}
                            /><br/>
                            Edytuj
                        </Link>
                        <hr/>
                        <button
                            className={"btn_orange"}
                            onClick={this.prepareShoppingList}>
                            <FontAwesomeIcon
                                icon={faListAlt}
                                className={"text-warning"}
                            /><br/>stwórz listę zakupów
                        </button>
                    </div>
                </div> :
                <div className="row">
                    <div className="col-lg-4">
                        <button
                            className={"btn_orange"}
                            onClick={this.handleGenerate}>
                            <FontAwesomeIcon
                                icon={faArrowAltCircleRight}
                                className={"text-warning"}
                            /><br/>wygeneruj ponownie<br/>
                            dietę na bieżący dzień
                        </button>
                    </div>
                    {dietData.menus.length < 14 ?
                        <div className="col-lg-4">
                            <button
                                className={"btn_green"}
                                onClick={this.handleAddNextDay}>
                                <FontAwesomeIcon
                                    icon={faCalendarPlus}
                                    className={"text-success"}
                                /><br/>
                                dodaj następny dzień<br/>
                                do diety
                            </button>
                        </div> : ""}
                    {dietData.menus.length > 1 ?
                        <div className="col-lg-4">
                            <button
                                className={"btn_red"}
                                data-dish-set-id={currentDishSetId}
                                data-diet-id={dietData.diet.id}
                                onClick={() => {
                                    if (window.confirm('Czy na pewno usunąć jadłospis na ten dzień?')) this.handleDeleteDishSet(dietData.diet.id, currentDishSetId)
                                }}>
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    className={"text-danger"}
                                /><br/>
                                usuń dietę<br/>
                                na bieżący dzień
                            </button>
                        </div> : ""}
                </div>
            }
            {dietData.menus.length > 0 && !readOnly ?
                <div className="row">
                    <div className={"col"}>
                        <hr/>
                        <button className={"btn_green"} onClick={this.handleSave}>
                            <FontAwesomeIcon
                                icon={faSave}
                                className={"text-success"}
                            /><br/>
                            Zakończ generowanie jadłospisu i<br/>
                            zapisz dietę na wybrane dni
                        </button>
                    </div>
                </div> : ""}
        </div>
    }

    getRoute = () => {
        const {readOnly} = this.props;
        return readOnly ? routes.showDiet : routes.generateDiet;
    }
}

export default DietGenerator