import React from "react";
import PageContent from "./PageContent";
import routes from "../../routes";
import {Link} from "react-router-dom";

const HowDoesItWork = props => {
    const {title, seoText, onMetaUpdate} = props;

    return (
        <PageContent
            title={title}
            seoText={seoText}
            onMetaUpdate={onMetaUpdate}
            route={routes.howDoesItWork}
        >
            <video src={"/static/videos/jak-dziala-dieterian.mp4"}
                   style={{
                       width: "100%"
                   }}
                   controls={true}
                   muted={true}
                   autoPlay={true}
            />
            <Link to={routes.generateDiet} className="btn_try">ułóż dietę za darmo</Link>
        </PageContent>
    );
};

export default HowDoesItWork;