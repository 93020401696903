import React from "react";
import ErrorMessageShort from "./ErrorMessageShort";

const ErrorMessage = props => {
    return (
        <div className="content_container">
            <div className="container p-5">
                <div className="row">
                    <div className="col">
                        <ErrorMessageShort {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorMessage;