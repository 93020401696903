import React from "react";
import logoImg from "../../assets/img/logo.png";
import closeImg from "../../assets/img/close.png";
import {Link} from "react-router-dom";
import routes from "../../routes";

const TopMobile = props => {
    const {onMenuToggle} = props;

    return (
        <div className="mobile_top">
            <button
                style={{
                    background: 'url(' + closeImg + ')'
                }}
                id="close-button"
                onClick={onMenuToggle}
            ></button>
            <Link to={routes.home}><img className="logo_mobile" src={logoImg} alt="Dieterian"/></Link>
            <button className="menu-button" id="open-button" onClick={onMenuToggle}>Open Menu</button>
        </div>
    );
};

export default TopMobile;