import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {deleteFoodPreference, setFoodPreference} from "../../services/api/api";
import {foodPreferenceObjectTypeIds} from "./enum";
import {faThumbsDown, faThumbsUp} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AuthError from "../../error/authError";

const modeLike = 'like'
const modeDislike = 'dislike'

class FoodPreference extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            liked: false,
            disliked: false,
        };
    };

    componentDidMount = async () => {
        const currentState = {...this.state};

        const liked = await this.container.foodPreferencesCacheStorage.isObjectLiked(this.props.objectType, this.props.objectId);
        if (liked === true) {
            currentState.liked = true;
            currentState.disliked = false;

            this.setState(currentState);
        } else if (liked === false) {
            currentState.liked = false;
            currentState.disliked = true;

            this.setState(currentState);
        }
    };

    handleLike = async () => {
        await this.handleClick(modeLike)
    }

    handleDislike = async () => {
        await this.handleClick(modeDislike)
    }

    handleClick = async mode => {
        const {onNotLoggedIn} = this.props

        try {
            const isLiked = await this.container.foodPreferencesCacheStorage.isObjectLiked(this.props.objectType, this.props.objectId);
            const currentState = {...this.state};

            switch (mode) {
                case modeLike: {
                    if (isLiked) {
                        await deleteFoodPreference(foodPreferenceObjectTypeIds[this.props.objectType], this.props.objectId);
                        currentState.liked = false;
                    } else {
                        await setFoodPreference(foodPreferenceObjectTypeIds[this.props.objectType], this.props.objectId, true);
                        currentState.liked = true;
                        currentState.disliked = false;
                    }

                    break;
                }
                case modeDislike: {
                    if (isLiked === false) {
                        await deleteFoodPreference(foodPreferenceObjectTypeIds[this.props.objectType], this.props.objectId);
                        currentState.disliked = false;
                    } else {
                        await setFoodPreference(foodPreferenceObjectTypeIds[this.props.objectType], this.props.objectId, false);
                        currentState.disliked = true;
                        currentState.liked = false;
                    }

                    break;
                }
                default:
                    console.log('invalid mode: ' + mode);
            }

            await this.container.foodPreferencesCacheStorage.refreshData();
            this.setState(currentState);
        } catch (error) {
            if (error instanceof AuthError) {
                onNotLoggedIn()
            } else {
                console.log("handling food preference failed: " + error);
            }
        }
    };

    render = () => {
        const likeClass = this.state.liked ? " text-success font-weight-bold" : " text-black-30";
        const dislikeClass = this.state.disliked ? " text-danger font-weight-bold" : " text-black-30";

        return (
            <div className={"no-print"}>
                <FontAwesomeIcon
                    icon={faThumbsUp}
                    className={"actionBtn" + likeClass}
                    onClick={this.handleLike}
                />
                <FontAwesomeIcon
                    icon={faThumbsDown}
                    className={"actionBtn" + dislikeClass}
                    onClick={this.handleDislike}
                />
            </div>
        )
    }
}

export default FoodPreference;
