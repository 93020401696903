import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import DishListItem from "./DishListItem";
import {getDishes} from "../../services/api/api";
import orangeImg from "../../assets/img/oranges.jpg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import PaywallError from "../../error/paywallError";
import AuthError from "../../error/authError";
import {isAdmin} from "../../services/User/User";
import Form from "react-bootstrap/Form";
import routes from "../../routes";
import {Link} from "react-router-dom";

class DishList extends AbstractComponent {

    filterFields = {};

    listLimit = 20;

    constructor(props) {
        super(props);
        this.filterFields = {
            query: {
                label: "Nazwa",
                type: "text"
            },
            kcalMin: {
                label: "kcal min",
                type: "number",
                min: 10,
            },
            kcalMax: {
                label: "kcal max",
                type: "number",
                max: 10,
            },
            lactoseFree: {
                label: "bez laktozy",
                type: "checkbox"
            },
            dairyFree: {
                label: "bez nabiału",
                type: "checkbox"
            },
            glutenFree: {
                label: "bez glutenu",
                type: "checkbox"
            },
            vegan: {
                label: "wegańskie",
                type: "checkbox"
            },
            vegetarian: {
                label: "wegetariańskie",
                type: "checkbox"
            },
            onlyUserDishes: {
                label: "tylko dodane przeze mnie",
                type: "checkbox"
            },
        }
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const {mode} = this.props

        return {
            isLoaded: false,
            page: 1,
            noMoreItems: false,
            items: [],
            errorMessage: null,
            kcalMin: 0,
            kcalMax: 9999,
            lactoseFree: false,
            dairyFree: false,
            glutenFree: false,
            vegan: false,
            vegetarian: false,
            showFilters: false,
            title: "Przepisy",
            isAdmin: isAdmin(),
            query: "",
            onlyInactive: false,
            onlyNotAvailableInDietGenerator: false,
            onlyUserDishes: mode === 'twoje',
        };
    };

    componentDidMount = async () => {
        const {title, isAdmin} = this.state
        const {onMetaUpdate} = this.props;
        if (isAdmin) {
            this.filterFields['onlyInactive'] = {
                label: "tylko nieaktywne",
                type: "checkbox"
            }
            this.filterFields['onlyNotAvailableInDietGenerator'] = {
                label: "tylko niedostępne w generatorze",
                type: "checkbox"
            }
        }
        await this.loadDishes(1)
        onMetaUpdate({
            metaTitle: title,
            metaCanonical: routes.recipes,
            metaDescription: "Lista dostępnych przepisów, również dla diety bezglutenowej, bez laktozy, bez nabiału, wegańskiej i wegetariańskiej."
        })
    };

    toggleFilters = () => {
        const currentState = {...this.state}
        currentState.showFilters = !currentState.showFilters
        this.setState(currentState)
    }

    loadFirstPage = async () => {
        await this.loadDishes(1);
    }

    loadNextPage = async () => {
        const {page} = this.state;
        await this.loadDishes(page + 1);
    }

    loadDishes = async (page) => {
        const {onNotLoggedIn, onPaywall} = this.props
        const currentState = {
            ...this.state
        }

        if (page === 1) {
            currentState.items = [];
            currentState.noMoreItems = false;
        }

        currentState.isLoaded = false
        currentState.page = page
        currentState.showFilters = false
        this.setState(currentState);

        const filters = {};

        Object.keys(this.filterFields).forEach((field) => {
            filters[field] = currentState[field];
        });

        try {
            const items = await getDishes(currentState.page, this.listLimit, filters);
            if (items.length > 0) {
                currentState.items = currentState.items.concat(...items);
                currentState.isLoaded = true;
                if (items.length < this.listLimit) {
                    currentState.noMoreItems = true;
                }
            } else {
                currentState.items = []
            }
        } catch (error) {
            if (error instanceof PaywallError) {
                onPaywall()
            } else if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else {
                console.log(error)
                currentState.errorMessage = "podczas pobierania listy potraw wystąpił błąd";
            }

        }
        currentState.isLoaded = true;

        this.setState(currentState)
    };

    getDishListJSX = () => {
        const {items, noMoreItems, isLoaded, isAdmin} = this.state;
        const {onNotLoggedIn} = this.props;

        if (!isLoaded) {
            return <Loader/>
        }

        const dishes = items.length > 0 ?
            this.state.items.map((dish, idx) =>
                <DishListItem dish={dish} key={idx} isAdmin={isAdmin} onNotLoggedIn={onNotLoggedIn}/>
            ) :
            "nie znaleziono potraw";

        const loadMore = noMoreItems ?
            "" :
            (
                <div>
                    <button onClick={this.loadNextPage} className={"btn_orange"}>Pokaż więcej</button>
                </div>
            );
        return (
            <div>
                {dishes}
                {loadMore}
                <hr/>
                <button className={"btn_green"} onClick={this.scrollToTop}>Do góry</button>
            </div>
        )
    };

    render = () => {
        const {errorMessage, showFilters} = this.state;

        if (errorMessage) {
            return <ErrorMessage message={errorMessage}/>
        }

        const formFieldsJsx = Object.keys(this.filterFields).map((key, index) => {
            return (
                <div key={index} className="form-group">
                    <label htmlFor={key} className={"text-center"}>{this.filterFields[key].label}</label>
                    {
                        this.filterFields[key].type === 'checkbox' ?
                            <Form.Check
                                id={key}
                                type="checkbox"
                                data-id={key}
                                onChange={this.handleInputChange}
                                value={this.state[key]}
                                checked={this.state[key]}
                            /> :
                            <input type={this.filterFields[key].type}
                                   className="form-control"
                                   id={key}
                                   data-id={key}
                                   onChange={this.handleInputChange}
                                   min={this.filterFields[key].min}
                                   value={this.state[key]}
                            />
                    }
                </div>
            )
        });

        return (
            <div className="content_container">
                <h2>Przepisy</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                {
                                    showFilters ?
                                        <>
                                            <button className={"btn_orange"} onClick={this.toggleFilters}>schowaj
                                                filtry
                                            </button>
                                            <form onSubmit={this.loadFirstPage}>
                                                {formFieldsJsx}
                                                <button className={"btn_green"} onClick={this.loadFirstPage}>filtruj
                                                </button>
                                            </form>
                                        </> :
                                        <>
                                            <button className={"btn_orange"} onClick={this.toggleFilters}>pokaż filtry
                                            </button>
                                        </>

                                }

                                <hr/>

                                {this.getDishListJSX()}
                                <Link className={"btn_green"} to={routes.calorieCalculator}>
                                    Nie znalazłaś/znalazłeś nic interesującego?<br/>
                                    Dodaj własną potrawę używając kalkulatora kalorii!
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default DishList;
