import React, {useEffect} from "react";
import orangeImg from "../../assets/img/oranges.jpg";

const PageContent = props => {
    const {title, seoText, onMetaUpdate, route, disableMetaUpdate, children, itemScope, itemType} = props;

    useEffect(() => {
        if (!disableMetaUpdate) {
            onMetaUpdate({
                metaTitle: title,
                metaDescription: seoText,
                metaCanonical: route
            })
        }
    }, [title, seoText, onMetaUpdate, disableMetaUpdate, route]);

    return (
        <div className="content_container" itemScope={itemScope} itemType={itemType}>
            <h2 itemProp="name">{title}</h2>
            {seoText ? <p className={"seo_text"}>{seoText}</p> : ""}

            <div className="prl_box_top">
                <div className="prl_box_bg"
                     style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                </div>
            </div>

            <div className="prl_box_middle">
                <div className="container">
                    <div className="row">
                        <div className="prl_box_mod">
                            {children}
                        </div>
                    </div>
                </div>
            </div>

            <div className="prl_box_bottom">
                <div className="prl_box_bg"
                     style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                </div>
            </div>
        </div>
    );
};

export default PageContent;