import React from "react";
import routes from "../../routes";
import {Link, Redirect} from "react-router-dom";
import {createShoppingList} from "../../services/api/api";
import AbstractComponent from "../AbstractComponent/AbstractComponent";

class DietListItem extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            redirectUrl: null
        };
    };

    prepareShoppingList = async () => {
        try {
            const {diet} = this.props;
            const result = await createShoppingList(diet.name, diet.id, null)
            this.changeState('redirectUrl', routes.shoppingLists + '/' + result.id)
        } catch (error) {
            console.log(error);
        }
    }

    render = () => {
        const {redirectUrl} = this.state
        const {diet, onDelete} = this.props

        if (redirectUrl) {
            return <Redirect to={redirectUrl}/>
        }
        const createdAt = new Date(diet.created_at)
        const parsedDate = createdAt.toISOString().split('T')[0]

        return (
            <li className="list-group-item">
                <Link to={(diet.draft ? routes.generateDiet : routes.showDiet) + '/' + diet.id}>
                    <strong>{diet.name}</strong> <i>{parsedDate}</i>
                </Link>
                <Link
                    to={routes.generateDiet + '/' + diet.id}
                    className={"action-button action-button-orange"}
                >
                    edytuj
                </Link>
                {
                    diet.draft ? '' : (
                        <button className={"action-button action-button-green"} onClick={this.prepareShoppingList}>
                            stwórz listę zakupów
                        </button>
                    )
                }

                <button
                    className={"action-button action-button-red"}
                    onClick={() => {
                        if (window.confirm('Czy na pewno usunąć dietę?')) onDelete(diet.id)
                    }
                    }>
                    usuń
                </button>
            </li>
        )
    }
}


export default DietListItem;