import React from "react";
import HomeSlider from "../HomeSlider/HomeSlider";
import DishStats from "../DishStats/DishStats";
import DishBoxesDesktop from "../Dish/DishBoxes";
import GreenCTA from "../HomeBottomCTAs/GreenCTA";
import routes from "../../routes";
import {Link} from "react-router-dom";
import AbstractComponent from "../AbstractComponent/AbstractComponent";

class HomePage extends AbstractComponent {
    metaTitle = "Dieterian.com - Dieta na Twoich zasadach - spróbuj za darmo!"
    metaDescription = "Możesz wymieniać potrawy, sam określić ile białka chcesz w diecie oraz jakie potrawy chcesz częściej.Możesz wykluczyć gluten, laktozę czy nawet pojedyncze składniki."

    componentDidMount = async () => {
        const {onMetaUpdate} = this.props;
        onMetaUpdate({
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            metaCanonical: routes.home,
            noSuffix: true,
        })
    }

    render() {
        return (
            <>
                <HomeSlider/>
                <div className={'row'}>
                    <div className={'container'}>
                        <div className={"col-md-10 subtitle-container mt-3"}>
                            <h2>Jak to działa?</h2>
                            <p>Na Dieterian.com za pomocą kilku kliknięć określisz swoje zapotrzebowanie kaloryczne,
                                ułożysz
                                zdrową, zbilansowaną <strong>dietę odchudzającą</strong>, utrzymującą bądź powodującą
                                &nbsp;<strong>przyrost masy ciała</strong> (to Ty decydujesz jaki cel chcesz
                                osiągnąć).<br/>
                                Uwzględnimy Twój gust (możesz określić które potrawy / produkty
                                lubisz bardziej, a które chciałbyś / chciałabyś wykluczyć). Możesz również wymienić
                                proponowane w diecie potrawy, zmodyfikować je oraz dodawać własne.<br/>
                                Jeśli chciałbyś / chciałabyś nieco zmodyfikować parametry diety - masz <strong>możliwość
                                    samodzielnego określenia makroelementów w diecie</strong>.<br/><br/>
                                <center><strong><Link to={routes.howDoesItWork}>Kliknij tutaj, aby obejrzeć krótki film
                                    instruktażowy</Link></strong></center>
                            </p>
                        </div>
                    </div>
                </div>

                <DishBoxesDesktop/>
                <Link to={routes.generateDiet} className="btn_try">ułóż dietę za darmo</Link>
                <DishStats/>
                <GreenCTA
                    additionalClass={'hp'}
                    h2Text={'Wygeneruj dietę za pomocą kilku kliknięć.'}
                    h2TextLine2={'Teraz, bez wychodzenia z domu.'}
                    linkUrl={routes.generateDiet}
                    actionText={'Spróbuj za darmo!'}
                />
            </>
        );
    }

}

export default HomePage;