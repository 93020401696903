import React from "react";
// get our fontawesome imports
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge} from "react-bootstrap";
import FoodPreference from "../FoodPreference/FoodPreference";
import {Link} from "react-router-dom";
import routes from "../../routes";

const DishComponents = props => {
    const {dish, onDeleteComponent, onEditComponent, withNutritionInfo, onNotLoggedIn} = props;

    if (!dish.components) {
        return (<span>brak elementów</span>)
    }

    function getDishComponentNutritionInfo(dishComponent) {
        return (
            <Badge bg="success"
                   pill>{parseInt(dishComponent.amount / 100 * dishComponent.product.kcal) + " kcal"}</Badge>
        );
    }

    return (
        <ul className="list-group list-group-flush">
            {dish.components.map((dishComponent, idx) =>
                <li key={idx}
                    className="list-group-item"
                >
                    <p className={"text-center"}>
                        <span className={"dish_component_name"}
                              itemProp="ingredients">
                            <Link
                                to={routes.product + '/' + dishComponent.product.slug}>{dishComponent.product.name}</Link>
                        </span> <br/>
                        <Badge bg="warning" pill>{dishComponent.amount} {dishComponent.product.unit.name}</Badge>
                        {dishComponent.amount_description ? <><br/><span
                            className={'text-muted'}>{'(' + dishComponent.amount_description + ')'}</span></> : ''}
                        &nbsp;{withNutritionInfo ? getDishComponentNutritionInfo(dishComponent) : ''}
                        <FoodPreference objectId={dishComponent.product.id} objectType={"product"}
                                        onNotLoggedIn={onNotLoggedIn}/>
                        {onEditComponent || onDeleteComponent ? <br/> : ''}
                        {onEditComponent ? (
                            <FontAwesomeIcon
                                icon={faEdit}
                                className={"actionBtn"}
                                onClick={() => {
                                    onEditComponent(dishComponent)
                                }}
                            />
                        ) : ''}
                        {onDeleteComponent ? (
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className={"actionBtn text-danger"}
                                onClick={() => {
                                    onDeleteComponent(dishComponent)
                                }}
                            />
                        ) : ''}
                    </p>
                </li>
            )}
        </ul>
    );
};

export default DishComponents;
