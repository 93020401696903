import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {updateShoppingListItem} from "../../services/api/api";
import {Badge} from "react-bootstrap";

class ShoppingListItem extends AbstractComponent {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const {data} = this.props;
        return {
            originalData: data,
            showForm: false,
            isLoaded: false,
            id: data.id,
            name: data.name,
            amount: data.amount === null ? "" : data.amount,
            optional: data.optional,
            done: data.done,
            unit: data.unit.name,
        };
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const {id, name, amount, optional, done} = this.state;

        await this.updateShoppingListItem(id, name, amount, optional, done);
    };

    updateShoppingListItem = async (id, name, amount, optional, done) => {

        const {listId} = this.props;
        if (!name) {
            this.changeState("formErrorMessage", "nazwa jest wymagana");

            return;
        }

        try {
            this.changeState("isLoaded", false);

            await updateShoppingListItem(
                listId,
                id,
                name,
                amount,
                optional,
                done
            );

            const currentState = {...this.state};
            currentState.originalData.name = name;
            currentState.originalData.amount = amount;
            currentState.originalData.optional = optional;
            currentState.originalData.done = done;
            currentState.name = name;
            currentState.amount = amount;
            currentState.optional = optional;
            currentState.done = done;
            currentState.isLoaded = true;
            currentState.showForm = false;
            currentState.formErrorMessage = "";
            this.setState(currentState);
        } catch (error) {
            const currentState = {...this.state};
            currentState.formErrorMessage = "operacja nie powiodła sie";
            currentState.isLoaded = true;
            this.setState(currentState);
        }
    }

    renderForm = () => {
        const {formErrorMessage, name, amount} = this.state
        return (
            <div>
                <button onClick={this.toggleForm}>zamknij</button>
                {formErrorMessage ? (<div>{formErrorMessage}</div>) : ''}
                <form onSubmit={this.handleSubmit}>
                    <p>
                        <label>Nazwa</label>
                        <input type="text" autoFocus data-id="name" value={name} onChange={this.handleInputChange}/>
                    </p>
                    <p>
                        <label>Ilość</label>
                        <input type="text" data-id="amount" value={amount} onChange={this.handleInputChange}/>
                    </p>
                    <p>
                        <button className={"action-button action-button-green"}>zapisz</button>
                    </p>
                </form>
            </div>
        )
    };

    resetFormData = () => {
        const {originalData} = this.state;
        const currentState = {...this.state}

        currentState.id = originalData.id;
        currentState.name = originalData.name;
        currentState.amount = originalData.amount === null ? "" : originalData.amount;
        currentState.optional = originalData.optional;
        currentState.done = originalData.done;
        currentState.showForm = true;

        this.setState(currentState);
    }

    toggleForm = () => {
        const {showForm} = this.state;
        if (!showForm) {
            this.resetFormData();
        } else {
            this.changeState("showForm", false);
        }
    }

    toggleDone = async (e) => {
        try {
            e.preventDefault();
            const {id, name, amount, optional, done} = this.state;
            await this.updateShoppingListItem(id, name, amount, optional, !done)
        } catch (error) {
            console.log('err', error);
        }
    }

    renderItem = () => {
        const {name, amount, done, unit, optional} = this.state;
        const additionalClass = done ? "text-strike-through" : "";
        return (
            <li
                className={"shopping-list-item list-group-item " + additionalClass}
                onClick={this.toggleDone}
            >
                {name} &nbsp;
                {
                    amount ? <Badge bg={"warning"} pill>{amount} {unit}</Badge> : ""
                }
                {
                    optional ? <Badge bg={"info"} pill>opcjonalny</Badge> : ""
                }
            </li>

        )
    }

    render = () => {
        const {showForm} = this.state;

        return showForm ? this.renderForm() : this.renderItem()
    }
}

export default ShoppingListItem;
