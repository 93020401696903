import React from "react";
import PageContent from "./PageContent";
import routes from "../../routes";
import {Link} from "react-router-dom";

const ZdrowePrzepisy = props => {
    const {onMetaUpdate} = props;

    return (
        <PageContent
            title={"Zdrowe i smaczne przepisy na dietę odchudzającą dostępne na Dieterian.com"}
            seoText={"Zdrowe odżywianie jest kluczowym elementem każdej udanej diety odchudzającej. Wybór odpowiednich składników oraz przygotowywanie posiłków, które są nie tylko niskokaloryczne, ale także smaczne i sycące, może być prawdziwym wyzwaniem. Na szczęście, portal Dieterian.com przychodzi z pomocą, oferując przepisy, które sprawią, że Twoja dieta będzie zarówno efektywna, jak i przyjemna."}
            onMetaUpdate={onMetaUpdate}
            route={routes.blogZdrowePrzepisy}
        >
            <p>
                Pierwszym przepisem, który warto wypróbować, jest <Link
                to={routes.recipe + '/owsianka-z-bananem-i-rodzynkami'}>owsianka z bananem i rodzynkami</Link>. To
                doskonały wybór na zdrowe śniadanie, dostarczające energii na cały dzień. Płatki owsiane gotujemy razem
                z pokrojonym bananem i rodzynkami, tworząc pyszne i pożywne danie. Co ważne, jedna porcja tego posiłku
                dostarcza 522 kcal i aż 18g białka, co sprawia, że jest to świetny start dnia, zwłaszcza dla osób
                aktywnych fizycznie.
            </p>
            <p>
                Kolejnym przepisem, który warto uwzględnić w swoim planie posiłków, jest <Link
                to={routes.recipe + '/kotlet-z-piersi-kurczaka-z-ziemniakami'}>kotlet z piersi kurczaka z
                ziemniakami</Link>. To świetna propozycja na obiad, który dostarczy Ci niezbędnych białek i
                węglowodanów, a jednocześnie nie obciąży Twojej diety zbyt wieloma kaloriami. Kotlet przygotowujemy,
                obtaczając go kolejno w mące, jajku i bułce tartej, a następnie smażymy na rozgrzanym oleju. Podajemy go
                z gotowanymi ziemniakami i surówką, co sprawia, że jest to pełnowartościowy i sycący posiłek.
            </p>
            <p>
                Jeśli masz ochotę na coś bardziej wyjątkowego, <Link to={routes.recipe + '/burger-wolowy'}>burger
                wołowy</Link> od Dieterian.com z pewnością Cię zainteresuje. Choć burgery często kojarzą się z
                fast-foodami i niezdrowym jedzeniem, ten przepis udowadnia, że mogą one być także częścią zdrowej diety.
                Mięso wołowe mieszamy z solą i pieprzem, formujemy kotlet, a następnie smażymy, podając go w bułce z
                sałatą, pomidorem, cebulą i ogórkiem. To doskonała propozycja na obiad lub kolację, która dostarczy Ci
                białka i tłuszczów, jednocześnie będąc smacznym i atrakcyjnym posiłkiem.
            </p>
            <p>
                Dieterian.com to nie tylko przepisy, ale także praktyczne narzędzia, które pomogą Ci dostosować dania do
                własnych potrzeb. Każdy przepis można modyfikować, dodając lub odejmując składniki, a następnie
                zapisując go na liście swoich potraw. Dzięki temu, Twoja dieta będzie dostosowana do indywidualnych
                potrzeb i preferencji, co jest kluczowe dla utrzymania motywacji i osiągnięcia sukcesu w odchudzaniu.
            </p>
            <p>
                Zapraszamy do korzystania z portalu Dieterian.com, gdzie znajdziesz nie tylko inspiracje kulinarne, ale
                także wsparcie na drodze do zdrowego stylu życia i wymarzonej sylwetki. Z nami odkryjesz, że zdrowa
                dieta odchudzająca może być smaczna, różnorodna i pełna przyjemności!
            </p>
        </PageContent>
    );
};

export default ZdrowePrzepisy;