import React, {useEffect, useState} from "react";

import veganImg from "../../assets/img/vegan.png"
import vegetarianImg from "../../assets/img/vegetarian.png"
import lactoseImg from "../../assets/img/lactose.png"
import glutenImg from "../../assets/img/gluten.png"
import Loader from "../Loader/Loader";
import {getDishStats} from "../../services/api/api";

const DishStats = props => {
    const [counts, setCounts] = useState(null)

    useEffect(() => {
        async function fetchData() {
            try {
                const dishStats = await getDishStats()
                setCounts(dishStats)
            } catch (error) {
                console.log(error)
            }
        }

        if (counts === null) {
            fetchData();
        }
    });


    return !counts ? <Loader/> : (
        <>
            <div className="try_icons">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className={"col-md-10 subtitle-container"}>
                            <h2>Alergia? Nietolerancja pokarmowa? A może po prostu preferujesz dietę wegańską lub
                                wegetariańską? Serdeczenie zapraszamy!</h2>
                        </div>

                        <div className="col-md-5 p-0 ico_block">
                            <div className="ico_head">
                                <img src={veganImg} alt=""/>
                                <h2>dieta wegańska</h2>
                            </div>

                            <p>
                                W naszej bazie przepisów <strong>{counts.vegan}</strong> z nich to przepisy na dania
                                wegańskie,
                                niezawierające mięsa, nabiału, jaj czy miodu, natomiast pełne zdrowych roślin
                                strączkowych, pełnowartościowych zbóż, owoców i warzyw.
                            </p>
                        </div>
                        <div className="col-md-5 p-0 ico_block">
                            <div className="ico_head">
                                <img src={vegetarianImg} alt=""/>
                                <h2>dieta wegetariańska</h2>
                            </div>
                            <p>
                                Baza naszych przepisów wegeteriańskich
                                liczy <strong>{counts.vegetarian}</strong> pozycji.
                                Brak białka pochodzącego z mięsa rekompensujemy (poza składnikami wymienionymi w diecie
                                wegańskiej) obecnością nabiału oraz jaj.
                            </p>
                        </div>
                        <div className="col-md-5 p-0 ico_block">
                            <div className="ico_head">
                                <img src={lactoseImg} alt=""/>
                                <h2>dieta bezlaktozowa</h2>
                            </div>
                            <p>
                                Na naszym portalu znajdziesz <strong>{counts.lactose_free}</strong> przepisów na dania
                                bez laktozy.
                                Jeśli po spożyciu produktów nabiałowych miewasz problemy jelitowe - spróbuj diety
                                bezlaktozowej z Dieterian.com.
                            </p>
                        </div>
                        <div className="col-md-5 p-0 ico_block">
                            <div className="ico_head">
                                <img src={glutenImg} alt=""/>
                                <h2>dieta bezglutenowa</h2>
                            </div>
                            <p>
                                Osobom na diecie bezglutenowej mamy do
                                zaproponowania <strong>{counts.gluten_free}</strong> starannie dobranych potraw. Jeśli
                                cierpisz na celiakię, alergię bądź nadwrażliwość na gluten - u nas na pewno znajdziesz
                                coś dla siebie.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DishStats;