import React from "react";
import GreenCTA from "../HomeBottomCTAs/GreenCTA";

const Paywall = props => {
    const {onClose, onBuy} = props
    return (
        <GreenCTA
            h2Text={'Dostęp wymaga aktywnej subskrypcji'}
            onClick={onBuy}
            onClose={onClose}
            actionText={'Kup dostęp już za 7,42 PLN / miesiąc'}
            modal={true}
        />
    )
}

export default Paywall;