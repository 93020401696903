import React from "react";

const ErrorMessageShort = props => {
    const {message, withoutRefreshButton, onButtonClick, buttonText} = props;
    const reloadPage = () => {
        window.location.reload();
    }
    return (
        <div className="alert alert-danger text-center" role="alert">
            <p className={'text-center'}>
                {message}
            </p>
            {
                withoutRefreshButton ?
                    '' :
                    <p>
                        <button className="btn_orange"
                                type="button"
                                onClick={onButtonClick ? onButtonClick : reloadPage}>
                            <span>{buttonText ? buttonText : 'Odśwież stronę'}</span>
                        </button>
                    </p>
            }
        </div>
    );
};

export default ErrorMessageShort;