import AbstractComponent from "../AbstractComponent/AbstractComponent";
import React from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import orangeImg from "../../assets/img/oranges.jpg";
import {getMealAlternatives, updateDishSetItem} from "../../services/api/api";
import DishListItem from "../Dish/DishListItem";
import routes from "../../routes";
import {Link} from "react-router-dom";
import AuthError from "../../error/authError";
import PaywallError from "../../error/paywallError";

class MealAlternatives extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            mealAlternatives: null,
            redirectTo: null,
            errorMessage: null
        };
    };

    componentDidMount = async () => {
        const {dietId, dishSetId, mealId, onNotLoggedIn, onPaywall} = this.props;
        const currentState = {...this.state};
        try {
            currentState.mealAlternatives = await getMealAlternatives(dietId, dishSetId, mealId);
            currentState.isLoaded = true;
        } catch (e) {
            if (e instanceof AuthError) {
                onNotLoggedIn()
                currentState.errorMessage = "Akcja wymaga zalogowania się."
            } else if (e instanceof PaywallError) {
                onPaywall()
                currentState.errorMessage = "Akcja wymaga posiadania aktywnej subskrypcji."
            } else {
                console.log("failed to load meal alternatives" + e);
                currentState.errorMessage = "Pobranie danych nie powiodło się. Spróbuj odświezyć stronę."
            }
        }

        this.setState(currentState);
    };

    handleUpdateDishSetItem = async (event) => {
        const {dietId, dishSetId, mealId} = this.props;
        const {mealAlternatives} = this.state;
        const newMealId = event.target.dataset.mealId;
        const portionMultiplier = event.target.dataset.portionMultiplier;

        const currentState = {...this.state}
        currentState.isLoading = true;
        this.setState(currentState);

        try {
            await updateDishSetItem(
                dietId,
                dishSetId,
                parseInt(mealId),
                mealAlternatives.alternatives[newMealId].id,
                parseInt(portionMultiplier)
            );

            this.handleRedirect(routes.generateDietFullPath(dietId, dishSetId))
        } catch (e) {
            console.log(e)
            this.changeState("errorMessage", "Ustawienie alternatywnej potrawy nie powiodło się. Spróbuj ponownie.")
        }
    };

    render = () => {
        const {dietId, dishSetId} = this.props;
        const {errorMessage, isLoaded, mealAlternatives} = this.state;
        const dietUrl = routes.generateDietFullPath(dietId, dishSetId)

        if (!isLoaded) {
            const redirectToDiet = () => {
                window.location.href = dietUrl
            }
            return errorMessage ? <ErrorMessage
                    message={errorMessage}
                    buttonText={'Powróć do wygenerowanej diety'}
                    onButtonClick={redirectToDiet}
                /> :
                <Loader/>
        }

        return <div className="content_container diet_generator">
            {this.renderRedirect()}
            <div className={"row"}>
                <div className={"container"}>
                    <h2>Alternatywy dla: <br/> {mealAlternatives.dish.name}</h2>
                    <Link className={"btn_red"} to={dietUrl}>
                        &laquo; powrót
                    </Link>
                </div>
            </div>

            <div className="prl_box_top">
                <div className="prl_box_bg"
                     style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                </div>
            </div>

            <div className="prl_box_middle">
                <div className="container">
                    <div className="row">
                        <div className="prl_box_mod">
                            {
                                mealAlternatives.alternatives ?
                                    Object.keys(mealAlternatives.alternatives).map((key, index) => {
                                        return (
                                            <div key={index}>
                                                <DishListItem
                                                    dish={mealAlternatives.alternatives[key]}
                                                    button={<button className={"btn_green"}
                                                                    data-meal-id={index}
                                                                    data-portion-multiplier={mealAlternatives.alternatives[key].portion_multiplier}
                                                                    onClick={this.handleUpdateDishSetItem}>Wybierz</button>
                                                    }
                                                />

                                            </div>
                                        )
                                    }) :
                                    <p className={"text-center"}>Brak alternatyw :(. Wróć do diety i wygeneruj nową
                                        dietę na cały dzień.</p>
                            }
                            <Link className={"btn_green"} to={routes.calorieCalculator}>
                                Nie znalazłaś/znalazłeś nic interesującego?<br/>
                                Dodaj własną potrawę używając kalkulatora kalorii!
                            </Link>
                            <Link className={"btn_red"} to={routes.generateDietFullPath(dietId, dishSetId)}>
                                &laquo; powrót
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="prl_box_bottom">
                <div className="prl_box_bg"
                     style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                </div>
            </div>
        </div>
    }
}

export default MealAlternatives;