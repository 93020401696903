import React from "react";
import {VictoryPie, VictoryTheme} from "victory";
import {Badge} from "react-bootstrap";

const DishNutrition = props => {
    const {dish, withChart} = props;

    let totalKcal = dish.kcal
    let totalWeight = dish.weight
    let totalProtein = dish.protein
    let totalFat = dish.fat
    let totalCarbohydrate = dish.carbohydrate
    let totalFiber = dish.fiber

    if (dish.portion_multiplier !== undefined) {
        totalKcal = totalKcal * dish.portion_multiplier
        totalWeight = totalWeight * dish.portion_multiplier
        totalProtein = totalProtein * dish.portion_multiplier
        totalFat = totalFat * dish.portion_multiplier
        totalCarbohydrate = totalCarbohydrate * dish.portion_multiplier
        totalFiber = totalFiber * dish.portion_multiplier
    }


    return (
        <div className={"w-100"}>
            <h2>{totalKcal} kcal</h2>
            Waga całkowita: <br/>
            <Badge bg="warning" pill>
                ok. {totalWeight} g
            </Badge><br/><br/>
            {dish.kcal > 0 && withChart ?
                (<VictoryPie
                    colorScale={["#99CA46", "red", "#FFA500"]}
                    animate={{
                        duration: 2000
                    }}
                    data={[
                        {
                            x: "B",
                            y: dish.protein_energy_percentage,
                        },
                        {
                            x: "T",
                            y: dish.fat_energy_percentage
                        },
                        {
                            x: "W",
                            y: dish.carbohydrate_energy_percentage
                        },
                    ]}
                    theme={VictoryTheme.material}
                />)
                : ''}

            <strong>Białko:</strong><br/>
            <Badge bg={"warning"} pill>{Math.round(totalProtein * 100) / 100} g</Badge>
            <Badge bg="success" pill>{dish.protein_energy_percentage}% energii</Badge><br/>
            <strong>Tłuszcz:</strong><br/>
            <Badge bg={"warning"} pill>{Math.round(totalFat * 100) / 100} g</Badge>
            <Badge bg="success" pill>{dish.fat_energy_percentage}% energii</Badge><br/>
            <strong>Węglowodany:</strong><br/>
            <Badge bg={"warning"} pill>{Math.round(totalCarbohydrate * 100) / 100} g
                ({Math.round(totalFiber * 100) / 100} g błonnik)</Badge>
            <Badge bg="success" pill>{dish.carbohydrate_energy_percentage}% energii</Badge><br/>
        </div>
    );
};


export default DishNutrition;