import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import axios from "../../services/axios/axios";
import endpoints from "../../services/api/endpoints";
import orangeImg from "../../assets/img/oranges.jpg";
import Loader from "../Loader/Loader";
import routes from "../../routes";
import ServiceLocator from "../../services/ServiceLocator/ServiceLocator";

class TDEECalculator extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoading: false,
            sex: "m",
            weight: "",
            height: "",
            age: "",
            activityLevel: 1,
            result: null,
            errorMessage: ''
        };
    }

    componentDidMount = async () => {
        const {onMetaUpdate} = this.props

        onMetaUpdate({
            metaTitle: 'Kalkulator TDEE',
            metaDescription: 'Oblicz swoje całkowite dzienne zapotrzebowanie kaloryczne (TDEE) aby wiedzieć ile kalorii powinna zawierać Twoja dzienna dieta aby osiągnąć Twój cel.',
            metaCanonical: routes.tdeeCalculator
        })
    }

    handleReduction = () => {
        const {result} = this.state
        this.updateKcalInDietParamsAndRedirect(Math.ceil(result * 0.8));
    }

    handleRetain = () => {
        const {result} = this.state
        this.updateKcalInDietParamsAndRedirect(Math.ceil(result));
    }

    handleGain = () => {
        const {result} = this.state
        this.updateKcalInDietParamsAndRedirect(Math.ceil(result * 1.2));
    }

    updateKcalInDietParamsAndRedirect = (kcal) => {
        const container = ServiceLocator.getInstance();
        let dietParams = container.dietParamsStorage.getDietParams()
        if (!dietParams) {
            dietParams = {}
        }
        dietParams.kcal = kcal
        container.dietParamsStorage.setDietParams(dietParams)

        window.location.href = routes.generateDiet;
    }

    handleSubmit = event => {
        event.preventDefault();
        const currentState = {...this.state}
        currentState.isLoading = true;
        currentState.result = null;
        currentState.errorMessage = '';
        this.setState(currentState);

        axios.post(endpoints.tdeeCalculator, {
            "weight": parseFloat(this.state.weight),
            "height": parseFloat(this.state.height),
            "age": parseInt(this.state.age),
            "sex": this.state.sex,
            "physical_activity_level": parseInt(this.state.activityLevel)
        })
            .then((response) => {
                currentState.result = response.data.tdee;
                currentState.isLoading = false;
                this.setState(currentState)
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response.status === 400) {
                    currentState.errorMessage = error.response.data.error;
                } else {
                    currentState.errorMessage = "Obliczenie nie powiodło się. Sprawdź poprawność danych i spróbuj ponownie.";
                }
                currentState.isLoading = false;
                this.setState(currentState)
            });
    };

    render = () => {
        const {isLoading, errorMessage, result} = this.state

        const errorMessageJSX = errorMessage ?
            <div className="alert alert-danger" role="alert">
                {errorMessage}
            </div> :
            ""

        let results = isLoading ? <Loader/> : "";
        if (result) {
            results = <div className="alert alert-primary m-5" role="alert">
                Twoje dzienne zapotrzebowanie kaloryczne to <strong>{result} kcal</strong>
                <hr/>
                <p className={"text-center"}><span className={'text-muted'}>
                    Po kliknięciu w dowolny z poniższych linków zostaniesz przeniesiony do formularza generowania diety.
                    Wartość kaloryczna będzie uzupełniona adekwatnie do celu, jaki chcesz osiągnąć.
                </span></p>
                <button className={"action-button action-button-green"} onClick={this.handleReduction}>Chcę schudnąć
                </button>
                <button className={"action-button action-button-orange"} onClick={this.handleRetain}>Chcę zachować
                    wagę
                </button>
                <button className={"action-button action-button-red"} onClick={this.handleGain}>Chcę przybrać na wadze
                </button>
            </div>;
        }
        return (
            <div className="content_container">
                <h2>Kalkulator TDEE</h2>
                <p className={"seo_text text-center"}>Kalkulator TDEE (Total Daily Energy Expenditure) pozwoli Ci
                    policzyć Twoje
                    całkowite dzienne zapotrzebowanie na kalorie, uwzględniając Twoją aktywność fizyczną.</p>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                {
                                    isLoading ?
                                        <Loader/> :
                                        <>
                                            {errorMessageJSX}
                                            {result ? '' :
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className={"form-group"}>
                                                        <p className={'text-center'}>
                                                            <label htmlFor={"activityLevel"}>Aktywność fizyczna</label>
                                                            <select
                                                                className={"form-select text-center"}
                                                                value={this.state.activityLevel}
                                                                data-id="activityLevel"
                                                                onChange={this.handleInputChange}
                                                                id={"activityLevel"}
                                                            >
                                                                <option value="1">Niewiele lub brak aktywności fizycznej
                                                                </option>
                                                                <option value="2">Ćwiczenia 1 - 3 razy w tygodniu
                                                                </option>
                                                                <option value="3">Ćwiczenia 3 - 5 razy w tygodniu
                                                                </option>
                                                                <option value="4">Ćwiczenia 6 - 7 razy w tygodniu
                                                                </option>
                                                                <option value="5">Sport zawodowy, ćwiczenia dwa razy
                                                                    dziennie.
                                                                </option>
                                                            </select>
                                                        </p>
                                                    </div>
                                                    <div className={"form-group"}>
                                                        <p className={'text-center'}>
                                                            <label htmlFor={"sex"}>Płeć</label>
                                                            <select
                                                                value={this.state.sex}
                                                                data-id="sex"
                                                                onChange={this.handleInputChange}
                                                                id={"sex"}
                                                                className={"form-select text-center"}
                                                            >
                                                                <option value="m">mężczyzna</option>
                                                                <option value="f">kobieta</option>
                                                            </select>
                                                        </p>
                                                    </div>
                                                    <div className={"form-group"}>
                                                        <p className={'text-center'}>
                                                            <label htmlFor={"age"}>Wiek</label>
                                                            <input
                                                                type="number"
                                                                data-id="age"
                                                                id="age"
                                                                onChange={this.handleInputChange}
                                                                value={this.state.age}
                                                                required={true}
                                                                className={"form-control text-center"}
                                                                min={1}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className={"form-group"}>
                                                        <p className={'text-center'}>
                                                            <label htmlFor={"height"}>Wzrost (cm)</label>
                                                            <input
                                                                type="number"
                                                                data-id="height"
                                                                id="height"
                                                                onChange={this.handleInputChange}
                                                                value={this.state.height}
                                                                required={true}
                                                                className={"form-control text-center"}
                                                                min={100}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className={"form-group"}>
                                                        <p className={'text-center'}>
                                                            <label htmlFor={"weight"}>Waga</label>
                                                            <input
                                                                type="number"
                                                                data-id="weight"
                                                                id="weight"
                                                                onChange={this.handleInputChange}
                                                                value={this.state.weight}
                                                                required={true}
                                                                className={"form-control text-center"}
                                                                min={20}
                                                            />
                                                        </p>
                                                    </div>
                                                    <button className={"btn_default"}>oblicz</button>
                                                </form>}
                                            {results}
                                        </>

                                }

                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default TDEECalculator;
