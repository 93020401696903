class ErrorWithCode extends Error {
    constructor(code = 'unknown_error', ...params) {
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ErrorWithCode);
        }

        // Custom debugging information
        this.code = code;
    }
}

export default ErrorWithCode;
