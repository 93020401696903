import React from "react";
import orangeImg from "../../assets/img/oranges.jpg";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {changePassword} from "../../services/api/api";

class ChangePasswordForm extends AbstractComponent {

    minLength = 6;

    state = {
        current_password: "",
        new_password: "",
        repeated_password: "",
        isSent: false,
        error: ""
    };

    componentDidMount = async () => {
        const {isLoggedIn, onMetaUpdate} = this.props

        onMetaUpdate({
            metaTitle: 'Zmiana hasła',
        })

        if (!isLoggedIn) {
            this.changeState("error", "Nie jesteś zalogowany");
        }
    }

    handleInputChange = event => {
        const state = {...this.state};
        state[event.target.dataset.id] = event.target.value;
        this.setState(state);
    };

    handleSubmit = async event => {
        event.preventDefault();
        const currentState = {
            ...this.state
        };

        if (this.state.current_password.length === 0) {
            currentState.error = "proszę wpisać obecne hasło";
            this.setState(currentState);

            return;
        }

        if (this.state.new_password.length < this.minLength) {
            currentState.error = "hasło musi mieć minimum " + this.minLength + " znaków";
            this.setState(currentState);

            return;
        }

        if (this.state.new_password !== this.state.repeated_password) {
            currentState.error = "hasła są różne";
            this.setState(currentState);

            return;
        }

        try {
            await changePassword(this.state.current_password, this.state.new_password)
            currentState.current_password = "";
            currentState.new_password = "";
            currentState.repeated_password = "";
            currentState.error = "";
            currentState.isSent = true;

        } catch (error) {
            if (error.code === 'validation_failed') {
                currentState.error = 'niepoprawne hasło'
            }
            currentState.isSent = false;
        }

        this.setState(currentState)
    };

    render() {
        const {error, isSent} = this.state

        const errorMessage = error ?
            <div className="alert alert-danger" role="alert">
                {error}
            </div> :
            '';
        return isSent ?
            (
                <div className="content_container">
                    <h2>Hasło zmienione</h2>
                </div>
            ) :
            (
                <div className="content_container">
                    <h2>Zmiana hasła</h2>

                    <div className="prl_box_top">
                        <div className="prl_box_bg"
                             style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                        </div>
                    </div>

                    <div className="prl_box_middle">
                        <div className="container">
                            <div className="row">
                                <div className="prl_box_mod">
                                    <div className="container">
                                        <div className="row">
                                            <div className={"col-lg-12"}>
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <p className={'text-center'}>
                                                            <label htmlFor={"current_password"}>Obecne hasło</label>
                                                            <input
                                                                type="password"
                                                                data-id="current_password"
                                                                value={this.state.current_password}
                                                                onChange={this.handleInputChange}
                                                                className={"form-control text-center"}
                                                                id={"current_password"}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className={'text-center'}>
                                                            <label htmlFor={"new_password"}>Nowe hasło</label>
                                                            <input
                                                                type="password"
                                                                data-id="new_password"
                                                                value={this.state.new_password}
                                                                onChange={this.handleInputChange}
                                                                className={"form-control text-center"}
                                                                id={"new_password"}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className={'text-center'}>
                                                            <label htmlFor={"repeated_password"}>Powtórz nowe
                                                                hasło</label>
                                                            <input
                                                                type="password"
                                                                data-id="repeated_password"
                                                                value={this.state.repeated_password}
                                                                onChange={this.handleInputChange}
                                                                className={"form-control text-center"}
                                                                id={"repeated_password"}
                                                            />
                                                        </p>
                                                    </div>
                                                    {errorMessage}
                                                    <button className={"btn_default"}>
                                                        Zmień hasło
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="prl_box_bottom">
                        <div className="prl_box_bg"
                             style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                        </div>
                    </div>
                </div>
            );
    }
}

export default ChangePasswordForm;