import React from 'react';
import {Link} from "react-router-dom";
import routes from "../../routes";
import logoImg from "../../assets/img/logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import {getUserName, isAdmin, isTempUser} from "../../services/User/User";

const MainMenu = props => {
    const {onLogout, onLogin, isLoggedIn} = props;
    const userName = getUserName()
    const isTemp = isTempUser()
    const isAdminUser = isAdmin()

    return (
        <div className="main_menu">
            <ul>
                <li>
                    <Link to={routes.home}>
                        <img className="img-fluid" src={logoImg} alt="Dieterian"/>
                    </Link>
                </li>
                <li>
                    <Link to={routes.generateDiet}>Generuj dietę</Link>
                </li>
                <li>
                    <Link to={routes.recipes}>Przepisy</Link>
                </li>
                <li>
                    <Link to={routes.calorieCalculator}>Kalkulator kalorii</Link>
                </li>
                <li>
                    <Link to={routes.subscriptions}>Cennik</Link>
                </li>

                <li>
                    {isLoggedIn ?
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdownMenuButton"
                                className="dropdown-toggle drop_account"
                                type={"button"}
                            >
                                {userName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {!isTemp ? <>
                                    {
                                        isAdminUser ? <>
                                            <Dropdown.Item>
                                                <Link to={routes.adminProducts} className="dropdown-item">
                                                    Produkty - panel admina
                                                </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <Link to={routes.adminDishForm} className="dropdown-item">
                                                    Nowa potrawa - panel admina
                                                </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <Link to={routes.adminUsers} className="dropdown-item">
                                                    Użytkownicy - panel admina
                                                </Link>
                                            </Dropdown.Item>
                                        </> : <></>
                                    }
                                    <Dropdown.Item>
                                        <Link to={routes.diets} className="dropdown-item">
                                            Twoje diety
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={routes.recipes + '/twoje'} className="dropdown-item">
                                            Twoje przepisy
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={routes.progressLog} className="dropdown-item">
                                            Wyniki diety
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={routes.shoppingLists} className="dropdown-item">
                                            Twoje listy zakupów
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={routes.userSubscriptions} className="dropdown-item">
                                            Subskrypcja
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={routes.changePassword} className="dropdown-item">
                                            Zmień hasło
                                        </Link>
                                    </Dropdown.Item>
                                </> : <Dropdown.Item>
                                    <Link to={routes.register} className="dropdown-item">
                                        Zarejestruj konto
                                    </Link>
                                </Dropdown.Item>}


                                <Dropdown.Item>
                                    <Link to={routes.foodPreferences + "/ulubione"} className="dropdown-item">
                                        Ulubione
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to={routes.foodPreferences + "/nielubiane"} className="dropdown-item">
                                        Nielubiane
                                    </Link>
                                </Dropdown.Item>
                                <hr/>
                                <Dropdown.Item>
                                    <Link to={"#"} onClick={onLogout} className="dropdown-item">
                                        Wyloguj
                                    </Link>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> :
                        <button onClick={onLogin} className="btn_login">Zaloguj</button>
                    }

                </li>
            </ul>
        </div>
    );
};


export default MainMenu;