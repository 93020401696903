import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {getDishBySlug, updateDishByAuthor} from "../../services/api/api";
import Loader from "../Loader/Loader";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import AuthError from "../../error/authError";
import orangeImg from "../../assets/img/oranges.jpg";
import routes from "../../routes";
import {Link} from "react-router-dom";
import ErrorWithCode from "../../error/errorWithCode";
import ErrorMessageShort from "../ErrorMessage/ErrorMessageShort";

class DishUpdateByUser extends AbstractComponent {
    defaultTitle = "Edycja przepisu"
    fields = {
        "name": {
            "label": "Nazwa",
            "type": "text",
            "required": true,
        },
        "recipe": {
            "label": "Przepis",
            "type": "textarea"
        },
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            errorMessage: null,
            formErrorMessage: null,
            dish: null,
            title: this.defaultTitle,
            id: 0,
            name: "",
            recipe: "",
            requestPending: false,
            meal1: false,
            meal2: false,
            meal3: false,
            meal4: false,
            meal5: false,
        };
    };

    componentDidMount = async () => {
        const {onNotLoggedIn, onMetaUpdate, slug} = this.props;
        const currentState = {
            ...this.state
        }

        if (slug) {
            try {
                const resp = await getDishBySlug(slug)
                if (!resp.is_author) {
                    throw new Error("not allowed")
                }
                currentState.dish = resp.dish;
                currentState.dish.categories.forEach(function (item) {
                    switch (item.id) {
                        case 1:
                            currentState.meal1 = true
                            break
                        case 2:
                            currentState.meal2 = true
                            break
                        case 3:
                            currentState.meal3 = true
                            break
                        case 4:
                            currentState.meal4 = true
                            break
                        case 5:
                            currentState.meal5 = true
                            break
                        default:
                            console.log('unsupported meal id: ' + item.id)
                    }
                })
                Object.keys(this.fields).forEach((field) => {
                    currentState[field] = currentState.dish[field]
                })
                onMetaUpdate({metaTitle: currentState.dish.title})
                currentState.isLoaded = true;
            } catch (error) {
                if (error instanceof AuthError) {
                    currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                    onNotLoggedIn()
                } else {
                    currentState.errorMessage = "pobieranie danych nie powiodło się";
                }
            }
        } else {
            currentState.errorMessage = "potrawa nie znaleziona"
        }
        currentState.isLoaded = true

        this.setState(currentState)
    }

    handleUpdateDish = async event => {
        event.preventDefault();
        const {dish} = this.state
        const values = {}
        if (dish) {
            values.id = dish.id
        }
        const currentState = {...this.state}
        currentState.requestPending = true
        this.setState(currentState)
        Object.keys(this.fields).forEach((field) => {
            values[field] = currentState[field]
        })
        const categories = []
        if (currentState.meal1) {
            categories.push(1)
        }
        if (currentState.meal2) {
            categories.push(2)
        }
        if (currentState.meal3) {
            categories.push(3)
        }
        if (currentState.meal4) {
            categories.push(4)
        }
        if (currentState.meal5) {
            categories.push(5)
        }
        values["categories"] = categories

        try {
            const resp = await updateDishByAuthor(values)
            if (!dish) {
                window.location.href = routes.adminDishForm + "/" + resp.id
            }
            currentState.errorMessage = ""
            window.location.href = routes.recipe + "/" + dish.slug
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
            } else if (error instanceof ErrorWithCode) {
                switch (error.code) {
                    case "invalid_input":
                        currentState.errorMessage = "uzupełnij wymagane pola"
                        break
                    default:
                        currentState.errorMessage = "operacja nie powiodła się"
                }
            } else {
                console.log(error)
                currentState.errorMessage = "operacja nie powiodła się";
            }
        }
        currentState.requestPending = false

        this.setState(currentState)
    }

    render = () => {
        const {errorMessage, dish, isLoaded, title, requestPending, meal1, meal2, meal3, meal4, meal5} = this.state
        if (!isLoaded) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }

        return (
            <div className="content_container">
                <h2>{title}</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="calc2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                {dish ? <>
                                    <Link className={"dish_name"}
                                          target={"_blank"}
                                          to={routes.recipe + '/' + dish.slug}><strong>{dish.name}</strong>
                                    </Link>
                                    <hr/>
                                </> : ""}

                                <form onSubmit={this.handleUpdateDish}>
                                    {Object.keys(this.fields).map((field) => {
                                        return this.renderFieldDiv(field)
                                    })}
                                    <div className={"row text-center mt-5 mb-5"}>
                                        <div className="col-md-2">
                                            <p className={"text-center"}>
                                                Posiłki *:
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"} htmlFor="meal1">Śniadanie</label>
                                                <br/>
                                                <input type={"checkbox"}
                                                       id={"meal1"}
                                                       data-id={"meal1"}
                                                       checked={meal1}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"} htmlFor="meal2">II
                                                    Śniadanie</label>
                                                <br/>
                                                <input type={"checkbox"}
                                                       id={"meal2"}
                                                       data-id={"meal2"}
                                                       checked={meal2}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"} htmlFor="meal3">Obiad</label>
                                                <br/>
                                                <input type={"checkbox"}
                                                       id={"meal3"}
                                                       data-id={"meal3"}
                                                       checked={meal3}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"}
                                                       htmlFor="meal4">Podwieczorek</label>
                                                <br/>
                                                <input type={"checkbox"}
                                                       id={"meal4"}
                                                       data-id={"meal4"}
                                                       checked={meal4}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"} htmlFor="meal5">Kolacja</label>
                                                <br/>
                                                <input type={"checkbox"}
                                                       id={"meal5"}
                                                       data-id={"meal5"}
                                                       checked={meal5}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                    </div>

                                    {errorMessage ? <ErrorMessageShort message={errorMessage}/> : ''}
                                    <p>* - pola wymagane</p>
                                    <button className="btn_green"
                                            disabled={requestPending}>{requestPending ? "zapis trwa..." : "zapisz"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderFieldDiv = (field) => {
        return <div key={field} className="form-group">
            <label htmlFor={field}>{this.fields[field].label}{this.fields[field].required ? " *" : ""}</label>
            {this.renderFieldElement(field)}
        </div>
    }

    renderFieldElement = (field) => {
        switch (this.fields[field].type) {
            case "textarea":
                return <textarea className="form-control"
                                 id={field}
                                 data-id={field}
                                 onChange={this.handleInputChange}
                                 rows={10}
                                 value={this.state[field]}
                />
            default:
                return <input className="form-control"
                              id={field}
                              type={this.fields[field].type}
                              data-id={field}
                              onChange={this.handleInputChange}
                              value={this.state[field]}/>
        }
    }
}

export default DishUpdateByUser;
