import AbstractComponent from "../AbstractComponent/AbstractComponent";
import React from "react";
import routes from "../../routes";
import {Link} from "react-router-dom";
import {deleteDiet, getDiets} from "../../services/api/api";
import DietListItem from "./DietListItem";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import orangeImg from "../../assets/img/oranges.jpg";
import PaywallError from "../../error/paywallError";
import AuthError from "../../error/authError";

class DietList extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoading: false,
            errorMessage: null,
            count: null,
            page: 1,
            diets: [],
            noMoreItems: false,
            title: "Twoje diety"
        };
    };

    componentDidMount = async () => {
        const {onNotLoggedIn, isLoggedIn, onMetaUpdate} = this.props
        const {title} = this.state

        onMetaUpdate({
            metaTitle: title,
        })

        if (!isLoggedIn) {
            onNotLoggedIn();
        } else {
            this.changeState("isLoading", true);
            await this.loadDiets();
            this.changeState("isLoading", false);
        }
    };

    loadDiets = async () => {
        const {onPaywall, onNotLoggedIn} = this.props
        const currentState = {...this.state};
        try {
            const resp = await getDiets(this.state.page);
            currentState.count = resp.count;
            if (resp.diets.length > 0) {
                currentState.diets = currentState.diets.concat(...resp.diets);
            }
            if (currentState.diets.length === currentState.count) {
                currentState.noMoreItems = true;
            } else {
                currentState.page++;
            }
        } catch (error) {
            if (error instanceof PaywallError) {
                onPaywall()
            }
            if (error instanceof AuthError) {
                onNotLoggedIn()
            }
            currentState.errorMessage = error;
        }

        this.setState(currentState);
    };

    handleLoadMore = async (event) => {
        event.preventDefault();
        await this.loadDiets();
    };

    handleDelete = async (id) => {

        this.changeState("isLoading", true);
        const currentState = {...this.state};
        try {
            await deleteDiet(id);
            currentState.diets = currentState.diets.filter((diet) => {
                return diet.id !== id
            })
        } catch (error) {
            currentState.errorMessage = 'podczas usuwania diety wystąpił błąd';
        }
        currentState.isLoading = false;
        this.setState(currentState);
    };

    render() {
        const {errorMessage, isLoading, diets, noMoreItems} = this.state

        if (isLoading) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }

        return (
            <div className="content_container">
                <h2>Twoje zapisane diety</h2>

                <a className="btn_try" href={routes.generateDiet}>wygeneruj nową dietę</a>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                {
                                    diets.length === 0 ?
                                        <>
                                            Lista diet jest pusta.
                                            <Link
                                                to={routes.generateDiet}
                                                className={"action-button action-button-green"}
                                            >
                                                Generuj dietę
                                            </Link>
                                        </> :
                                        <ul className="list-group list-group-flush">
                                            {diets.map((diet, index) => {
                                                return <DietListItem diet={diet} key={index}
                                                                     onDelete={this.handleDelete}/>
                                            })}
                                            {
                                                noMoreItems ?
                                                    '' :
                                                    <li className="list-group-item">
                                                        <br/>
                                                        <button className={"btn_green"}
                                                                onClick={this.handleLoadMore}>wczytaj następne
                                                        </button>
                                                    </li>
                                            }
                                        </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default DietList;