import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {facebookRegister, googleRegister} from "../../services/api/api";
import queryString from "query-string";
import OAuthButton from "./OAuthButton";
import Loader from "../Loader/Loader";
import PageContent from "../PageContent/PageContent";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";

class OAuthRegister extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            errorMessage: '',
            isLoaded: true,
            newsletterConsent: false,
            rulesConsent: false,
            registrationFailed: false,
            code: '',
            redirectUrl: '',
            platform: ''
        };
    };

    componentDidMount = async () => {
        const {platform} = this.props;
        const {code, redirectUrl} = queryString.parse(this.props.location.search)
        const currentState = {...this.state}
        currentState.code = code
        currentState.platform = platform
        currentState.redirectUrl = decodeURI(redirectUrl)
        this.setState(currentState)
    };

    handleRegister = async (e) => {
        e.preventDefault();
        const {code, newsletterConsent, rulesConsent, redirectUrl, platform} = this.state;
        const currentState = {...this.state}
        currentState.errorMessage = null
        currentState.isLoaded = false

        this.setState(currentState)
        if (!rulesConsent) {
            currentState.errorMessage = 'Akceptacja regulaminu jest wymagana';
            currentState.isLoaded = true;

            this.setState(currentState)

            return;
        }

        try {
            const params = {
                code: code,
                newsletter_consent: newsletterConsent
            };
            const res = (platform === 'facebook') ?
                await facebookRegister(params) :
                await googleRegister(params)
            ;

            this.container.tokenStorage.setRefreshToken(res.refresh_token);
            this.container.tokenStorage.setToken(res.token);

            this.props.onSuccess(redirectUrl)
        } catch (e) {
            currentState.errorMessage = 'Rejestracja nie powiodła się';
            currentState.isLoaded = true;

            this.setState(currentState)
        }
    };

    render = () => {
        const {onMetaUpdate} = this.props;
        const {isLoaded, errorMessage, redirectUrl, platform} = this.state;

        if (!isLoaded) {
            return <Loader/>
        }

        if (errorMessage) {
            return <ErrorMessage
                withoutRefreshButton={true}
                message={<>
                    Podczas rejestracji wystąpił błąd.
                    <OAuthButton
                        platform={platform}
                        mode={'register'}
                        redirectUrl={redirectUrl}
                        buttonText={'spróbuj ponownie'}
                    />
                </>}/>
        }

        const formJSX = (
            <form onSubmit={this.handleRegister}>

                <Form.Group controlId={"rulesConsent"} className="mb-3">
                    <Form.Check
                        type="checkbox"
                        data-id={'rulesConsent'}
                        value={this.state.rulesConsent}
                        onChange={this.handleInputChange}
                        className={'text-lg-center'}
                    />
                    <Form.Text>
                        * Akceptuję <Link to={'/regulamin.pdf'} target={'_blank'}>regulamin</Link> i <Link
                        to={'/polityka-prywatnosci.pdf'} target={'_blank'}>polityka prywatności</Link>
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId={"newsletterConsent"} className="mb-3">
                    <Form.Check
                        type="checkbox"
                        data-id={'newsletterConsent'}
                        value={this.state.newsletterConsent}
                        onChange={this.handleInputChange}
                        className={'text-lg-center'}
                    />
                    <Form.Text>
                        Chcę zapisać się do newslettera
                    </Form.Text>
                </Form.Group>

                <div className="form-group">
                    <p className={'text-muted text-center'}>
                        * - pole wymagane
                    </p>
                </div>

                <button className={"btn_default"}>zapisz</button>

            </form>
        )

        return <PageContent onMetaUpdate={onMetaUpdate} title={'Rejestracja ' + platform}
        >{formJSX}</PageContent>
    }
}

export default OAuthRegister;