import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import axios from "../../services/axios/axios";
import endpoints from "../../services/api/endpoints";
import orangeImg from "../../assets/img/oranges.jpg";
import {Link} from "react-router-dom";
import Form from 'react-bootstrap/Form'
import ErrorMessageShort from "../ErrorMessage/ErrorMessageShort";
import {addUtmData, register} from "../../services/api/api";
import routes from "../../routes";
import queryString from "query-string";

class RegistrationForm extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            stage: 1,
            email: "",
            newPassword: "",
            errorMessage: "",
            formSent: false,
            rulesConsent: false,
            newsletterConsent: false,
        };
    }

    componentDidMount = async () => {
        const {onMetaUpdate} = this.props

        onMetaUpdate({metaTitle: 'Rejestracja'})
    }

    handleSubmit = async event => {
        event.preventDefault();

        if (this.state.password === "") {
            this.changeState('errorMessage', 'Hasło jest wymagane');

            return;
        }

        if (!this.state.rulesConsent) {
            this.changeState('errorMessage', 'Akceptacja regulaminu jest wymagana');

            return;
        }

        if (!this.state.newPassword || this.state.newPassword.length < 8) {
            this.changeState('errorMessage', 'Hasło musi mieć minimum 8 znaków');

            return;
        }

        const data = addUtmData({
            "password": this.state.newPassword,
            "email": this.state.email,
            "newsletter_consent": this.state.newsletterConsent,
        })

        try {
            const res = await register(data);
            this.container.tokenStorage.setRefreshToken(res.refresh_token);
            this.container.tokenStorage.setToken(res.token);

            const {redirectUrl} = queryString.parse(this.props.location.search)

            window.location.href = redirectUrl ? redirectUrl : routes.generateDiet;
        } catch (e) {
            this.changeState('errorMessage', 'Rejestracja nie powiodła się. Sprawdź poprawność danych formularza, jeśli wszystko jest OK, skontaktuj się proszę z nami pod adresem support@dieterian.com.');
        }
    }

    handleNextStage = event => {
        event.preventDefault();

        if (this.state.email === "") {
            this.changeState('errorMessage', 'Adres email jest wymagany');

            return;
        }

        axios.post(endpoints.validateEmail, {
            email: this.state.email
        })
            .then((response) => {
                const currentState = {...this.state};
                currentState.stage = 2;
                currentState.errorMessage = "";
                this.setState(currentState);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 409) {
                        this.changeState('errorMessage', 'Podany adres email istnieje już w bazie. Spróbuj się zalogować.');

                        return;
                    }
                    if (error.response.status === 400) {
                        this.changeState('errorMessage', 'Podany adres email jest niepoporawny.');

                        return;
                    }
                }

                this.changeState('errorMessage', 'Rejestracja nie powiodła się. Proszę spróbować później.');
            });
    };

    handlePrevStage = event => {
        event.preventDefault();

        this.changeState('stage', 1);
    };

    render = () => {
        if (this.state.formSent) {
            return (
                <div className="content_container">
                    <h2>Dziękujemy!</h2>
                    <p className={"seo_text"}>Formularz wysłany, sprawdź proszę swoją pocztę i aktywuj konto klikając w
                        przesłany przez nas
                        link.
                        Nie zapomnij sprawdzić folderu spam. W przypadku braku nadejścia wiadomości w ciągu następnych
                        kilku minut, skontaktuj się z nami
                        pod adresem support@dieterian.com wysyłając wiadomość z konta email użytego podczas rejestracji.
                    </p>
                    <br/>
                    <br/>
                    <br/>
                </div>
            );
        }
        let formJSX = "";
        if (this.state.stage === 1) {
            formJSX = (
                <>
                    <div className="form-group">
                        <p className={'text-lg-center'}>
                            <label htmlFor={"email"}>Email</label>
                            <input
                                type="email"
                                data-id="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                className={"form-control text-center"}
                                id={"email"}
                            />
                        </p>
                    </div>

                    <button className={"btn_default"} onClick={this.handleNextStage}>dalej</button>
                </>
            )
        } else {
            formJSX = (
                <>
                    <div className="form-group">
                        <p className={'text-lg-center'}>
                            <label htmlFor={"newPassword"}>* Hasło</label>
                            <input
                                type="password"
                                data-id="newPassword"
                                value={this.state.newPassword}
                                onChange={this.handleInputChange}
                                className={"form-control text-center"}
                                id={"newPassword"}
                            />
                        </p>
                    </div>

                    <Form.Group controlId={"rulesConsent"} className="mb-3">
                        <Form.Check
                            type="checkbox"
                            data-id={'rulesConsent'}
                            value={this.state.rulesConsent}
                            onChange={this.handleInputChange}
                            className={'text-lg-center'}
                        />
                        <Form.Text>
                            * Akceptuję <Link to={'/regulamin.pdf'} target={'_blank'}>regulamin</Link> i <Link
                            to={'/polityka-prywatnosci.pdf'} target={'_blank'}>polityka prywatności</Link>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId={"newsletterConsent"} className="mb-3">
                        <Form.Check
                            type="checkbox"
                            data-id={'newsletterConsent'}
                            value={this.state.newsletterConsent}
                            onChange={this.handleInputChange}
                            className={'text-lg-center'}
                        />
                        <Form.Text>
                            Chcę zapisać się do newslettera
                        </Form.Text>
                    </Form.Group>
                    <div className="form-group">
                        <p className={'text-muted text-center'}>
                            * - pole wymagane
                        </p>
                    </div>
                    <button className={"btn_default"}>zapisz</button>

                    <hr/>

                    <button className={"action-button action-button-green"}
                            onClick={this.handlePrevStage}>&laquo; powrót
                    </button>
                </>
            )
        }

        return (
            <div className="content_container">
                <h2>rejestracja - krok {this.state.stage} / 2</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                <div className="container">
                                    <div className="row">
                                        <div className={"col-lg-12"}>
                                            <form onSubmit={this.handleSubmit}>
                                                {this.state.errorMessage ?
                                                    <ErrorMessageShort withoutRefreshButton={true}
                                                                       message={this.state.errorMessage}/> : ''}
                                                {formJSX}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default RegistrationForm;