import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import queryString from 'query-string';
import {facebookConnect, facebookLogin, googleConnect, googleLogin} from "../../services/api/api";
import OAuthButton from "./OAuthButton";
import AuthError from "../../error/authError";
import Loader from "../Loader/Loader";
import PageContent from "../PageContent/PageContent";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {Redirect} from "react-router-dom";
import routes from "../../routes";

class OAuthLogin extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            errorMessage: '',
            accountNotFound: false,
            isLoaded: false,
            code: null,
            mode: null,
            redirectUrl: null,
            platform: null,
        };
    };

    componentDidMount = async () => {
        await this.handleAuth()
    };

    renderRedirectToRegister = () => {
        const {code, redirectUrl, platform} = this.state

        const platformRegisterUrl = (platform === 'facebook') ? routes.facebookRegister : routes.googleRegister

        return <Redirect to={platformRegisterUrl + "?code=" + code + '&redirectUrl=' + encodeURI(redirectUrl)}/>
    };

    handleAuth = async () => {
        const {platform} = this.props

        const currentState = {...this.state}
        currentState.platform = platform;

        const {code, state} = queryString.parse(this.props.location.search)
        const decodedState = this.decodeState(state)
        currentState.mode = decodedState.mode;
        currentState.redirectUrl = decodedState.redirectUrl;

        try {
            let res = null;
            switch (decodedState.mode) {
                case 'register': {
                    currentState.code = code;

                    break;
                }
                case 'connect': {
                    res = (platform === 'facebook') ? await facebookConnect(code) : await googleConnect(code);

                    this.props.onConnect();

                    return;
                }
                default: {
                    res = (platform === 'facebook') ? await facebookLogin(code) : await googleLogin(code);
                    currentState.errorMessage = '';
                    currentState.accountNotFound = false;

                    this.container.tokenStorage.setRefreshToken(res.refresh_token);
                    this.container.tokenStorage.setToken(res.token);
                    this.props.onLogin(decodedState.redirectUrl);
                }
            }
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.accountNotFound = true;
            } else {
                currentState.errorMessage = 'Logowanie nie powiodło się, spróbuj ponownie.';
            }
        }
        currentState.isLoaded = true;
        this.setState(currentState);
    };

    render = () => {
        const {onMetaUpdate} = this.props;
        const {isLoaded, errorMessage, accountNotFound, mode, redirectUrl, platform} = this.state;
        if (!isLoaded) {
            return <Loader/>
        }

        if (errorMessage) {
            return <ErrorMessage
                withoutRefreshButton={true}
                message={<>
                    Podczas logowania wystąpił błąd.
                    <OAuthButton
                        platform={platform}
                        mode={mode}
                        redirectUrl={redirectUrl}
                        buttonText={'spróbuj ponownie'}
                    />
                </>}/>
        }

        let content = '';
        let title = '';
        switch (mode) {
            case 'register': {
                return this.renderRedirectToRegister()
            }
            default: {
                if (accountNotFound) {
                    title = 'Logowanie nie powiodło się'
                    content = <>
                        <p className={'text-center'}>Wygląda na to, że logujesz się u nas pierwszy raz używając tego
                            konta {platform}. Zarejestruj się proszę (rejestracja jest darmowa).</p>
                        <OAuthButton
                            additionalClass={'wide'}
                            platform={platform}
                            mode={'register'}
                            redirectUrl={redirectUrl}
                            buttonText={'zarejestruj z ' + platform}
                        />
                    </>
                } else {
                    title = 'Logowanie zakończone powodzeniem'
                }
            }
        }

        return <PageContent onMetaUpdate={onMetaUpdate} title={title}
        >{content}</PageContent>
    }

    decodeState(state) {
        return JSON.parse(decodeURI(state));
    }
}

export default OAuthLogin;