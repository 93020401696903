import React from "react";
import {Link} from "react-router-dom";
import routes from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";

const ProductListItem = props => {
    const {product, isAdmin, onDelete} = props;
    return (
        <>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <Link to={routes.product + '/' + product.slug}>{product.name}</Link>
                        {isAdmin ? <> <br/>
                            <Link to={routes.adminProductForm + '/' + product.id}>
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    className={"actionBtn text-warning"}
                                />
                            </Link>
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className={"actionBtn text-danger"}
                                onClick={() => {
                                    if (window.confirm('Czy na pewno chcesz usunąć tą pozycję?')) {
                                        onDelete(product);
                                    }
                                }}
                            />
                        </> : ""}

                    </div>
                </div>
            </div>
            <hr/>
        </>
    );
};


export default ProductListItem;