import TokenStorage from "../TokenStorage/TokenStorage";
import FoodPreferencesCachedStorage from "../FoodPreferencesCached/FoodPreferencesCacheStorage";
import DietParamsStorage from "../DietParamsStorage/DietParamsStorage";

let instance = null;

class ServiceLocator {
    instance = null;

    constructor() {
        const tokenStorage = new TokenStorage();
        this.tokenStorage = tokenStorage;
        this.foodPreferencesCacheStorage = new FoodPreferencesCachedStorage(tokenStorage);
        this.dietParamsStorage = new DietParamsStorage()
    }

    static getInstance = () => {
        if (instance === null) {
            instance = new ServiceLocator();
        }

        return instance;
    }
}

export default ServiceLocator;