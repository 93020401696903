import ls from "local-storage";

class DietParamsStorage {

    localStorageKey = "dietParams";

    constructor() {
        this.dietParams = ls.get(this.localStorageKey);
    }

    setDietParams = (dietParams) => {
        this.dietParams = dietParams;
        ls.set(this.localStorageKey, dietParams);
    }

    getDietParams = () => {
        return this.dietParams
    }

    clear() {
        this.setDietParams(null)
    }
}

export default DietParamsStorage;