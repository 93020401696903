import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {
    createProgressLogItem,
    deleteProgressLogItem,
    getProgressLog,
    updateProgressLogItem
} from "../../services/api/api";
import orangeImg from "../../assets/img/oranges.jpg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";

class ProgressLog extends AbstractComponent {
    listLimit = 20;

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            showForm: false,
            isLoaded: false,
            page: 1,
            noMoreItems: false,
            items: [],
            errorMessage: null,
            formErrorMessage: null,
            lastResult: null,
            id: "",
            weight: "",
            fat: "",
            muscle: "",
            water: "",
            date: "",
            showDate: false,
            note: ""
        };
    };

    resetFormData = () => {
        const currentState = {...this.state}

        const lastItem = this.state.items.length > 0 ? this.state.items[0] : null;

        currentState.id = "";
        currentState.weight = lastItem ? lastItem.weight.toString() : "";
        currentState.fat = lastItem && lastItem.fat ? lastItem.fat.toString() : "";
        currentState.muscle = lastItem && lastItem.muscle ? lastItem.muscle.toString() : "";
        currentState.water = lastItem && lastItem.water ? lastItem.water.toString() : "";
        currentState.date = "";
        currentState.showDate = false;
        currentState.note = "";
        currentState.showForm = true;

        this.setState(currentState);
    }

    componentDidMount = async () => {
        const {onNotLoggedIn, isLoggedIn, onMetaUpdate} = this.props
        onMetaUpdate({metaTitle: 'Efekty diety'})
        if (!isLoggedIn) {
            onNotLoggedIn();
        } else {
            await this.loadItems()
        }
    };

    loadItems = async () => {
        this.changeState("isLoaded", false);
        const currentState = {
            ...this.state
        };

        try {
            const {page} = this.state;
            const items = await getProgressLog(page);
            if (items.length > 0) {
                currentState.items = currentState.items.concat(...items);
            }
            if (items.length < this.listLimit) {
                currentState.noMoreItems = true;
            } else {
                currentState.page++;
            }

        } catch (error) {
            currentState.errorMessage = "pobieranie danych nie powiodło się";
        }
        currentState.isLoaded = true;
        this.setState(currentState)
    };

    handleDelete = async (objectIdToRemove) => {
        const currentState = {...this.state};
        try {
            this.changeState("isLoaded", false);
            await deleteProgressLogItem(objectIdToRemove);
            const items = this.state.items.filter((item) => {
                return item.id !== objectIdToRemove;
            });
            currentState.isLoaded = true;
            currentState.items = items;
            this.setState(currentState);
        } catch (error) {
            currentState.errorMessage = "operacja nie powiodła sie";
            currentState.isLoaded = true;
            this.setState(currentState);
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const {weight, fat, muscle, water, note, date, id} = this.state;
        if (!weight) {
            this.changeState("formErrorMessage", "waga jest wymagana");

            return;
        }

        try {
            this.changeState("isLoaded", false);

            if (id) {
                await updateProgressLogItem(
                    id,
                    parseFloat(weight),
                    parseFloat(fat),
                    parseFloat(muscle),
                    parseFloat(water),
                    date,
                    note
                );
            } else {
                await createProgressLogItem(
                    parseFloat(weight),
                    parseFloat(fat),
                    parseFloat(muscle),
                    parseFloat(water),
                    date,
                    note
                );
            }
            this.changeState("items", []);
            await this.loadItems();
            const currentState = {...this.state};
            currentState.isLoaded = true;
            currentState.showForm = false;
            this.setState(currentState);
        } catch (error) {
            const currentState = {...this.state};
            currentState.formErrorMessage = "operacja nie powiodła sie";
            currentState.isLoaded = true;
            this.setState(currentState);
        }
    };

    toggleDate = () => {
        const {showDate} = this.state;
        this.changeState("showDate", !showDate);
    }

    toggleForm = () => {
        const {showForm} = this.state;
        if (!showForm) {
            this.resetFormData();
        } else {
            this.changeState("showForm", false);
        }
    }

    handleEdit = (event) => {
        event.preventDefault();
        const {items} = this.state;
        const id = parseInt(event.target.dataset.object_id);
        const currentState = {...this.state}

        for (const item of items) {
            if (item.id === id) {
                currentState.id = id;
                currentState.weight = item.weight;
                currentState.fat = item.fat_percentage ? item.fat_percentage : "";
                currentState.muscle = item.muscle_percentage ? item.muscle_percentage : "";
                currentState.water = item.water_percentage ? item.water_percentage : "";
                currentState.date = new Date(item.created_at).toISOString().split('T')[0];
                currentState.showDate = true;
                currentState.note = item.note;
                currentState.showForm = true;
                this.setState(currentState);

                return;
            }
        }

        currentState.errorMessage = "Nie znaleziono elementu do edycji";
        this.setState(currentState);
    }

    renderForm = () => {
        const {weight, fat, muscle, water, date, formErrorMessage, showDate, note} = this.state
        return (
            <>
                <button className={"action-button action-button-black"} onClick={this.toggleForm}>zamknij</button>
                {formErrorMessage ? (<div>{formErrorMessage}</div>) : ''}
                <form onSubmit={this.handleSubmit} className={"mt-3"}>
                    <div className="form-group">
                        <label htmlFor="weight">Waga</label>
                        <input
                            type="number"
                            className="form-control"
                            id="weight"
                            data-id="weight"
                            value={weight}
                            min={20}
                            max={300}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="fat">Procent tkanki tłuszczowej (opcjonalnie)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="fat"
                            data-id="fat"
                            value={fat}
                            min={1}
                            max={100}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="muscle">Procent tkanki mięśniowej (opcjonalnie)</label>
                        <input
                            type="number"
                            className="form-control"
                            data-id="muscle"
                            id="muscle"
                            value={muscle}
                            min={1}
                            max={100}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="water">Procent nawodnienia (opcjonalnie)</label>
                        <input
                            type="number"
                            className="form-control"
                            data-id="water"
                            id="water"
                            value={water}
                            min={1}
                            max={100}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Notatka (opcjonalnie)</label>
                        <input
                            type="note"
                            className="form-control"
                            data-id="note"
                            value={note}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor={"date"}>Data</label>
                        {
                            showDate ?
                                <input
                                    type="date"
                                    className="form-control"
                                    data-id="date"
                                    id="date"
                                    value={date}
                                    onChange={this.handleInputChange}
                                /> :
                                <button
                                    onClick={this.toggleDate}
                                    className={"form-control btn_black"}
                                >
                                    dziś (zmień)
                                </button>
                        }

                    </div>
                    <button className={"btn_green"}>zapisz</button>
                </form>
            </>
        )
    }

    render = () => {
        const {errorMessage, items, showForm, isLoaded} = this.state

        if (!isLoaded) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }

        const loadMoreItems = this.state.noMoreItems ?
            "" :
            <tr>
                <td colSpan={6}>
                    <button className={"btn_green"} onClick={this.loadItems}>Pokaż więcej</button>
                </td>
            </tr>
        ;


        return (
            <div className="content_container">
                <h2>Efekty diety</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                <div className="container">
                                    <div className="row">
                                        <div className={"col-lg-12"}>
                                            {
                                                showForm ?
                                                    this.renderForm() :
                                                    (
                                                        <button className={"btn_default"}
                                                                onClick={this.toggleForm}>Dodaj</button>
                                                    )
                                            }
                                        </div>
                                    </div>
                                    <div className="table-responsive-lg mt-4">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Data</th>
                                                <th scope="col">Waga</th>
                                                <th scope="col">%T</th>
                                                <th scope="col">%M</th>
                                                <th scope="col">%W</th>
                                                <th scope="col"> &nbsp; </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {items.map((item) => {
                                                return <tr key={item.id}>
                                                    <th scope="row">
                                                        {new Date(item.created_at).toISOString().split('T')[0]}<br/>
                                                        {item.note ?
                                                            <span className={"text-muted"}>{item.note}</span> : ''}
                                                    </th>
                                                    <td>{item.weight}</td>
                                                    <td>{item.fat_percentage ? item.fat_percentage + '%' : 'bd.'}</td>
                                                    <td>{item.muscle_percentage ? item.muscle_percentage + '%' : 'bd.'}</td>
                                                    <td>{item.water_percentage ? item.water_percentage + '%' : 'bd.'}</td>
                                                    <td>
                                                        <button className={"action-button action-button-red"}
                                                                onClick={() => {
                                                                    if (window.confirm('Czy na pewno chcesz usunąć tą pozycję?')) {
                                                                        this.handleDelete(item.id);
                                                                    }
                                                                }}>
                                                            usuń
                                                        </button>
                                                        <button className={"action-button action-button-orange"}
                                                                data-object_id={item.id}
                                                                onClick={this.handleEdit}>
                                                            edytuj
                                                        </button>
                                                    </td>
                                                </tr>
                                            })}

                                            {loadMoreItems}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProgressLog;
