const routes = {
    home: '/',
    resetPassword: '/resetuj-haslo',
    changePassword: '/zmien-haslo',
    tasteRecognition: '/badanie-gustu',
    register: '/rejestracja',
    dishes: '/potrawy',
    recipes: '/przepisy',
    recipe: '/przepis',
    tdeeCalculator: '/kalkulator-tdee',
    foodPreferences: '/ustawienia/gust',
    generateDiet: '/generuj-diete',
    activate: '/aktywuj',
    dish: '/potrawa',
    updateDish: '/edytuj-potrawe',
    showDiet: '/dieta',
    diets: '/diety',
    googleLogin: '/auth/google/login',
    googleConnect: '/auth/google/connect',
    googleRegister: '/auth/google/register',
    facebookLogin: '/auth/facebook/login',
    facebookConnect: '/auth/facebook/connect',
    facebookRegister: '/auth/facebook/register',
    subscriptions: '/cennik',
    paymentResult: '/platnosc-zakonczona',
    userSubscriptions: '/subskrypcje',
    progressLog: '/efekty-diety',
    shoppingLists: '/listy-zakupow',
    calorieCalculator: '/kalkulator-kalorii',
    mealAlternatives: "/alternatywy-dla-potrawy",
    contact: "/kontakt",
    aboutUs: "/o-nas",
    products: "/produkty",
    product: "/produkt",
    howDoesItWork: "/jak-to-dziala",
    blog: "/blog-dietetyczny",
    blogZapotrzebowanieKaloryczne: "/blog-dietetyczny/jak-okreslic-swoje-zapotrzebowanie-kaloryczne-i-dlaczego-jest-to-wazne-dla-skutecznego-odchudzania",
    blogZdrowePrzepisy: "/blog-dietetyczny/zdrowe-i-smaczne-przepisy-na-dieta-odchudzajaca-dieterian-com",
    adminDishForm: "/admin/dish",
    adminProductForm: "/admin/product",
    adminProducts: "/admin/products",
    adminUsers: "/admin/users"
};


routes.mealAlternativesFullPath = (dietId, dishSetId, mealId) => {
    return routes.mealAlternatives + "/" + dietId + "/" + dishSetId + "/" + mealId
}
routes.generateDietFullPath = (dietId, dishSetId) => {
    return routes.generateDiet + "/" + dietId + "/" + dishSetId
}

export default routes;