import React from "react";

const Loader = props => {
    return (
        <div className="content_container">
            <div className="d-flex justify-content-center p-5">
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div>
    );
};

export default Loader;