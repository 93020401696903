import React, {Component} from "react";
import ServiceLocator from "../../services/ServiceLocator/ServiceLocator";
import {Redirect} from "react-router";

class AbstractComponent extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef()
        this.container = ServiceLocator.getInstance();
    }

    scrollToTop = () => window.scrollTo(0, this.myRef.offsetHeight)

    changeState = (field, value) => {
        const currentState = {
            ...this.state
        };
        currentState[field] = value;
        this.setState(currentState);
    };

    handleInputChange = event => {
        const state = {...this.state};
        let value = null;

        if (event.target.nodeName === 'SELECT') {
            if (event.target.multiple) {
                value = Array.from(event.target.selectedOptions, option => option.value);
            } else {
                value = event.target.value
            }
        } else {
            switch (event.target.type) {
                case "checkbox":
                    value = event.target.checked;
                    break;
                default:
                    value = event.target.value
            }
        }

        state[event.target.dataset.id] = value;
        this.setState(state);
    }

    handleRedirect = (to) => {
        this.changeState("redirectTo", to)
    };

    renderRedirect = () => {
        const {redirectTo} = this.state;
        if (redirectTo) {
            return <Redirect to={redirectTo}/>
        }
    };
}

export default AbstractComponent;