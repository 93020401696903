import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {deleteFoodPreference, getFoodPreferences} from "../../services/api/api";
import orangeImg from "../../assets/img/oranges.jpg";

class FoodPreferenceList extends AbstractComponent {
    listLimit = 20;

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            page: 1,
            noMoreItems: false,
            items: [],
            errorMessage: null,
        };
    };

    componentDidMount = async () => {
        const {onNotLoggedIn, isLoggedIn, onMetaUpdate} = this.props
        if (!isLoggedIn) {
            onNotLoggedIn();
        } else {
            await this.loadItems()
        }

        onMetaUpdate({metaTitle: 'Twój gust'})
    };

    async componentDidUpdate(prevProps) {
        if (this.props.liked !== prevProps.liked) {
            await this.setState(this.getInitialState());
            await this.loadItems();
        }
    }

    loadItems = async () => {
        this.changeState("isLoaded", false);
        const currentState = {
            ...this.state
        };

        try {
            const items = await getFoodPreferences(this.state.page, this.props.liked, this.listLimit);
            if (items.length > 0) {
                currentState.items = currentState.items.concat(...items);
            }
            if (items.length < this.listLimit) {
                currentState.noMoreItems = true;
            } else {
                currentState.page++;
            }

        } catch (error) {
            currentState.errorMessage = "pobieranie danych nie powiodło się";
            console.log(error);
        }
        currentState.isLoaded = true;
        this.setState(currentState)
    };

    handleDelete = async (objectTypeToRemove, objectIdToRemove) => {
        try {
            await deleteFoodPreference(objectTypeToRemove, objectIdToRemove);
            const items = this.state.items.filter((item) => {
                return item.object_id !== objectIdToRemove || item.object_type_id !== objectTypeToRemove;
            });
            await this.container.foodPreferencesCacheStorage.refreshData();
            this.changeState("items", items);

        } catch (error) {
            const currentState = {...this.state};
            currentState.errorMessage = "operacja nie powiodła sie";
            console.log(error);
            this.setState(currentState);
        }
    };

    render = () => {
        const mode = this.props.liked;
        const {errorMessage, noMoreItems, items} = this.state;
        const errorMessageJSX = errorMessage ?
            <div className="alert alert-danger" role="alert">
                {errorMessage}
            </div> :
            ""
        const loadMoreItems = noMoreItems ?
            "" :
            <button
                onClick={this.loadItems}
                className={"btn_default"}
            >
                Pokaż więcej
            </button>

        return (
            <div className="content_container diet_generator">
                {this.renderRedirect()}

                <h2>{mode ? 'Ulubione' : 'Nielubiane'}</h2>


                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                {errorMessageJSX}
                                <ul className="list-group list-group-flush">
                                    {
                                        items.length === 0 ?
                                            'brak pozycji' :
                                            items.map((item) => {
                                                return <li
                                                    className="list-group-item"
                                                    key={item.id}
                                                >
                                                    {item.name}
                                                    <button
                                                        className={"action-button action-button-red"}
                                                        onClick={() => {
                                                            if (window.confirm('Czy na pewno chcesz usunąć tą pozycję?')) {
                                                                this.handleDelete(item.object_type_id, item.object_id);
                                                            }
                                                        }}
                                                    >
                                                        usuń
                                                    </button>
                                                </li>
                                            })}
                                </ul>
                                {loadMoreItems}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default FoodPreferenceList;
