import React from 'react';
import questionMarkImg from "../../assets/img/ico/ico-question-mark.png";

const HelpMuted = props => {
    const {id} = props;

    return (
        <div className={"row"}>
            <div className={"col-md-2 col-sm-4"}>
                <img src={questionMarkImg} alt={"info"}/>
            </div>
            <div className={"col-md-10 col-sm-8"}>
                <small id={id} className="form-text text-muted">
                    {props.children}
                </small>
            </div>
        </div>
    );
};

export default HelpMuted;