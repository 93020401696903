import ls from "local-storage";
import {getFoodPreferencesFullMap} from "../api/api";
import {sleep} from "../../utils/time";

class FoodPreferencesCachedStorage {

    constructor(tokenStorage) {
        this.tokenStorage = tokenStorage;
    }

    dataKey = "foodPreferences";
    updatedAtKey = "foodPreferencesUpdatedAt";
    lockKey = "foodPreferencesLock";

    refreshData = async () => {
        if (!this.tokenStorage.isTokenValid()) {
            return;
        }

        try {
            if (!ls.get(this.lockKey)) {
                ls.set(this.lockKey, true);
                const foodMap = await getFoodPreferencesFullMap();
                ls.set(this.dataKey, foodMap);
                ls.set(this.updatedAtKey, Date.now());
                ls.remove(this.lockKey);
            } else {
                for (let i = 0; i < 5; i++) {
                    await sleep(300);
                    if (!ls.get(this.lockKey)) {
                        return;
                    }
                }
                console.log("refreshing is taking to long, waiting aborted")
                ls.remove(this.lockKey);
            }
        } catch (error) {
            console.log("refreshing food preference data failed: " + error);
        }
    };

    getUpdatedAt = () => {
        return ls.get(this.updatedAtKey)
    };

    getDataAndRefreshIfNeeded = async () => {
        const updatedAt = this.getUpdatedAt();
        const hourAgo = new Date(new Date() - (1000 * 60 * 60));
        if (!updatedAt || updatedAt < hourAgo) {
            await this.refreshData();
        }

        return ls.get(this.dataKey);
    };

    isObjectLiked = async (objectType, objectId) => {
        if (!this.tokenStorage.isTokenValid()) {
            return null;
        }
        const foodPreferenceMap = await this.getDataAndRefreshIfNeeded();

        if (foodPreferenceMap[objectType]) {
            if (foodPreferenceMap[objectType].liked && foodPreferenceMap[objectType].liked.includes(objectId)) {
                return true;
            }

            if (foodPreferenceMap[objectType].disliked && foodPreferenceMap[objectType].disliked.includes(objectId)) {
                return false;
            }
        }

        return null;
    }
}

export default FoodPreferencesCachedStorage;