import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {adminGetCategories, adminGetProductById, getUnits, upsertProduct} from "../../services/api/api";
import Loader from "../Loader/Loader";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import AuthError from "../../error/authError";
import orangeImg from "../../assets/img/oranges.jpg";
import routes from "../../routes";
import Form from "react-bootstrap/Form";
import Editor from "../WysiwygEditor/Editor";

class ProductForm extends AbstractComponent {
    defaultTitle = "Dodawanie produktu"
    fields = {
        "name": "text",
        "description": "textarea",
        "protein": "number",
        "fat": "number",
        "carbohydrate": "number",
        "fiber": "number",
        "carbohydrate_fixed_kcal": "number",
        "lactose_free": "checkbox",
        "dairy_free": "checkbox",
        "gluten_free": "checkbox",
        "vegetarian": "checkbox",
        "vegan": "checkbox",
        "categories": "multiselect",
        "unit": "select",
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            errorMessage: "",
            product: "",
            title: this.defaultTitle,
            description: "",
            name: "",
            protein: 0,
            fat: 0,
            carbohydrate: 0,
            fiber: 0,
            carbohydrate_fixed_kcal: "",
            lactose_free: false,
            dairy_free: false,
            gluten_free: false,
            vegetarian: false,
            vegan: false,
            requestPending: false,
            available_categories: [],
            available_unit: [],
            categories: [],
            unit: "",
        }
    };

    componentDidMount = async () => {
        const {title} = this.state
        const {onMetaUpdate, onNotLoggedIn, id} = this.props;
        const currentState = {
            ...this.state
        }
        let metaTitle = title
        try {
            const allCategories = await adminGetCategories()
            currentState.available_unit = await getUnits()
            currentState.available_categories = allCategories.filter((item) => {
                return (item.type === 'produkt')
            })
            if (id) {
                currentState.product = await adminGetProductById(id)
                Object.keys(this.fields).forEach((field) => {
                    if (this.fields[field] === 'multiselect') {
                        if (currentState.product[field]) {
                            for (const option of currentState.product[field]) {
                                currentState[field].push(parseFloat(option.id))
                            }

                        }
                    } else if (this.fields[field] === 'select') {
                        if (currentState.product[field]) {
                            currentState[field] = currentState.product[field].id
                        }
                    } else {
                        currentState[field] = currentState.product[field]
                    }
                })
                metaTitle = currentState.product.name
            } else {
                currentState.unit = currentState.available_unit[0].id
            }
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else {
                currentState.errorMessage = "pobieranie danych nie powiodło się";
            }
        }
        onMetaUpdate({metaTitle: metaTitle})
        currentState.isLoaded = true
        this.setState(currentState)
    }

    handleUpsertProduct = async event => {
        event.preventDefault();
        const {product} = this.state
        const values = {}
        if (product) {
            values.id = product.id
        }
        const currentState = {...this.state}
        currentState.requestPending = true
        this.setState(currentState)
        Object.keys(this.fields).forEach((field) => {
            if (field === 'categories') {
                values[field] = currentState[field].map((val) => {
                    return parseInt(val)
                })
            } else {
                values[field] = this.fields[field] === "number" || this.fields[field] === "select" ? parseFloat(currentState[field]) : currentState[field]
            }
        })

        try {
            const resp = await upsertProduct(values)
            if (!product) {
                window.location.href = routes.adminProductForm + "/" + resp.id
            }
            currentState.errorMessage = ""
        } catch (e) {
            currentState.errorMessage = "failed to save product: " + e.message
        }
        currentState.requestPending = false

        this.setState(currentState)
    }

    render = () => {
        const {errorMessage, isLoaded, title, requestPending} = this.state
        if (!isLoaded) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }

        return (
            <div className="content_container">
                <h2>{title}</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="calc2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <form onSubmit={this.handleUpsertProduct}>
                                    {Object.keys(this.fields).map((field) => {
                                        return this.renderFieldDiv(field)
                                    })}
                                    {errorMessage ? <ErrorMessage message={errorMessage}/> : ''}
                                    <button className="btn_green"
                                            disabled={requestPending}>{requestPending ? "zapis trwa..." : "zapisz"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderFieldDiv = (field) => {
        return <div key={field} className="form-group">
            <label htmlFor={field}>{field}</label>
            {this.renderFieldElement(field)}
        </div>
    }

    renderFieldElement = (field) => {
        let options = []
        switch (this.fields[field]) {
            case "multiselect":
                options = this.state["available_" + field] ? this.state["available_" + field] : []
                return <select multiple={true}
                               className="form-control"
                               id={field}
                               data-id={field}
                               onChange={this.handleInputChange}
                               value={this.state[field]}
                >
                    {options.map((item) => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                    })}
                </select>
            case "select":
                options = this.state["available_" + field] ? this.state["available_" + field] : []
                return <select className="form-control"
                               id={field}
                               data-id={field}
                               onChange={this.handleInputChange}
                               value={this.state[field]}
                >
                    {options.map((item) => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                    })}
                </select>
            case "textarea":
                return <Editor
                    id={field}
                    value={this.state[field]}
                    data-id={field}
                    onChange={this.handleInputChange}
                ></Editor>
            case "checkbox":
                return <Form.Check
                    id={field}
                    type="checkbox"
                    data-id={field}
                    onChange={this.handleInputChange}
                    value={this.state[field]}
                    checked={this.state[field]}
                />
            case "number":
                return <input className="form-control"
                              id={field}
                              type={this.fields[field]}
                              data-id={field}
                              step={0.01}
                              onChange={this.handleInputChange}
                              value={this.state[field]}/>
            default:
                return <input className="form-control"
                              id={field}
                              type={this.fields[field]}
                              data-id={field}
                              onChange={this.handleInputChange}
                              value={this.state[field]}/>
        }
    }
}

export default ProductForm;
