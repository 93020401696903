import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {
    adminDeleteDish,
    adminGetCategories,
    adminGetDishById,
    deleteDishComponent,
    productAutocomplete,
    recipeProposal,
    upsertDish,
    upsertDishComponent
} from "../../services/api/api";
import AsyncSelect from 'react-select/async';
import DishComponents from "../Dish/DishComponents";
import {faArrowAltCircleLeft} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../Loader/Loader";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import DishNutrition from "../Dish/DishNutrition";
import AuthError from "../../error/authError";
import orangeImg from "../../assets/img/oranges.jpg";
import routes from "../../routes";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Editor from "../WysiwygEditor/Editor";

class DishForm extends AbstractComponent {
    defaultTitle = "Dodawanie przepisu"
    fields = {
        "name": "text",
        "portions": "number",
        "available_for_diet_creation": "checkbox",
        "active": "checkbox",
        "recipe": "textarea",
        "slug": "text",
        "comment": "textarea",
        "description": "textarea",
        "categories": "multiselect"
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            autocompleteProductText: "",
            productId: null,
            productAmount: "",
            productAmountDesc: "",
            isLoaded: false,
            tempDish: null,
            errorMessage: null,
            formErrorMessage: null,
            editedProduct: null,
            asyncSelectValue: null,
            dish: null,
            title: this.defaultTitle,
            name: "",
            available_for_diet_creation: false,
            recipe: "",
            slug: "",
            comment: "",
            available_categories: [],
            categories: [],
            requestPending: false,
        };
    };

    componentDidMount = async () => {
        const {title} = this.state
        const {onMetaUpdate, onNotLoggedIn, id} = this.props;
        const currentState = {
            ...this.state
        };
        if (id) {
            try {
                currentState.dish = await adminGetDishById(id);
                Object.keys(this.fields).forEach((field) => {
                    if (this.fields[field] === 'multiselect') {
                        if (currentState.dish[field]) {
                            for (const option of currentState.dish[field]) {
                                currentState[field].push(parseInt(option.id))
                            }

                        }
                    } else {
                        currentState[field] = currentState.dish[field]
                    }
                })
                onMetaUpdate({
                    metaTitle: currentState.dish.title,
                })
                currentState.isLoaded = true;
            } catch (error) {
                if (error instanceof AuthError) {
                    currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                    onNotLoggedIn()
                } else {
                    currentState.errorMessage = "pobieranie danych nie powiodło się";
                }
            }
        } else {
            onMetaUpdate({
                metaTitle: title,
            })
        }
        currentState.isLoaded = true

        const allCategories = await adminGetCategories()

        currentState.available_categories = allCategories.filter((item) => {
            return (item.type === 'potrawa' || item.type === 'typ potrawy')
        })
        this.setState(currentState)
    }

    handleProductAutocomplete = (inputValue) => {
        this.changeState("autocompleteProductText", inputValue);

        return inputValue;
    }

    handleProductSelect = value => {
        this.amountInput.focus();
        const currentState = {...this.state};
        currentState.productId = value.value;
        currentState.asyncSelectValue = value;

        this.setState(currentState);
    }

    handleRemoveComponent = async (dishComponent) => {
        const {onNotLoggedIn} = this.props
        const {dish} = this.state
        const currentState = {...this.state}
        try {
            currentState.dish = await deleteDishComponent(dish.id, dishComponent.product.id)
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else {
                currentState.errorMessage = "operacja nie powiodła się";
            }
        }
        this.setState(currentState);
    }

    handleEditComponent = (dishComponent) => {
        const currentState = {...this.state}
        currentState.productId = dishComponent.product.id
        currentState.productAmount = dishComponent.amount
        currentState.productAmountDesc = dishComponent.amount_description
        this.amountInput.focus()
        currentState.editedProduct = dishComponent.product.name
        this.setState(currentState);
    }

    handleUpsertComponent = async event => {
        const {onNotLoggedIn} = this.props
        const {productId, dish, productAmount, productAmountDesc} = this.state
        event.preventDefault();
        const currentState = {...this.state}
        if (!this.state.productId || !this.state.productAmount) {
            currentState.errorMessage = "oba pola są wymagane";
        } else {
            try {
                currentState.dish = await upsertDishComponent(dish.id, parseInt(productId), parseFloat(productAmount), productAmountDesc)
                currentState.productId = null
                currentState.productAmount = ""
                currentState.productAmountDesc = ""
                currentState.autocompleteProductText = null;
                currentState.editedProduct = null;
                currentState.asyncSelectValue = null;
            } catch (error) {
                if (error instanceof AuthError) {
                    currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                    onNotLoggedIn()
                } else {
                    currentState.errorMessage = "operacja nie powiodła się";
                }
            }
        }
        this.setState(currentState);
        this.productSelect.focus();
    }

    loadOptions = async (inputValue, callback) => {
        const data = []
        if (inputValue.length > 0) {
            try {
                const products = await productAutocomplete(inputValue);
                Object.keys(products).forEach(function (key) {
                    data.push({
                        value: key,
                        label: products[key]
                    });
                })
            } catch (error) {
                console.log(error)
            }
        }
        callback(data)
    };

    handleCancelEdit = () => {
        const currentState = {...this.state}
        currentState.editedProduct = null
        currentState.productAmount = ""
        this.setState(currentState)
    }

    handleUpsertDish = async event => {
        event.preventDefault();
        const {dish} = this.state
        const values = {}
        if (dish) {
            values.id = dish.id
        }
        const currentState = {...this.state}
        currentState.requestPending = true
        this.setState(currentState)
        Object.keys(this.fields).forEach((field) => {
            if (field === 'categories') {
                values[field] = currentState[field].map((val) => {
                    return parseInt(val)
                })
            } else {
                values[field] = this.fields[field] === "number" ? parseInt(currentState[field]) : currentState[field]
            }
        })

        try {
            const resp = await upsertDish(values)
            if (!dish) {
                window.location.href = routes.adminDishForm + "/" + resp.id
            }
            currentState.errorMessage = ""
        } catch (e) {
            currentState.errorMessage = "failed to save dish: " + e.message
            console.log(e)
        }
        currentState.requestPending = false

        this.setState(currentState)
    }

    handleRecipeProposal = async () => {
        const {dish, recipe} = this.state
        const currentState = {...this.state}
        try {
            this.changeState("isLoaded", false)
            const proposal = await recipeProposal(dish.id)
            currentState.recipe = proposal + '<hr>' + recipe
        } catch (error) {
            currentState.errorMessage = "failed to get recipe proposal" + error.message
        }
        currentState.isLoaded = true

        this.setState(currentState)
    }

    handleDelete = async () => {
        const {dish} = this.state
        try {
            await adminDeleteDish(dish.id)

            window.location.href = routes.recipes
        } catch (error) {
            alert(error)
        }
    }

    render = () => {
        const {errorMessage, dish, isLoaded, editedProduct, asyncSelectValue, title, requestPending} = this.state
        const {onNotLoggedIn} = this.props
        if (!isLoaded) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }

        return (
            <div className="content_container">
                <h2>{title}</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="calc2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                {dish ? <>
                                    <Link className={"dish_name"}
                                          target={"_blank"}
                                          to={routes.recipe + '/' + dish.slug}><strong>{dish.name}</strong>
                                    </Link>
                                    <hr/>
                                </> : ""}
                                <button className="btn_red" onClick={() => {
                                    if (window.confirm('Czy na pewno chcesz usunąć tą pozycję?')) {
                                        this.handleDelete()
                                    }
                                }}>Usuń
                                </button>
                                <hr/>
                                <button className="btn_green" onClick={this.handleRecipeProposal}>Zaproponuj przepis
                                </button>
                                <form onSubmit={this.handleUpsertDish}>
                                    {Object.keys(this.fields).map((field) => {
                                        return this.renderFieldDiv(field)
                                    })}
                                    {errorMessage ? <ErrorMessage message={errorMessage}/> : ''}
                                    <button className="btn_green"
                                            disabled={requestPending}>{requestPending ? "zapis trwa..." : "zapisz"}</button>
                                </form>

                                {dish ?
                                    <>
                                        <strong>Składniki:</strong>
                                        <hr/>
                                        <form onSubmit={this.handleUpsertComponent}>
                                            <div className="form-group row">
                                                <div className="col-sm-5">
                                                    {editedProduct ?
                                                        (
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    icon={faArrowAltCircleLeft}
                                                                    onClick={this.handleCancelEdit}
                                                                    title={"cofnij"}
                                                                    alt={"cofnij"}
                                                                /> &nbsp;
                                                                <strong>Edytujesz ilość
                                                                    produktu: {editedProduct}</strong>
                                                            </div>
                                                        ) :
                                                        <AsyncSelect
                                                            placeholder={"produkt..."}
                                                            ref={(input) => {
                                                                this.productSelect = input;
                                                            }}
                                                            cacheOptions
                                                            defaultOptions
                                                            loadOptions={this.loadOptions}
                                                            onInputChange={this.handleProductAutocomplete}
                                                            onChange={this.handleProductSelect}
                                                            value={asyncSelectValue}
                                                        />
                                                    }
                                                </div>
                                                <div className="col-sm-2">
                                                    <input placeholder={"ilość g/ml"}
                                                           className="form-control"
                                                           id={"prod_amount"}
                                                           type="number"
                                                           data-id={"productAmount"}
                                                           ref={(input) => {
                                                               this.amountInput = input;
                                                           }}
                                                           onChange={this.handleInputChange}
                                                           value={this.state.productAmount}/>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input placeholder={"ilość opisowo"}
                                                           className="form-control"
                                                           id={"prod_amount_desc"}
                                                           type="text"
                                                           data-id={"productAmountDesc"}
                                                           ref={(input) => {
                                                               this.amountInput = input;
                                                           }}
                                                           onChange={this.handleInputChange}
                                                           value={this.state.productAmountDesc}/>
                                                </div>
                                                <div className="col-sm-1">
                                                    <button className="btn_green">Zapisz</button>
                                                </div>
                                            </div>
                                            {errorMessage ? <ErrorMessage message={errorMessage}/> : ''}
                                            {errorMessage ? <ErrorMessage message={errorMessage}/> : ''}
                                        </form>
                                        <DishComponents
                                            dish={dish}
                                            onDeleteComponent={this.handleRemoveComponent}
                                            onEditComponent={this.handleEditComponent}
                                            withNutritionInfo={true}
                                            onNotLoggedIn={onNotLoggedIn}
                                        />
                                    </> :
                                    ""
                                }
                            </div>
                            <div className="col-md-5">
                                <div className="mod_calc">
                                    {dish ? <DishNutrition dish={dish}/> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderFieldDiv = (field) => {
        return <div key={field} className="form-group">
            <label htmlFor={field}>{field}</label>
            {this.renderFieldElement(field)}
        </div>
    }

    renderFieldElement = (field) => {
        switch (this.fields[field]) {
            case "multiselect":
                const options = this.state["available_" + field] ? this.state["available_" + field] : []
                return <select multiple={true}
                               className="form-control"
                               id={field}
                               data-id={field}
                               onChange={this.handleInputChange}
                               value={this.state[field]}
                               size={15}
                >
                    {options.map((item) => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                    })}
                </select>
            case "textarea":
                return <Editor
                    id={field}
                    value={this.state[field]}
                    data-id={field}
                    onChange={this.handleInputChange}
                ></Editor>
            case "checkbox":
                return <Form.Check
                    id={field}
                    type="checkbox"
                    data-id={field}
                    onChange={this.handleInputChange}
                    value={this.state[field]}
                    checked={this.state[field]}
                />
            case "number":
                return <input className="form-control"
                              id={field}
                              type={this.fields[field]}
                              data-id={field}
                              onChange={this.handleInputChange}
                              step={0.01}
                              value={this.state[field]}/>
            default:
                return <input className="form-control"
                              id={field}
                              type={this.fields[field]}
                              data-id={field}
                              onChange={this.handleInputChange}
                              value={this.state[field]}/>
        }
    }
}

export default DishForm;
