import appConfig from "../../config/config";

const endpoints = {
    login: appConfig.apiUrl + "/security/login",
    userProfile: appConfig.apiUrl + "/user/profile",
    refreshToken: appConfig.apiUrl + "/security/refresh-token",
    resetPassword: appConfig.apiUrl + "/security/reset-password",
    sendResetPasswordToken: appConfig.apiUrl + "/security/send-reset-password-token",
    changePassword: appConfig.apiUrl + "/security/change-password",
    validateEmail: appConfig.apiUrl + "/user/validate-email",
    register: appConfig.apiUrl + "/user/register",
    indexProductsForTasteRecognition: appConfig.apiUrl + "/product/index-products-for-taste-recognition",
    createFoodPreference: appConfig.apiUrl + "/food-preferences/create",
    dishBySlug: appConfig.apiUrl + "/dish/slug",
    dishIndex: appConfig.apiUrl + "/dish/index",
    tempDish: appConfig.apiUrl + "/dish/temp",
    tdeeCalculator: appConfig.apiUrl + "/calculator/tdee",
    foodPreferencesFullMap: appConfig.apiUrl + "/food-preferences/full-map",
    addFoodPreference: appConfig.apiUrl + "/food-preferences/create",
    indexFoodPreference: appConfig.apiUrl + "/food-preferences/index",
    deleteFoodPreference: appConfig.apiUrl + "/food-preferences/delete",
    generateDiet: appConfig.apiUrl + "/diet/generate",
    indexMealAlternatives: appConfig.apiUrl + "/diet/index-meal-alternatives",
    showDiet: appConfig.apiUrl + "/diet/show",
    saveDiet: appConfig.apiUrl + "/diet/save",
    updateDishSetItem: appConfig.apiUrl + "/diet/update-dish-set-item",
    deleteDishSet: appConfig.apiUrl + "/diet/delete-dish-set",
    dietIndex: appConfig.apiUrl + "/diet/index",
    deleteDiet: appConfig.apiUrl + "/diet/delete",
    googleAuthRedirectUrl: appConfig.apiUrl + "/auth/google/redirect-url",
    googleAuthLogin: appConfig.apiUrl + "/auth/google/login",
    googleAuthRegister: appConfig.apiUrl + "/auth/google/register",
    googleAuthConnect: appConfig.apiUrl + "/auth/google/connect",
    googleAuthDisconnect: appConfig.apiUrl + "/auth/google/disconnect",
    facebookAuthRedirectUrl: appConfig.apiUrl + "/auth/facebook/redirect-url",
    facebookAuthLogin: appConfig.apiUrl + "/auth/facebook/login",
    facebookAuthRegister: appConfig.apiUrl + "/auth/facebook/register",
    facebookAuthConnect: appConfig.apiUrl + "/auth/facebook/connect",
    facebookAuthDisconnect: appConfig.apiUrl + "/auth/facebook/disconnect",
    activateAccount: appConfig.apiUrl + "/security/activate",
    subscriptions: appConfig.apiUrl + "/subscription/index",
    buySubscription: appConfig.apiUrl + "/user-subscription",
    userActiveSubscriptions: appConfig.apiUrl + "/user-subscription",
    progressLog: appConfig.apiUrl + "/progress-log",
    shoppingListIndex: appConfig.apiUrl + "/shopping-list/index",
    shoppingListDelete: appConfig.apiUrl + "/shopping-list/delete",
    shoppingListUpdate: appConfig.apiUrl + "/shopping-list/update",
    shoppingListCreate: appConfig.apiUrl + "/shopping-list/create",
    shoppingListShow: appConfig.apiUrl + "/shopping-list/show",
    shoppingListItemDelete: appConfig.apiUrl + "/shopping-list/delete-item",
    shoppingListItemCreate: appConfig.apiUrl + "/shopping-list/create-item",
    shoppingListItemUpdate: appConfig.apiUrl + "/shopping-list/update-item",
    productAutocomplete: appConfig.apiUrl + "/product/autocomplete",
    tempDishComponent: appConfig.apiUrl + "/dish/temp/component",
    resetTempDish: appConfig.apiUrl + "/dish/temp/reset",
    saveTempDish: appConfig.apiUrl + "/dish/temp/save",
    tempRegister: appConfig.apiUrl + "/user/temp-register",
    dishStats: appConfig.apiUrl + "/dish/stats",
    productIndex: appConfig.apiUrl + "/products",
    adminUserIndex: appConfig.apiUrl + "/admin/users",
    adminGetCategories: appConfig.apiUrl + "/admin/categories",
    getUnits: appConfig.apiUrl + "/unit/index",
    adminUpsertDish: appConfig.apiUrl + "/admin/dish",
    adminAddProduct: appConfig.apiUrl + "/admin/product",
    updateDishByAuthor: appConfig.apiUrl + "/dish/update-by-author",
    productBySlug: appConfig.apiUrl + "/product",

    tempDishFromDish(id) {
        return appConfig.apiUrl + "/dish/temp/from/" + id
    },
    adminUpdateProduct(id) {
        return appConfig.apiUrl + "/admin/product/" + id
    },
    adminUpsertDishComponent: (dishId) => {
        return appConfig.apiUrl + "/admin/dish/" + dishId + "/component"
    },
    adminDeleteDishComponent: (dishId, productId) => {
        return appConfig.apiUrl + "/admin/dish/" + dishId + "/component/" + productId
    },
    adminGetDishById: (dishId) => {
        return appConfig.apiUrl + "/admin/dish/id/" + dishId
    },
    adminGetProductById(id) {
        return appConfig.apiUrl + "/admin/product/" + id
    },
    adminDeleteProduct(id) {
        return appConfig.apiUrl + "/admin/product/" + id
    },
    adminDeleteUser(id) {
        return appConfig.apiUrl + "/admin/users/" + id
    },
    adminDeleteDish(id) {
        return appConfig.apiUrl + "/admin/dish/" + id
    },
    adminDuplicateDish(id) {
        return appConfig.apiUrl + "/admin/dish/" + id + "/create-duplicate"
    },
    adminCreateDishVariant(id) {
        return appConfig.apiUrl + "/admin/dish/" + id + "/create-variant"
    },
    adminDishRecipeProposal(id) {
        return appConfig.apiUrl + "/admin/dish/" + id + "/recipe-proposal"
    },
};

export default endpoints;