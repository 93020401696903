import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import axios from "../../services/axios/axios";
import endpoints from "../../services/api/endpoints";

class TasteRecognition extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            isCompleted: false,
            currentKey: 0,
            products: [],
            errorMessage: null
        };
    };

    componentDidMount = () => {
        const currentState = {
            ...this.state
        };

        axios.get(endpoints.indexProductsForTasteRecognition)
            .then((response) => {
                let products = [];
                Object.keys(response.data).forEach(function (key) {
                    products.push({id: key, name: response.data[key]});
                });
                currentState.products = products;
                currentState.isLoaded = true;
                this.setState(currentState)
            })
            .catch((error) => {
                console.log(error);
                currentState.errorMessage = "podczas pobierania listy produktow wystąpił błąd";
                currentState.isCompleted = true;

                this.setState(currentState)
            });
    };

    handleLike = () => {
        this.createFoodPreference(true);
    };

    handleDislike = () => {
        this.createFoodPreference(false);
    };

    handleSkip = () => {
        this.increaseCurrentKey();
    };

    createFoodPreference = (like) => {
        axios.post(endpoints.createFoodPreference, {
            "object_type_id": 2,
            "object_id": parseInt(this.state.products[this.state.currentKey].id, 10),
            "liked": like
        })
            .then(() => {
                this.increaseCurrentKey();
            })
            .catch((error) => {
                console.log(error);
                this.changeState('errorMessage', 'Nie udało się dodać preferencji żywieniowych.');
            });
    };

    increaseCurrentKey() {
        const productCount = this.state.products.length;
        const nextKey = this.state.currentKey + 1;
        if (nextKey < productCount) {
            this.changeState('currentKey', nextKey);
        } else {
            this.changeState('isCompleted', true);
        }
    }

    render = () => {
        console.log();
        let jsx = 'loading...';
        const product = this.state.products[this.state.currentKey];

        if (!this.state.isCompleted && this.state.isLoaded) {
            jsx = (
                <div>
                    <span>{product.name}</span> <span onClick={this.handleLike}>lubię</span> <span
                    onClick={this.handleDislike}>nie lubię</span> <span onClick={this.handleSkip}>pomiń</span>
                </div>
            )
        } else if (this.state.isCompleted) {
            jsx = (
                <div>
                    Dziękujemy, teraz będziemy mogli oferować Ci bardziej dopasowane do Twojego gustu potrawy!
                </div>
            )
        } else if (this.state.errorMessage !== "") {
            jsx = (
                <div>
                    Wystąpił błąd!
                </div>
            )
        }

        return (
            <div>
                {jsx}
            </div>
        )

    }
}

export default TasteRecognition;
