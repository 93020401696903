import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

require('dotenv').config()
const trackingId = "UA-170508805-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactDOM.render(<App/>, document.getElementById('root'));

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
if (params.utm_medium) {
    document.cookie = 'utm_medium=' + params.utm_medium + ';max-age=604800;domain=dieterian.com'
}
if (params.utm_source) {
    document.cookie = 'utm_source=' + params.utm_source + ';max-age=604800;domain=dieterian.com'
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
