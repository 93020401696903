import ServiceLocator from "../ServiceLocator/ServiceLocator";

export function getInitialDietGeneratorState() {
    const container = ServiceLocator.getInstance();
    const dietParams = container.dietParamsStorage.getDietParams()

    return {
        kcal: dietParams && dietParams.kcal ? dietParams.kcal : 2000,
        mealCount: dietParams && dietParams.mealCount ? dietParams.mealCount : 5,
        glutenFree: dietParams && dietParams.glutenFree ? dietParams.glutenFree : false,
        dairyFree: dietParams && dietParams.dairyFree ? dietParams.dairyFree : false,
        lactoseFree: dietParams && dietParams.lactoseFree ? dietParams.lactoseFree : false,
        vegetarian: dietParams && dietParams.vegetarian ? dietParams.vegetarian : false,
        vegan: dietParams && dietParams.vegan ? dietParams.vegan : false,
        overrideDietParams: dietParams && dietParams.overrideDietParams ? dietParams.overrideDietParams : false,
        proteinRangeMin: dietParams && dietParams.energy_source_parameters ? dietParams.energy_source_parameters.protein_energy_percentage.min : 15,
        proteinRangeMax: dietParams && dietParams.energy_source_parameters ? dietParams.energy_source_parameters.protein_energy_percentage.max : 25,
        fatRangeMin: dietParams && dietParams.energy_source_parameters ? dietParams.energy_source_parameters.fat_energy_percentage.min : 20,
        fatRangeMax: dietParams && dietParams.energy_source_parameters ? dietParams.energy_source_parameters.fat_energy_percentage.max : 30,
        dietData: null,
        redirect: false,
        ordinalNumber: 1,
    };
}

export function getDietGeneratorParamsFromState(state) {
    const {
        dietData,
        ordinalNumber,
        kcal,
        mealCount,
        glutenFree,
        dairyFree,
        lactoseFree,
        vegetarian,
        vegan,
        overrideDietParams,
        proteinRangeMin,
        proteinRangeMax,
        fatRangeMin,
        fatRangeMax
    } = state;

    const dietParams = {
        ordinalNumber: ordinalNumber,
        kcal: kcal,
        mealCount: mealCount,
        glutenFree: glutenFree,
        dairyFree: dairyFree,
        lactoseFree: lactoseFree,
        vegetarian: vegetarian,
        vegan: vegan,
    };

    if (overrideDietParams) {
        dietParams.energy_source_parameters = {
            "protein_energy_percentage": {
                "min": proteinRangeMin,
                "max": proteinRangeMax
            },
            "fat_energy_percentage": {
                "min": fatRangeMin,
                "max": fatRangeMax
            }
        };
    }
    if (dietData && dietData.diet) {
        dietParams['diet_id'] = dietData.diet.id;
    }

    const dietParamsToStore = {...dietParams}
    dietParamsToStore.overrideDietParams = overrideDietParams
    delete dietParamsToStore.diet_id
    delete dietParamsToStore.ordinalNumber

    const container = ServiceLocator.getInstance();
    container.dietParamsStorage.setDietParams(dietParamsToStore)

    return dietParams;
}