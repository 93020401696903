import React, {Component} from "react";
import axios from "axios";
import endpoints from "../../services/api/endpoints";
import orangeImg from "../../assets/img/oranges.jpg";

class SendResetPasswordTokenForm extends Component {

    state = {
        email: "",
        isSent: false
    };

    handleInputChange = event => {
        const state = {...this.state};
        state[event.target.dataset.id] = event.target.value;
        this.setState(state);
    };

    componentDidMount = () => {
        const {onMetaUpdate} = this.props

        onMetaUpdate({metaTitle: 'Reset hasła'})
    };

    handleSubmit = event => {
        event.preventDefault();
        const currentState = {
            ...this.state
        };
        axios.post(endpoints.sendResetPasswordToken, {
            email: this.state.email
        })
            .then((response) => {
                currentState.email = "";
                currentState.isSent = true;
                this.setState(currentState)
            })
            .catch((error) => {
                console.log('sending reset password token failed: ' + error)
            });
    };

    render() {
        return this.state.isSent ?
            (
                <div className="content_container">
                    <h2>Email z linkiem resetującym hasło został wysłany</h2>
                </div>
            ) :
            (
                <div className="content_container">
                    <h2>Reset hasła</h2>

                    <div className="prl_box_top">
                        <div className="prl_box_bg"
                             style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                        </div>
                    </div>

                    <div className="prl_box_middle">
                        <div className="container">
                            <div className="row">
                                <div className="prl_box_mod">
                                    <div className="container">
                                        <div className="row">
                                            <div className={"col-lg-12"}>
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <label htmlFor={"email"}>Email</label>
                                                        <input
                                                            type="email"
                                                            data-id="email"
                                                            value={this.state.email}
                                                            onChange={this.handleInputChange}
                                                            className={"form-control text-center"}
                                                            id={"email"}
                                                        />
                                                    </div>
                                                    <button className={"btn_default"}>
                                                        Wyślij link do resetu hasła
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="prl_box_bottom">
                        <div className="prl_box_bg"
                             style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                        </div>
                    </div>
                </div>
            );
    }
}

export default SendResetPasswordTokenForm;