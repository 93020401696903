import React from "react";
import vegImg from "../../assets/img/veg.png";
import greenBackImg from "../../assets/img/green_back.png";
import {Link} from "react-router-dom";

const GreenCTA = props => {

        const {h2Text, linkUrl, actionText, onClick, modal, onClose, h2TextLine2, additionalClass} = props

        let className = 'try'
        if (additionalClass) {
            className += ' ' + additionalClass
        }
        if (modal) {
            className += ' green_cta_modal'
        }

        return (
            <div className={className} style={{
                background: 'url(' + vegImg + ') no-repeat center center'
            }}>
                <div className="container green_back"
                     style={{
                         background: 'url(' + greenBackImg + ') no-repeat center center',
                         position: 'relative'
                     }}>
                    <h2>{h2Text}{h2TextLine2 ? <><br/>{h2TextLine2}</> : ''}</h2>
                    {onClick ?
                        <button className={'btn_white'} onClick={onClick}>{actionText}</button> :
                        <Link to={linkUrl} className="btn_white">{actionText}</Link>
                    }
                    {onClose ? <button className={'close-paywall btn_white'}
                                       onClick={onClose}> &laquo; powrót </button> : ''}
                </div>
            </div>
        );
    }
;

export default GreenCTA;