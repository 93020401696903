import orangeImg from "../../assets/img/oranges.jpg";
import React from "react";

const PageNotFound = props => {
    return (
        <div className="content_container">
            <h2>404</h2>

            <div className="prl_box_top">
                <div className="prl_box_bg"
                     style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                </div>
            </div>

            <div className="prl_box_middle">
                <div className="container">
                    <div className="row">
                        <div className="prl_box_mod">
                            <p>
                                <h3>Nie znaleziono strony :(</h3>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="prl_box_bottom">
                <div className="prl_box_bg"
                     style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;