import React from 'react';
import {Link} from "react-router-dom";
import routes from "../../routes";

const FooterMenu = props => {
    return (
        <div className="footer_menu">
            <div className="container">
                <ul>
                    <li><Link to={routes.aboutUs}>O nas</Link></li>
                    <li><Link to={routes.blog}>Blog dietetyczny</Link></li>
                    <li><Link to={routes.products}>Składniki przepisów</Link></li>
                    <li><Link to={routes.howDoesItWork}>Jak to działa</Link></li>
                    <li><Link to={routes.contact}>Kontakt</Link></li>
                    <li><a href={"/regulamin.pdf"} target={'_blank'} rel={'noopener noreferrer'}>Regulamin</a></li>
                    <li><a href={"/polityka-prywatnosci.pdf"} target={'_blank'}
                           rel={'noopener noreferrer'}>Polityka<br/>
                        prywatności</a></li>
                </ul>
            </div>
        </div>
    );
};


export default FooterMenu;