import axios from "axios";
import endpoints from "../api/endpoints";
import AuthError from "../../error/authError";
import ErrorWithCode from "../../error/errorWithCode";
import ServiceLocator from "../ServiceLocator/ServiceLocator";
import PaywallError from "../../error/paywallError";

axios.interceptors.request.use(
    config => {
        const container = ServiceLocator.getInstance();
        const token = container.tokenStorage.getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(new AuthError(error));
    }
);

axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    const originalRequest = error.config;
    const container = ServiceLocator.getInstance();
    if (!originalRequest._retry &&
        error.response.status === 401 &&
        container.tokenStorage.getRefreshToken() &&
        originalRequest.url !== endpoints.refreshToken &&
        originalRequest.url !== endpoints.login &&
        originalRequest.url !== endpoints.googleAuthLogin &&
        originalRequest.url !== endpoints.googleAuthRegister &&
        originalRequest.url !== endpoints.facebookAuthLogin &&
        originalRequest.url !== endpoints.facebookAuthRegister
    ) {
        originalRequest._retry = true;
        const refreshToken = container.tokenStorage.getRefreshToken();
        return axios.post(endpoints.refreshToken,
            {
                "refresh_token": refreshToken
            })
            .then(res => {
                if (res.status === 200) {
                    container.tokenStorage.setToken(res.data.token);
                    container.tokenStorage.setRefreshToken(res.data.refresh_token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + container.tokenStorage.getToken();
                    return axios(originalRequest);
                } else if (res.status === 401 && refreshToken !== container.tokenStorage.getRefreshToken()) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + container.tokenStorage.getToken();
                    return axios(originalRequest);
                }
            })
    }
    let rejectReason
    switch (error.response.status) {
        case 400: {
            if (error.response.data && error.response.data.code) {
                rejectReason = new ErrorWithCode(error.response.data.code, error)
            } else {
                rejectReason = error
            }
            break;
        }
        case 401: {
            container.tokenStorage.clearTokens()
            rejectReason = new AuthError(error)
            break;
        }
        case 403: {
            rejectReason = new PaywallError(error)
            break;
        }
        default: {
            rejectReason = error
        }
    }
    return Promise.reject(rejectReason);
});

export default axios;