import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import orangeImg from "../../assets/img/oranges.jpg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import PaywallError from "../../error/paywallError";
import AuthError from "../../error/authError";
import {isAdmin} from "../../services/User/User";
import {adminDeleteProduct, getProducts} from "../../services/api/api";
import ProductListItem from "./ProductListItem";
import routes from "../../routes";

class ProductList extends AbstractComponent {

    filterFields = {
        query: {
            label: "Nazwa",
            type: "text"
        }
    };

    listLimit = 20;

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            page: 1,
            noMoreItems: false,
            items: [],
            errorMessage: null,
            showFilters: false,
            title: "Produkty",
            isAdmin: isAdmin(),
            query: ""
        };
    };

    componentDidMount = async () => {
        const {title} = this.state
        const {onMetaUpdate} = this.props;
        onMetaUpdate({metaTitle: title})
        await this.loadProducts(1)
    };

    toggleFilters = () => {
        const currentState = {...this.state}
        currentState.showFilters = !currentState.showFilters
        this.setState(currentState)
    }

    loadFirstPage = async () => {
        await this.loadProducts(1);
    }

    loadNextPage = async () => {
        const {page} = this.state;
        await this.loadProducts(page + 1);
    }

    loadProducts = async (page) => {
        const {onNotLoggedIn, onPaywall} = this.props
        const currentState = {
            ...this.state
        }

        if (page === 1) {
            currentState.items = [];
            currentState.noMoreItems = false;
        }

        currentState.isLoaded = false
        currentState.page = page
        currentState.showFilters = false
        this.setState(currentState);

        const filters = {};

        Object.keys(this.filterFields).forEach((field) => {
            filters[field] = currentState[field];
        });

        try {
            const items = await getProducts(currentState.page, this.listLimit, filters);
            if (items.length > 0) {
                currentState.items = currentState.items.concat(...items);
                currentState.isLoaded = true;
                if (items.length < this.listLimit) {
                    currentState.noMoreItems = true;
                }
            } else {
                currentState.items = []
            }
        } catch (error) {
            if (error instanceof PaywallError) {
                onPaywall()
            } else if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else {
                console.log(error)
                currentState.errorMessage = "podczas pobierania listy potraw wystąpił błąd";
            }

        }
        currentState.isLoaded = true;

        this.setState(currentState)
    }

    handleDelete = async (product) => {
        const {onNotLoggedIn} = this.props
        const currentState = {...this.state}
        try {
            await adminDeleteProduct(product.id)
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else {
                currentState.errorMessage = "operacja nie powiodła się";
            }
        }
        window.location.href = routes.adminProducts
    }

    getProductListJSX = () => {
        const {items, noMoreItems, isLoaded, isAdmin} = this.state;

        if (!isLoaded) {
            return <Loader/>
        }

        const products = items.length > 0 ?
            this.state.items.map((product, idx) =>
                <ProductListItem product={product} key={idx} isAdmin={isAdmin} onDelete={this.handleDelete}/>
            ) :
            "nie znaleziono produktów";

        const loadMore = noMoreItems ?
            "" :
            (
                <div>
                    <button onClick={this.loadNextPage} className={"btn_orange"}>Pokaż więcej</button>
                </div>
            );
        return (
            <div>
                {products}
                {loadMore}
                <hr/>
                <button className={"btn_green"} onClick={this.scrollToTop}>Do góry</button>
            </div>
        )
    };

    render = () => {
        const {errorMessage, showFilters} = this.state;

        if (errorMessage) {
            return <ErrorMessage message={errorMessage}/>
        }

        const formFieldsJsx = Object.keys(this.filterFields).map((key, index) => {
            return (
                <div key={index} className="form-group">
                    <label htmlFor={key} className={"text-center"}>{this.filterFields[key].label}</label>
                    <input type={this.filterFields[key].type}
                           className="form-control"
                           id={key}
                           data-id={key}
                           onChange={this.handleInputChange}
                           value={this.state[key]}
                    />
                </div>
            )
        });

        return (
            <div className="content_container">
                <h2>Produkty</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                {
                                    showFilters ?
                                        <>
                                            <button className={"btn_orange"} onClick={this.toggleFilters}>schowaj
                                                filtry
                                            </button>
                                            {formFieldsJsx}
                                            <button className={"btn_green"} onClick={this.loadFirstPage}>filtruj
                                            </button>
                                        </> :
                                        <>
                                            <button className={"btn_orange"} onClick={this.toggleFilters}>pokaż filtry
                                            </button>
                                        </>

                                }

                                <hr/>
                                <a href={routes.adminProductForm} className={"action-button action-button-green"}>
                                    dodaj produkt
                                </a>
                                <hr/>

                                {this.getProductListJSX()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductList;
