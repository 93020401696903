import React from "react";
import slide1Img from "../../assets/img/slide1.jpg";
import {Link} from "react-router-dom";
import routes from "../../routes";
import Carousel from "react-bootstrap/Carousel";

const HomeSlider = props => {
    return (
        <div className="home_slider">
            <Carousel controls={false}>
                <Carousel.Item style={{
                    background: 'url(' + slide1Img + ') no-repeat center center'
                }}>
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-5 slid_left h-100 d-inline-block">
                                <h2>W Nowym Roku zainwestuj w siebie! Zacznij zmieniać swoje życie już teraz!</h2>
                                <p><Link to={routes.generateDiet} className={"btn_white"}>Spróbuj za darmo</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item style={{
                    background: 'url(' + slide1Img + ') no-repeat center center'
                }}>
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-5 slid_left h-100 d-inline-block">
                                <h2>Skorzystaj z promocji na start! Wszystkie pakiety tylko teraz kosztują 50%
                                    mniej!</h2>
                                <p><Link to={routes.subscriptions} className={"btn_white"}>Kup pakiet w promocyjnej
                                    cenie!</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default HomeSlider;