import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";

const UserListItem = props => {
    const {user, onDelete} = props;

    return (
        <>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <u>{user.id} ({user.created_at})</u>: {getName(user)} {!user.enabled ?
                        <strong>(nieaktywny)</strong> : <></>}
                        <br/>
                        {user.utm_medium ? "utm_medium: " + user.utm_medium : ""} {user.utm_source ? "utm_source: " + user.utm_source : ""}
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            className={"actionBtn text-danger"}
                            onClick={() => {
                                if (window.confirm('Czy na pewno chcesz usunąć użytkownika?')) {
                                    onDelete(user.id);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <hr/>
        </>
    );
}

const getName = user => {
    if (user.email) {
        return user.email
    }

    if (user.facebook_id) {
        return "facebook: " + user.facebook_id
    }

    if (user.google_id) {
        return "google: " + user.google_id
    }

    return "???"
}


export default UserListItem;