import ServiceLocator from "../ServiceLocator/ServiceLocator";
import {getUserActiveSubscriptions} from "../api/api";

export async function hasActiveSubscription() {
    let hasSubscription = false;
    const container = ServiceLocator.getInstance();
    const token = container.tokenStorage.getToken();

    if (token) {
        const decodedToken = parseJwt(token)
        if (decodedToken.temp === true) {
            return false
        }

        if (decodedToken.subscription === true) {
            return true
        }

        try {
            const res = await getUserActiveSubscriptions(1)
            if (res.length > 0) {
                return true;
            }
        } catch (error) {
            console.error(error)
        }
    }

    return hasSubscription
}

export function isTempUser() {
    const container = ServiceLocator.getInstance();
    const token = container.tokenStorage.getToken();

    if (token) {
        const decodedToken = parseJwt(token)
        return decodedToken.temp
    }

    return false
}

export function isAdmin() {
    const container = ServiceLocator.getInstance();
    const token = container.tokenStorage.getToken();
    if (token) {
        const decodedToken = parseJwt(token)

        return decodedToken.is_admin
    }

    return false
}

export function getUserName() {
    const container = ServiceLocator.getInstance();
    const token = container.tokenStorage.getToken();

    if (token) {
        const decodedToken = parseJwt(token)
        if (decodedToken.email) {
            return decodedToken.email
        }
        if (decodedToken.temp) {
            return "konto tymczasowe"
        }

        return "profil użytkownika"
    }

    return null
}

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}