import React from 'react';
import "./assets/styles/index.scss"

import './App.css';

import LoginForm from './components/LoginForm/LoginForm';
import {BrowserRouter, Route} from 'react-router-dom';
import SendResetPasswordTokenForm from './components/ResetPassword/SendResetPasswordTokenForm';
import ResetPasswordForm from './components/ResetPassword/ResetPasswordForm';
import ChangePasswordForm from './components/ChangePasswordForm/ChangePasswordForm';
import AbstractComponent from './components/AbstractComponent/AbstractComponent';
import ActivateAccount from './components/ActivateAccount/ActivateAccount';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import TasteRecognition from './components/TasteRecognition/TasteRecognition';
import DishDetails from './components/Dish/DishDetails';
import DishList from './components/Dish/DishList';
import DishUpdateByUser from './components/Dish/DishUpdateByUser';
import TDEECalculator from './components/Calculator/TDEECalculator';
import FoodPreferenceList from './components/FoodPreference/FoodPreferenceList';
import DietGenerator from './components/Diet/DietGenerator';
import routes from './routes';
import DietList from "./components/Diet/DietList";
import OAuthLogin from "./components/OAuth/OAuthLogin";
import OAuthRegister from "./components/OAuth/OAuthRegister";
import Subscriptions from "./components/Subscriptions/Subscriptions";
import UserSubscriptions from "./components/Subscriptions/UserSubscriptions";
import ProgressLog from "./components/ProgressLog/ProgressLog";
import ShoppingLists from "./components/ShoppingList/ShoppingLists";
import ShoppingListItems from "./components/ShoppingList/ShoppingListItems";
import CalorieCalculator from "./components/CalorieCalculator/CalorieCalculator";
import brandImg from "./assets/img/brand.png"
import fbImg from "./assets/img/fb.png"
import MenuMobile from "./components/Menu/MenuMobile";
import TopMobile from "./components/Top/TopMobile";
import MainMenu from "./components/Menu/MainMenu";
import FooterMenu from "./components/Menu/FooterMenu";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import DietGeneratorForm from "./components/Diet/DietGeneratorForm";
import MealAlternatives from "./components/Diet/MealAlternatives";
import {Redirect, Switch} from "react-router";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import {login, tempRegister} from "./services/api/api";
import CookieConsent from "react-cookie-consent";
import MetaTags from 'react-meta-tags';

import HomePage from "./components/HomePage/HomePage";
import Paywall from "./components/Paywall/Paywall";
import PageContent from "./components/PageContent/PageContent";
import DishForm from "./components/Dish/DishForm";
import ProductList from "./components/Product/ProductList";
import ProductForm from "./components/Product/ProductForm";
import ErrorWithCode from "./error/errorWithCode";
import AboutUs from "./components/PageContent/AboutUs";
import HowDoesItWork from "./components/PageContent/HowDoesItWork";
import UserList from "./components/Users/UserList";
import ReactGA from 'react-ga';
import ProductDetails from "./components/Product/ProductDetails";
import BlogDietetyczny from "./components/PageContent/BlogDietetyczny";
import ZapotrzebowanieKaloryczne from "./components/PageContent/ZapotrzebowanieKaloryczne";
import ZdrowePrzepisy from "./components/PageContent/ZdrowePrzepisy";

class App extends AbstractComponent {
    metaTitleSuffix = "Dieterian.com - Dieta na Twoich zasadach"

    constructor(props) {
        super(props);

        const state = this.getInitialState()
        if (this.container.tokenStorage.isTokenValid()) {
            state.isUserLoggedIn = true;
        }

        this.state = state;
    }

    getInitialState = () => {
        return {
            fixedMenu: false,
            isUserLoggedIn: false,
            isGoogleConnected: false,
            isFacebookConnected: false,
            loginFailed: false,
            errorCode: "",
            showLoginForm: false,
            showMobileMenu: false,
            metaTitle: "",
            metaDescription: "",
            metaCanonical: "",
            showPaywall: false,
            isAdmin: false,
            noSuffix: false,
        };
    }

    handleMetaUpdate = (changeData) => {
        const currentState = {...this.state};
        currentState.metaTitle = changeData.metaTitle
        currentState.metaDescription = changeData.metaDescription
        currentState.metaCanonical = changeData.metaCanonical
        currentState.noSuffix = changeData.noSuffix === true

        this.setState(currentState)
    }

    componentDidMount = async () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const {fixedMenu} = this.state
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const menuShouldBeFixed = winScroll > 40

        if (menuShouldBeFixed && !fixedMenu) {
            this.changeState("fixedMenu", true)
        } else if (!menuShouldBeFixed && fixedMenu) {
            this.changeState("fixedMenu", false)
        }
    }

    handleLogout = (refresh) => {
        this.container.tokenStorage.clearTokens();
        const state = this.getInitialState();
        if (refresh) {
            window.location.reload()
        } else {
            state.redirectTo = routes.home
        }
        this.setState(state);
    }

    handleLogin = async (email, password) => {
        const currentState = {...this.state};
        try {
            const response = await login(email, password)
            this.container.tokenStorage.setRefreshToken(response.refresh_token);
            this.container.tokenStorage.setToken(response.token);
            currentState.isUserLoggedIn = true;
            currentState.loginFailed = false
            currentState.showLoginForm = false
            currentState.errorCode = ""
            window.location.reload();
        } catch (error) {
            if (error instanceof ErrorWithCode) {
                currentState.errorCode = error.code
            }
            currentState.loginFailed = true
        }
        this.setState(currentState);
    }

    handleSetTokens = async (tokens) => {
        const currentState = {...this.state};
        try {
            this.container.tokenStorage.setRefreshToken(tokens.refresh_token);
            this.container.tokenStorage.setToken(tokens.token);
            currentState.isUserLoggedIn = true;
            currentState.loginFailed = false
            currentState.showLoginForm = false
            window.location.href = routes.generateDiet
        } catch (error) {
            console.log(error)
            currentState.loginFailed = true
        }
        this.setState(currentState);
    }

    handleAuthSuccess = (location) => {
        window.location.href = location
    }

    handleGoogleConnectSuccess = () => {
        window.location.href = "/";
    }

    handleFacebookConnectSuccess = () => {
        window.location.href = "/";
    }

    handleGoogleDisconnect = () => {
        this.changeState("isGoogleConnected", false)
    }

    handleFacebookDisconnect = () => {
        this.changeState("isFacebookConnected", false)
    }

    handleForgotPassword = () => {
        const currentState = {...this.state}
        currentState.showLoginForm = false;
        currentState.redirectTo = routes.resetPassword;

        this.setState(currentState)
    }

    showLoginForm = () => {
        const currentState = {...this.state}
        currentState.isUserLoggedIn = false;
        currentState.showMobileMenu = false;
        currentState.showLoginForm = true;
        this.setState(currentState);
    }

    hideLoginForm = () => {
        this.changeState("showLoginForm", false)
    }

    toggleMobileMenu = () => {
        const {showMobileMenu} = this.state;
        this.changeState("showMobileMenu", !showMobileMenu)
    }

    handleNotLoggedIn = () => {
        this.showLoginForm()
    }

    handleBuy = () => {
        const currentState = {...this.state}
        currentState.showPaywall = false
        currentState.redirectTo = routes.subscriptions
        this.setState(currentState);
    }

    handlePaywall = () => {
        const currentState = {...this.state}
        currentState.showPaywall = true
        this.setState(currentState);
    }

    closePaywall = () => {
        const currentState = {...this.state}
        currentState.showPaywall = false
        this.setState(currentState);
    }

    handleTempRegister = async (e) => {
        const currentState = {...this.state};
        e.preventDefault()
        try {
            const response = await tempRegister()
            this.container.tokenStorage.setToken(response.token);
            currentState.isUserLoggedIn = true;
            currentState.loginFailed = false
            currentState.showLoginForm = false

            window.location.reload();
        } catch (error) {
            currentState.loginFailed = true
        }
        this.setState(currentState);
    }

    render = () => {
        const {
            isUserLoggedIn,
            loginFailed,
            errorCode,
            fixedMenu,
            showLoginForm,
            showMobileMenu,
            showPaywall,
            metaTitle,
            metaDescription,
            metaCanonical,
            noSuffix
        } = this.state;

        const additionMenuClass = fixedMenu ? ' fixed' : '';
        const showMobileMenuClass = showMobileMenu ? ' show-menu' : '';
        const title = (noSuffix === true) ? metaTitle : metaTitle + " - " + this.metaTitleSuffix

        return <BrowserRouter forceRefresh>
            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={metaDescription}/>
                <meta property="og:title" content={title}/>
                <meta property="og:image" content="%PUBLIC_URL%/logo-dieterian.png"/>
                {metaCanonical ? <link rel="canonical" href={metaCanonical}/> : ""}
            </MetaTags>
            {this.renderRedirect()}
            <div className={showMobileMenuClass}>
                <ScrollToTop/>
                <MenuMobile
                    isLoggedIn={isUserLoggedIn}
                    onLogout={this.handleLogout}
                    onLogin={this.showLoginForm}
                    onHideMenu={this.toggleMobileMenu}
                />
                <TopMobile
                    onMenuToggle={this.toggleMobileMenu}
                />
                {showLoginForm ?
                    <LoginForm
                        loginFailed={loginFailed}
                        errorCode={errorCode}
                        onClose={this.hideLoginForm}
                        onLogin={this.handleLogin}
                        onForgotPassword={this.handleForgotPassword}
                        onTempRegister={this.handleTempRegister}
                    /> : ''}
                {showPaywall ?
                    <Paywall
                        onClose={this.closePaywall}
                        onBuy={this.handleBuy}
                    /> : ''}
                <div className="content-wrap">
                    <div className={"container-fluid menu_top" + additionMenuClass}>
                        <div className="container">
                            <MainMenu
                                isLoggedIn={isUserLoggedIn}
                                onLogout={this.handleLogout}
                                onLogin={this.showLoginForm}
                            />
                        </div>
                    </div>
                    <Switch>
                        <Route path={routes.home} exact render={(props) => (
                            <HomePage
                                onMetaUpdate={this.handleMetaUpdate}
                                isLoggedIn={isUserLoggedIn}
                                {...props} />
                        )}/>

                        <Route path={routes.resetPassword} exact render={(props) => (
                            <SendResetPasswordTokenForm
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props} />)
                        }/>
                        <Route path={routes.resetPassword + '/:token'} render={(props) => (
                            <ResetPasswordForm
                                onMetaUpdate={this.handleMetaUpdate}
                                isLoggedIn={isUserLoggedIn}
                                {...props} />)
                        }/>
                        <Route path={routes.activate + '/:token'} render={(props) => (
                            <ActivateAccount
                                onMetaUpdate={this.handleMetaUpdate}
                                onSetTokens={this.handleSetTokens}
                                {...props} />)
                        }/>
                        <Route path={routes.changePassword} render={(props) => (
                            <ChangePasswordForm isLoggedIn={isUserLoggedIn}
                                                onMetaUpdate={this.handleMetaUpdate}
                                                {...props} />)
                        }/>
                        <Route path={routes.register} exact render={(props) => (
                            <RegistrationForm
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props} />)
                        }/>
                        <Route path={routes.tasteRecognition} exact render={(props) => (
                            <TasteRecognition isLoggedIn={isUserLoggedIn}
                                              onNotLoggedIn={this.handleNotLoggedIn}
                                              onMetaUpdate={this.handleMetaUpdate}
                                              {...props} />)
                        }/>
                        <Route path={routes.dish + '/:slug'} render={(props) => (
                            <Redirect to={routes.recipe + '/' + props.match.params.slug}/>)
                        }/>
                        <Route path={routes.recipe + '/:slug'} render={(props) => (
                            <DishDetails onMetaUpdate={this.handleMetaUpdate}
                                         onNotLoggedIn={this.handleNotLoggedIn}
                                         onPaywall={this.handlePaywall}
                                         {...props} />)
                        }/>
                        <Route path={routes.updateDish + '/:slug'} render={(props) => (
                            <DishUpdateByUser onMetaUpdate={this.handleMetaUpdate}
                                              slug={props.match.params.slug}
                                              onNotLoggedIn={this.handleNotLoggedIn}
                                              onPaywall={this.handlePaywall}
                                              {...props} />)
                        }/>
                        <Route path={routes.recipes} exact render={(props) => (
                            <DishList onMetaUpdate={this.handleMetaUpdate}
                                      onNotLoggedIn={this.handleNotLoggedIn}
                                      onPaywall={this.handlePaywall}
                                      {...props} />)
                        }/>
                        <Route path={routes.dishes} exact render={(props) => (
                            <Redirect to={routes.recipes}/>)
                        }/>
                        <Route path={routes.recipes + '/:mode'} exact render={(props) => (
                            <DishList onMetaUpdate={this.handleMetaUpdate}
                                      onNotLoggedIn={this.handleNotLoggedIn}
                                      onPaywall={this.handlePaywall}
                                      mode={props.match.params.mode}
                                      {...props} />)
                        }/>
                        <Route path={routes.dishes + '/:mode'} exact render={(props) => (
                            <Redirect to={routes.recipes + '/' + props.match.params.mode}/>)
                        }/>
                        <Route path={routes.adminProducts} render={(props) => (
                            <ProductList onMetaUpdate={this.handleMetaUpdate}
                                         onNotLoggedIn={this.handleNotLoggedIn}
                                         onPaywall={this.handlePaywall}
                                         {...props} />)
                        }/>
                        <Route path={routes.products} render={(props) => (
                            <ProductList onMetaUpdate={this.handleMetaUpdate}
                                         onNotLoggedIn={this.handleNotLoggedIn}
                                         onPaywall={this.handlePaywall}
                                         {...props} />)
                        }/>
                        <Route path={routes.product + '/:slug'} render={(props) => (
                            <ProductDetails onMetaUpdate={this.handleMetaUpdate}
                                            onNotLoggedIn={this.handleNotLoggedIn}
                                            onPaywall={this.handlePaywall}
                                            {...props} />)
                        }/>
                        <Route path={routes.tdeeCalculator} render={(props) => (
                            <TDEECalculator onMetaUpdate={this.handleMetaUpdate}
                                            {...props} />)
                        }/>
                        <Route path={routes.foodPreferences + '/:mode'} render={(props) => (
                            <FoodPreferenceList liked={props.match.params.mode === 'ulubione'}
                                                isLoggedIn={isUserLoggedIn}
                                                onNotLoggedIn={this.handleNotLoggedIn}
                                                onMetaUpdate={this.handleMetaUpdate}
                                                {...props} />)
                        }/>

                        <Route path={routes.mealAlternatives + '/:dietId/:dishSetId/:mealId'} render={(props) => (
                            <MealAlternatives dietId={props.match.params.dietId}
                                              dishSetId={props.match.params.dishSetId}
                                              mealId={props.match.params.mealId}
                                              isLoggedIn={isUserLoggedIn}
                                              onNotLoggedIn={this.handleNotLoggedIn}
                                              onPaywall={this.handlePaywall}
                                              onMetaUpdate={this.handleMetaUpdate}
                                              {...props} />)
                        }/>
                        <Route path={routes.generateDiet} exact render={(props) => (
                            <DietGeneratorForm isLoggedIn={isUserLoggedIn}
                                               onNotLoggedIn={this.handleNotLoggedIn}
                                               onPaywall={this.handlePaywall}
                                               onMetaUpdate={this.handleMetaUpdate}
                                               {...props} />)
                        }/>
                        <Route path={routes.generateDiet + '/:dietId'} exact render={(props) => (
                            <DietGenerator dietId={props.match.params.dietId}
                                           isLoggedIn={isUserLoggedIn}
                                           onNotLoggedIn={this.handleNotLoggedIn}
                                           onPaywall={this.handlePaywall}
                                           onMetaUpdate={this.handleMetaUpdate}
                                           {...props} />)
                        }/>
                        <Route path={routes.generateDiet + '/:dietId/:dishSetId'} render={(props) => (
                            <DietGenerator dietId={props.match.params.dietId}
                                           dishSetId={props.match.params.dishSetId}
                                           isLoggedIn={isUserLoggedIn}
                                           onNotLoggedIn={this.handleNotLoggedIn}
                                           onPaywall={this.handlePaywall}
                                           onMetaUpdate={this.handleMetaUpdate}
                                           {...props} />)
                        }/>
                        <Route path={routes.showDiet + '/:dietId'} exact render={(props) => (
                            <DietGenerator
                                dietId={props.match.params.dietId}
                                readOnly={true}
                                isLoggedIn={isUserLoggedIn}
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onPaywall={this.handlePaywall}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props} />)
                        }/>
                        <Route path={routes.showDiet + '/:dietId/:dishSetId'} render={(props) => (
                            <DietGenerator
                                readOnly={true}
                                dietId={props.match.params.dietId}
                                dishSetId={props.match.params.dishSetId}
                                isLoggedIn={isUserLoggedIn}
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onPaywall={this.handlePaywall}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props} />)
                        }/>
                        <Route path={routes.diets} exact render={(props) => (
                            <DietList
                                isLoggedIn={isUserLoggedIn}
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onPaywall={this.handlePaywall}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props} />)
                        }/>
                        <Route path={routes.googleLogin} render={(props) => (
                            <OAuthLogin
                                platform={'google'}
                                onLogin={this.handleAuthSuccess}
                                onConnect={this.handleGoogleConnectSuccess}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props}
                            />
                        )}/>
                        <Route path={routes.facebookLogin} render={(props) => (
                            <OAuthLogin
                                platform={'facebook'}
                                onLogin={this.handleAuthSuccess}
                                onConnect={this.handleFacebookConnectSuccess}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props}
                            />
                        )}/>
                        <Route path={routes.facebookRegister} render={(props) => (
                            <OAuthRegister
                                platform={'facebook'}
                                onSuccess={this.handleAuthSuccess}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props}
                            />
                        )}/>
                        <Route path={routes.googleRegister} render={(props) => (
                            <OAuthRegister
                                platform={'google'}
                                onSuccess={this.handleAuthSuccess}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props}
                            />
                        )}/>
                        <Route path={routes.subscriptions} exact render={(props) => (
                            <Subscriptions isLoggedIn={isUserLoggedIn}
                                           onNotLoggedIn={this.handleNotLoggedIn}
                                           onLogin={this.showLoginForm}
                                           onLogout={this.handleLogout}
                                           onMetaUpdate={this.handleMetaUpdate}
                                           {...props}
                            />
                        )}/>
                        <Route path={routes.userSubscriptions} exact render={(props) => (
                            <UserSubscriptions isLoggedIn={isUserLoggedIn}
                                               onNotLoggedIn={this.handleNotLoggedIn}
                                               onMetaUpdate={this.handleMetaUpdate}
                                               {...props}
                            />
                        )}/>
                        <Route path={routes.progressLog} exact render={(props) => (
                            <ProgressLog isLoggedIn={isUserLoggedIn}
                                         onNotLoggedIn={this.handleNotLoggedIn}
                                         onMetaUpdate={this.handleMetaUpdate}
                                         {...props}
                            />
                        )}/>
                        <Route path={routes.shoppingLists} exact render={(props) => (
                            <ShoppingLists isLoggedIn={isUserLoggedIn}
                                           onNotLoggedIn={this.handleNotLoggedIn}
                                           onPaywall={this.handlePaywall}
                                           onMetaUpdate={this.handleMetaUpdate}
                                           {...props}
                            />
                        )}/>
                        <Route path={routes.shoppingLists + '/:listId'} render={(props) => (
                            <ShoppingListItems listId={props.match.params.listId}
                                               isLoggedIn={isUserLoggedIn}
                                               onNotLoggedIn={this.handleNotLoggedIn}
                                               onPaywall={this.handlePaywall}
                                               onMetaUpdate={this.handleMetaUpdate}
                                               {...props}
                            />)
                        }/>
                        <Route path={routes.calorieCalculator} exact render={(props) => (
                            <CalorieCalculator isLoggedIn={isUserLoggedIn}
                                               onNotLoggedIn={this.handleNotLoggedIn}
                                               onPaywall={this.handlePaywall}
                                               onMetaUpdate={this.handleMetaUpdate}
                                               {...props}
                            />
                        )}/>
                        <Route path={routes.aboutUs} exact render={(props) => (
                            <AboutUs title={'O nas'}
                                     onMetaUpdate={this.handleMetaUpdate}
                                     {...props}
                            />
                        )}/>
                        <Route path={routes.howDoesItWork} exact render={(props) => (
                            <HowDoesItWork title={'Jak to działa'}
                                           onMetaUpdate={this.handleMetaUpdate}
                                           {...props}
                            />
                        )}/>
                        <Route path={routes.blog} exact render={(props) => (
                            <BlogDietetyczny onMetaUpdate={this.handleMetaUpdate}
                                             {...props}
                            />
                        )}/>
                        <Route path={routes.blogZapotrzebowanieKaloryczne} exact render={(props) => (
                            <ZapotrzebowanieKaloryczne onMetaUpdate={this.handleMetaUpdate}
                                                       {...props}
                            />
                        )}/>
                        <Route path={routes.blogZdrowePrzepisy} exact render={(props) => (
                            <ZdrowePrzepisy onMetaUpdate={this.handleMetaUpdate}
                                            {...props}
                            />
                        )}/>
                        <Route path={routes.contact} exact render={(props) => (
                            <PageContent title={'Kontakt'}
                                         route={routes.contact}
                                         onMetaUpdate={this.handleMetaUpdate}
                                         {...props}
                            >
                                <p className={'text-center'}>Masz jakieś pytania, propozycje? Zapraszamy do
                                    kontaktu!</p>
                                <p className={'text-center'}>kontakt@dieterian.com</p>
                            </PageContent>
                        )}/>
                        <Route path={routes.adminDishForm + '/:id'} exact render={(props) => (
                            <DishForm
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onMetaUpdate={this.handleMetaUpdate}
                                id={props.match.params.id}
                                {...props}
                            />
                        )}/>
                        <Route path={routes.adminDishForm} exact render={(props) => (
                            <DishForm
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props}
                            />
                        )}/>

                        <Route path={routes.adminProductForm + '/:id'} exact render={(props) => (
                            <ProductForm
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onMetaUpdate={this.handleMetaUpdate}
                                id={props.match.params.id}
                                {...props}
                            />
                        )}/>
                        <Route path={routes.adminProductForm} exact render={(props) => (
                            <ProductForm
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props}
                            />
                        )}/>
                        <Route path={routes.adminDishForm} exact render={(props) => (
                            <DishForm
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props}
                            />
                        )}/>
                        <Route path={routes.adminUsers} exact render={(props) => (
                            <UserList
                                onNotLoggedIn={this.handleNotLoggedIn}
                                onMetaUpdate={this.handleMetaUpdate}
                                {...props}
                            />
                        )}/>
                        <Route path="*">
                            <PageNotFound/>
                        </Route>
                    </Switch>
                </div>
                <div className="footer no-print">
                    <div className="brand">
                        <img src={brandImg} className="img-fluid" alt=""/>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-mdr-4 footer_widget">
                            </div>
                            <div className="col-md-4 footer_widget">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/dieteriancom" target={"_blank"}
                                           rel="noopener noreferrer">
                                            <img src={fbImg} className="img-fluid" alt=""/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <FooterMenu/>

                    <div className="text-center copy">
                        © 2023 Dieterian.com
                    </div>

                    <CookieConsent
                        location="bottom"
                        buttonText="OK"
                        cookieName="dieterian_cookie_consent"
                        style={{background: "#223502"}}
                        buttonStyle={{background: "#223502", color: "#fff", fontSize: "13px", border: "1px solid #fff"}}
                        expires={150}
                    >
                        Ta strona używa cookie w celach analitycznych. <a className={"cookieConsent"}
                                                                          href={"/polityka-prywatnosci.pdf"}>Polityka
                        prywatności.</a>
                    </CookieConsent>

                </div>
            </div>
        </BrowserRouter>
    }
}

export default App;
