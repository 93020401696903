import React from "react";
import {Link} from "react-router-dom";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import OAuthButton from "../OAuth/OAuthButton";
import {Modal} from "react-bootstrap";
import routes from "../../routes";

class LoginForm extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            email: "",
            password: ""
        };
    };

    handleInputChange = event => {
        this.changeState(event.target.dataset.id, event.target.value);
    };

    handleLogin = event => {
        event.preventDefault();
        this.props.onLogin(this.state.email, this.state.password);
        this.changeState('password', "")
    };

    render() {
        const {loginFailed, errorCode, onClose, onForgotPassword, onTempRegister} = this.props
        const errorMessage = loginFailed ?
            <div className="alert alert-danger" role="alert">
                {errorCode === 'not_active' ? 'Konto nieaktywne. Na Twoje konto przesłaliśmy podczas rejestracji link aktywacyjny - sprawdź w folderze Spam. Jeśli nie otrzymałe(a)ś emaila aktywacyjnego, skontaktuj się z nami: kontakt@dieterian.com chętnie pomożemy :)' : 'Błędne dane logowania.'}
            </div> :
            '';
        const redirectUrl = encodeURI(window.location.pathname)
        const onRegister = (e) => {
            e.preventDefault();
            window.location.href = routes.register
        }

        return (
            <>
                <Modal
                    show={true}
                    onHide={onClose}
                    size="lg"
                    aria-labelledby="modal-title"
                    id={"login_modal"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-title">
                            Logowanie
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <OAuthButton platform={'facebook'} mode={'login'} redirectUrl={redirectUrl}
                                                 buttonText={"zaloguj z facebook"}/>
                                </div>
                                <div className={"col-md-6"}>
                                    <OAuthButton platform={'google'} mode={'login'} redirectUrl={redirectUrl}
                                                 buttonText={"zaloguj z google"}/>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <hr/>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <form onSubmit={this.handleLogin}>
                                        <div className="form-group">
                                            <p className={'text-center'}>
                                                <input
                                                    type="email"
                                                    data-id="email"
                                                    placeholder={"email"}
                                                    value={this.state.email}
                                                    onChange={this.handleInputChange}
                                                    className={"form-control text-center"}
                                                    id={"email"}
                                                />
                                            </p>
                                            <p className={'text-center'}>
                                                <input
                                                    type="password"
                                                    data-id="password"
                                                    placeholder={"hasło"}
                                                    value={this.state.password}
                                                    onChange={this.handleInputChange}
                                                    className={"form-control text-center"}
                                                    id={"password"}
                                                />
                                                <small className="form-text text-muted text-right">
                                                    <Link to={"#"}
                                                          onClick={onForgotPassword}
                                                    >
                                                        Zapomniałeś hasła?
                                                    </Link>
                                                </small>
                                            </p>
                                        </div>
                                        {errorMessage}
                                        <button className={"btn_default"}>
                                            Zaloguj
                                        </button>
                                        <hr/>
                                        <Link to={"#"}
                                              onClick={onRegister}
                                              className={"btn_try"}
                                        >
                                            Zarejestruj się za darmo
                                        </Link>
                                        <Link to={"#"}
                                              onClick={onTempRegister}
                                              className={"action-button action-button-red temp-reg"}
                                        >
                                            Wypróbuj za darmo bez rejestracji przez godzinę
                                        </Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default LoginForm;