import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {createShoppingListItem, deleteShoppingListItem, getShoppingList} from "../../services/api/api";
import ShoppingListItem from "./ShoppingListItem";
import orangeImg from "../../assets/img/oranges.jpg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListAlt} from "@fortawesome/free-regular-svg-icons/faListAlt";
import {faPlusSquare} from "@fortawesome/free-regular-svg-icons";
import ReactToPrint from "react-to-print";

class ShoppingListItems extends AbstractComponent {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            showForm: false,
            isLoaded: false,
            items: [],
            errorMessage: null,
            formErrorMessage: null,
            lastResult: null,
            listName: "",
            listDate: "",
            name: "",
            amount: "",
            optional: false,
            done: false,
        };
    };

    componentDidMount = async () => {
        const {onNotLoggedIn, isLoggedIn, onMetaUpdate} = this.props
        if (!isLoggedIn) {
            onNotLoggedIn();
        } else {
            await this.loadItems()
            const {listName} = this.state
            onMetaUpdate({metaTitle: 'Lista zakupów: ' + listName})
        }
    };

    loadItems = async () => {
        this.changeState("isLoaded", false);
        const currentState = {
            ...this.state
        };

        try {
            const {listId} = this.props;
            const resp = await getShoppingList(listId);
            currentState.items = resp.items;
            currentState.listName = resp.name;
            currentState.listDate = resp.created_at;
        } catch (error) {
            currentState.errorMessage = "pobieranie danych nie powiodło się";
        }
        currentState.isLoaded = true;
        this.setState(currentState)
    };

    handleDelete = async (objectIdToRemove) => {
        const currentState = {...this.state};
        try {
            this.changeState("isLoaded", false);
            const {listId} = this.props;
            await deleteShoppingListItem(listId, objectIdToRemove);
            const items = this.state.items.filter((item) => {
                return item.id !== objectIdToRemove;
            });
            currentState.isLoaded = true;
            currentState.items = items;
            this.setState(currentState);
        } catch (error) {
            currentState.errorMessage = "operacja nie powiodła sie";
            currentState.isLoaded = true;
            this.setState(currentState);
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const {name, amount, optional} = this.state;
        const {listId} = this.props;

        if (!name) {
            this.changeState("formErrorMessage", "nazwa jest wymagana");

            return;
        }

        try {
            this.changeState("isLoaded", false);

            await createShoppingListItem(
                listId,
                name,
                amount,
                optional
            );

            this.changeState("items", []);
            await this.loadItems();
            const currentState = {...this.state};
            currentState.isLoaded = true;
            currentState.showForm = false;
            this.setState(currentState);
        } catch (error) {
            const currentState = {...this.state};
            currentState.formErrorMessage = "operacja nie powiodła sie";
            currentState.isLoaded = true;
            this.setState(currentState);
        }
    };

    renderForm = () => {
        const {formErrorMessage, name, amount} = this.state
        return (
            <div>
                <button
                    onClick={this.toggleForm}
                    className={"action-button action-button-black"}
                >
                    zamknij
                </button>
                <hr/>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor={"name"}>
                            Nazwa *
                        </label>
                        <input
                            type="text"
                            autoFocus
                            data-id="name"
                            id={"name"}
                            value={name}
                            onChange={this.handleInputChange}
                            className={"form-control"}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor={"amount"}>
                            Ilość
                        </label>
                        <input
                            type="number"
                            data-id="amount"
                            id={"amount"}
                            value={amount}
                            onChange={this.handleInputChange}
                            className={"form-control"}
                        />
                    </div>
                    {
                        formErrorMessage ?
                            <div className="alert alert-danger" role="alert">{formErrorMessage}</div> :
                            ''
                    }
                    <button className={"btn_green"}>zapisz</button>
                    <hr/>
                </form>
            </div>
        )
    };

    resetFormData = () => {
        const currentState = {...this.state}

        currentState.name = "";
        currentState.amount = "";
        currentState.optional = false;
        currentState.showForm = true;

        this.setState(currentState);
    }

    toggleForm = () => {
        const {showForm} = this.state;
        if (!showForm) {
            this.resetFormData();
        } else {
            this.changeState("showForm", false);
        }
    }

    render = () => {
        const {listId} = this.props;
        const {errorMessage, items, listName, showForm, listDate, isLoaded} = this.state;

        if (!isLoaded) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }

        return <div className="content_container">
            <h2>Lista zakupów</h2>
            <h3>{listName} ({new Date(listDate).toISOString().split('T')[0]})</h3>

            <div className="prl_box_top">
                <div className="prl_box_bg"
                     style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                </div>
            </div>

            <div className="prl_box_middle">
                <div className="container">
                    <div className="row">
                        <div className={"prl_box_mod"}>
                            {
                                showForm ?
                                    this.renderForm() :
                                    (
                                        <>
                                            <ReactToPrint
                                                trigger={() => {
                                                    return <button
                                                        className={"btn_orange"}
                                                    >
                                                        <i className="fa-solid fa-print"></i>
                                                        <FontAwesomeIcon
                                                            icon={faListAlt}
                                                            className={"text-danger"}
                                                        />&nbsp;
                                                        Drukuj
                                                    </button>
                                                }
                                                }
                                                content={() => this.componentRef}
                                                documentTitle={listName}
                                                pageStyle={'print'}
                                            />

                                            <hr/>
                                            <button
                                                onClick={this.toggleForm}
                                                className={"btn_green"}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlusSquare}
                                                    className={"text-success"}
                                                />&nbsp;
                                                Dodaj pozycję do listy zakupów
                                            </button>
                                        </>
                                    )
                            }
                            <hr/>
                            <ul className="list-group list-group-flush" ref={el => (this.componentRef = el)}>
                                {items.map((item) => {
                                    return <ShoppingListItem
                                        key={item.id}
                                        listId={listId}
                                        data={item}
                                        onDelete={this.handleDelete}
                                    />
                                })}
                            </ul>
                        </div>
                        {errorMessage}
                    </div>
                </div>
            </div>

            <div className="prl_box_bottom">
                <div className="prl_box_bg"
                     style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                </div>
            </div>
        </div>
    }
}

export default ShoppingListItems;
