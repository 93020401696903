import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {
    facebookDisconnect,
    getFacebookAuthRedirectUrl,
    getGoogleAuthRedirectUrl,
    googleDisconnect
} from "../../services/api/api";
import fbImg from "../../assets/img/facebook.svg";
import googleImg from "../../assets/img/google.svg";

class OAuthButton extends AbstractComponent {

    handleLogin = async event => {
        event.preventDefault();
        const {redirectUrl, mode, platform} = this.props;
        const state = encodeURI(JSON.stringify({redirectUrl: redirectUrl, mode: mode}))
        try {
            const redirectTo = (platform === "facebook") ?
                await getFacebookAuthRedirectUrl(state) :
                await getGoogleAuthRedirectUrl(state)
            ;
            window.location.href = redirectTo.url;
        } catch (error) {
            console.log(platform + ' login failed', error);
        }
    };

    handleDisconnect = async event => {
        const {platform} = this.props;
        event.preventDefault();
        try {
            (platform === "facebook") ? await facebookDisconnect() : await googleDisconnect();
            this.props.onDisconnect()
        } catch (error) {
            console.log(platform + ' login failed', error);
        }
    };

    render() {
        const {buttonText, mode, platform, additionalClass} = this.props;

        const callback = (mode === 'disconnect') ? this.handleDisconnect : this.handleLogin
        const icon = (platform === 'facebook') ? fbImg : googleImg;
        const cssClass = (platform === 'facebook') ? 'btn_blue' : 'btn_green';

        return <button className={'oauth-button ' + additionalClass + ' ' + cssClass} onClick={callback}>
            <img src={icon} alt={platform}/>{buttonText}
        </button>
    }
}

export default OAuthButton;