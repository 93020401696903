import ls from "local-storage";

class TokenStorage {

    tokenLocalStorageKey = "token";
    refreshTokenLocalStorageKey = "refreshToken";

    constructor() {
        this.tokens = {
            token: ls.get(this.tokenLocalStorageKey),
            refreshToken: ls.get(this.refreshTokenLocalStorageKey)
        };
    }

    setToken = (token) => {
        this.tokens.token = token;
        ls.set(this.tokenLocalStorageKey, token);
    }

    setRefreshToken = (refreshToken) => {
        this.tokens.refreshToken = refreshToken;
        ls.set(this.refreshTokenLocalStorageKey, refreshToken);
    }

    getToken = () => {
        return this.tokens.token
    }

    getRefreshToken = () => {
        return this.tokens.refreshToken
    }

    clearTokens() {
        this.setToken(null);
        this.setRefreshToken(null);
    }

    isTokenValid() {
        if (!this.tokens.token) {
            return false
        }

        const decodedToken = this.decodeToken(this.tokens.token)
        const tokenIsValid = decodedToken.exp > Math.floor(Date.now() / 1000)
        if (tokenIsValid) {
            return true
        }

        if (!this.tokens.refreshToken) {
            return false
        }
        const decodedRefreshToken = this.decodeToken(this.tokens.refreshToken)

        return decodedRefreshToken.exp > Math.floor(Date.now() / 1000)
    }

    decodeToken(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}

export default TokenStorage;