import React from "react";

import card1Img from "../../assets/img/card1.png";
import card2Img from "../../assets/img/card2.png";
import {Link} from "react-router-dom";
import routes from "../../routes";

const DishBoxesDesktop = props => {
    return (
        <div className="cards">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-md-5 card">
                        <div className="imag"
                             style={{
                                 background: 'url(' + card1Img + ') no-repeat center center'
                             }}></div>
                        <div className="row card_icons">
                            <div className="col-md-12 card_desc">
                                <p>
                                    Nie potrzebujesz aktualnie generować pełnej diety, ale chciałbyś znaleźć przepisy w
                                    określonym przedziale kalorii oraz spełniające określone warunki wykluczeń diety?
                                </p>
                                <div className="row button_cards">
                                    <div className="col-12">
                                        <Link to={routes.recipes} className="btn_green">Zobacz listę przepisów</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 card">
                        <div className="imag"
                             style={{
                                 background: 'url(' + card2Img + ') no-repeat center center'
                             }}></div>
                        <div className="row card_icons">
                            <div className="col-md-12 card_desc">
                                <p>
                                    Twojej ulubionej potrawy nie ma na naszej liście? Nie ma problemu! Użyj kalkulatora
                                    kalorii aby ją skomponować i określić posiłki dla jakich ma być dostępna w
                                    generatorze diet!
                                </p>
                                <div className="row button_cards">
                                    <div className="col-12">
                                        <Link to={routes.calorieCalculator} className="btn_green">Kalkulator
                                            kalorii</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DishBoxesDesktop;