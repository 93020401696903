import React from "react";
import FoodPreference from "../FoodPreference/FoodPreference";
import {Link} from "react-router-dom";
import routes from "../../routes";
import DishComponents from "./DishComponents";
import DietLabels from "../DietLabels/DietLabels";
import DishNutrition from "./DishNutrition";

const DishMenuItem = props => {
    const {dish, dietId, dishSetId, mealNumber, readOnly, onNotLoggedIn} = props;

    const getMealName = (mealNumber) => {
        let mealName = ""
        switch (mealNumber) {
            case 1: {
                mealName = "Śniadanie"
                break;
            }
            case 2: {
                mealName = "Drugie śniadanie"
                break;
            }
            case 3: {
                mealName = "Obiad"
                break;
            }
            case 4: {
                mealName = "Podwieczorek"
                break;
            }
            default:
            case 5: {
                mealName = "Kolacja"
                break;
            }
        }

        return mealName
    }

    const ref = React.createRef();

    return (
        <div className="container diet_menu_item" ref={ref}>
            <div className="row">
                <div className="col">
                    <h3>{getMealName(mealNumber)}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-12">
                    <span className={"dish_name"}>{dish.name}</span><br/>
                    <FoodPreference objectId={dish.id} objectType={"dish"} onNotLoggedIn={onNotLoggedIn}/>
                    <DishNutrition dish={dish}/>
                    <DietLabels item={dish} min={true}/>
                    {
                        readOnly ?
                            '' :
                            <Link className={"btn_orange"}
                                  to={routes.mealAlternativesFullPath(dietId, dishSetId, mealNumber)}>
                                wybierz coś innego na {getMealName(mealNumber).toLowerCase()}
                            </Link>
                    }
                </div>
                <div className="col-lg-4  col-md-12">
                    <strong>Składniki: </strong><br/>
                    <DishComponents dish={dish} onNotLoggedIn={onNotLoggedIn}/>
                </div>
                <div className="col-lg-4  col-md-12">
                    <strong>Przepis</strong><br/>
                    <p dangerouslySetInnerHTML={{__html: dish.recipe}}>
                    </p>
                </div>
            </div>
            <hr/>
        </div>
    );
};


export default DishMenuItem;