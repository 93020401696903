import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {getUserActiveSubscriptions} from "../../services/api/api";
import {Link} from "react-router-dom";
import routes from "../../routes";
import orangeImg from "../../assets/img/oranges.jpg";
import Loader from "../Loader/Loader";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {Badge} from "react-bootstrap";

class UserSubscriptions extends AbstractComponent {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            items: [],
            errorMessage: null,
        };
    }

    componentDidMount = async () => {
        const {onNotLoggedIn, isLoggedIn, onMetaUpdate} = this.props
        if (!isLoggedIn) {
            onNotLoggedIn();
            return
        } else {
            await this.loadItems()
        }
        onMetaUpdate({metaTitle: 'Twoje subskrypcje'})
    }

    loadItems = async () => {
        this.changeState("isLoaded", false);
        const currentState = {
            ...this.state
        };

        try {
            currentState.items = await getUserActiveSubscriptions(0);
        } catch (error) {
            currentState.errorMessage = "pobieranie danych nie powiodło się";
            console.log(error);
        }
        currentState.isLoaded = true;
        this.setState(currentState)
    }

    renderItem = (item) => {
        const validFrom = new Date(item.valid_from);
        const validTo = new Date(item.valid_to);

        return (
            <li className="list-group-item" key={item.id}>
                <Badge bg={"warning"}>{item.duration_days} dni</Badge> &nbsp;
                Ważna od: {validFrom.toISOString().split('T')[0]} do {validTo.toISOString().split('T')[0]}
            </li>
        )
    }

    render = () => {
        const {items, isLoaded, errorMessage} = this.state;

        if (errorMessage) {
            return <ErrorMessage message={errorMessage}/>
        }

        if (!isLoaded) {
            return <Loader/>
        }

        const hasSubscription = items.length > 0

        return (
            <div className="content_container">
                <h2>Twoje subskrypcje</h2>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                <div className="container">
                                    <div className="row">
                                        <div className={"col-md-12 text-center"}>
                                            {hasSubscription ?
                                                <>
                                                    <Link className={"btn_green"} to={routes.diets}>
                                                        Przejdź do listy Twoich diet</Link>
                                                    <hr/>
                                                </> :
                                                ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={"col-md-12"}>
                                            <ul className="list-group list-group-flush">
                                                {
                                                    items.length > 0 ?
                                                        items.map((item) => {
                                                            return this.renderItem(item)
                                                        }) :
                                                        <p>Brak aktywnych subskrypcji (jeśli właśnie dokonałeś zakupu -
                                                            sprawdź proszę ponownie później, potwierdzenie z systemu
                                                            płatności może przyjść za kilka sekund / minut).</p>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={"col-md-12 text-center"}>
                                            <hr/>
                                            <Link className={"btn_default"} to={routes.subscriptions}>
                                                {hasSubscription ? 'Przedłuż subskrypcję' : 'Kup subskrypcję'}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserSubscriptions;
