import React, {Component} from "react";
import orangeImg from "../../assets/img/oranges.jpg";
import {resetPassword} from "../../services/api/api";
import routes from "../../routes";

class ResetPasswordForm extends Component {

    minLength = 6;

    state = {
        password: "",
        repeated_password: "",
        isSent: false,
        error: ""
    };

    componentDidMount = async () => {
        const {onMetaUpdate, isLoggedIn} = this.props

        if (isLoggedIn) {
            window.location.href = routes.generateDiet;
        }

        onMetaUpdate({metaTitle: 'Ustawianie nowego hasła'})
    }

    handleInputChange = event => {
        const state = {...this.state};
        state[event.target.dataset.id] = event.target.value;
        this.setState(state);
    };

    handleSubmit = async event => {
        event.preventDefault();
        const currentState = {
            ...this.state
        };

        if (this.state.password.length < this.minLength) {
            currentState.error = "hasło musi mieć minimum " + this.minLength + " znaków";
            this.setState(currentState);

            return;
        }
        if (this.state.password !== this.state.repeated_password) {
            currentState.error = "hasła są różne";
            this.setState(currentState);

            return;
        }

        try {
            await resetPassword(this.props.match.params.token, this.state.password)
            currentState.email = "";
            currentState.repeated_password = "";
            currentState.error = "";
            currentState.isSent = true;
        } catch (error) {
            if (error.code === 'validation_failed') {
                currentState.error = 'niepoprawne hasło'
            } else {
                currentState.error = 'wystąpił nieznany błąd'
            }
            currentState.isSent = false;
        }
        this.setState(currentState)
    };

    render() {
        const {error, isSent} = this.state
        const errorMessage = error ?
            <div className="alert alert-danger" role="alert">
                {error}
            </div> :
            '';

        return isSent ?
            (
                <div className="content_container">
                    <h2>Hasło zmienione<br/>możesz się już zalogować za jego pomocą</h2>
                </div>
            ) :
            (
                <div className="content_container">
                    <h2>Ustawianie nowego hasła</h2>

                    <div className="prl_box_top">
                        <div className="prl_box_bg"
                             style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                        </div>
                    </div>

                    <div className="prl_box_middle">
                        <div className="container">
                            <div className="row">
                                <div className="prl_box_mod">
                                    <div className="container">
                                        <div className="row">
                                            <div className={"col-lg-12"}>
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <label htmlFor={"password"}>Nowe hasło</label>
                                                        <input
                                                            type="password"
                                                            data-id="password"
                                                            value={this.state.password}
                                                            onChange={this.handleInputChange}
                                                            className={"form-control text-center"}
                                                            id={"password"}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor={"repeated_password"}>Powtórz hasło</label>
                                                        <input
                                                            type="password"
                                                            data-id="repeated_password"
                                                            value={this.state.repeated_password}
                                                            onChange={this.handleInputChange}
                                                            className={"form-control text-center"}
                                                            id={"repeated_password"}
                                                        />
                                                    </div>
                                                    {errorMessage}
                                                    <button className={"btn_default"}>
                                                        Ustaw hasło
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="prl_box_bottom">
                        <div className="prl_box_bg"
                             style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                        </div>
                    </div>
                </div>
            );
    }
}

export default ResetPasswordForm;