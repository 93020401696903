import React from "react";

import glutenFreeIco from "../../assets/img/ico/ico_gluten_free_medium.png";
import lactoseFreeIco from "../../assets/img/ico/ico_lactose_free_medium.png";
import dairyFreeIco from "../../assets/img/ico/ico_dairy_free_medium.png";
import veganIco from "../../assets/img/ico/ico_vegan_medium.png";
import vegetarianIco from "../../assets/img/ico/ico_vegetarian_medium.png";

import glutenFreeIcoMin from "../../assets/img/ico/ico_gluten_free_min.png";
import lactoseFreeIcoMin from "../../assets/img/ico/ico_lactose_free_min.png";
import dairyFreeIcoMin from "../../assets/img/ico/ico_dairy_free_min.png";
import veganIcoMin from "../../assets/img/ico/ico_vegan_min.png";
import vegetarianIcoMin from "../../assets/img/ico/ico_vegetarian_min.png";

const DietLabels = props => {
    const {min} = props
    const {lactose_free, dairy_free, gluten_free, vegetarian, vegan} = props.item

    const className = min ? "m-2 inline" : "m-3"

    const renderMin = () => {
        return <p className={"text-center"}>
            {gluten_free ?
                <img src={glutenFreeIcoMin} alt={"bez glutenu"} title={"bez glutenu"} className={className}/> : ""}
            {lactose_free ?
                <img src={lactoseFreeIcoMin} alt={"bez laktozy"} title={"bez laktozy"} className={className}/> : ""}
            {dairy_free ?
                <img src={dairyFreeIcoMin} alt={"bez nabiału"} title={"bez nabiału"} className={className}/> : ""}
            {vegetarian ?
                <img src={vegetarianIcoMin} alt={"wegetariańska"} title={"wegetariańska"} className={className}/> : ""}
            {vegan ?
                <img src={veganIcoMin} alt={"wegańska"} title={"wegańska"} className={className}/> : ""}
        </p>
    }

    const renderMedium = () => {
        return <div className={"container"}>
            <div className={"row"}>
                {gluten_free ?
                    <div className={"col inline"}>
                        <img src={glutenFreeIco} alt={"bez glutenu"} title={"bez glutenu"} className={className}/>
                        <br/>bez glutenu
                    </div> : ""}
                {lactose_free ?
                    <div className={"col inline"}>
                        <img src={lactoseFreeIco} alt={"bez laktozy"} title={"bez laktozy"} className={className}/>
                        <br/>bez laktozy
                    </div> : ""}
                {dairy_free ?
                    <div className={"col inline"}>
                        <img src={dairyFreeIco} alt={"bez nabiału"} title={"bez nabiału"} className={className}/>
                        <br/>bez nabiału
                    </div> : ""}
                {vegetarian ?
                    <div className={"col inline"}>
                        <img src={vegetarianIco} alt={"wegetariańska"} title={"wegetariańska"} className={className}/>
                        <br/>wegetariańska
                    </div> : ""}
                {vegan ?
                    <div className={"col inline"}>
                        <img src={veganIco} alt={"wegańska"} title={"wegańska"} className={className}/>
                        <br/>wegańska
                    </div> : ""}
            </div>
        </div>
    }

    return min ? renderMin() : renderMedium()
};

export default DietLabels;