import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {
    addTempDishComponent,
    deleteTempDishComponent,
    productAutocomplete,
    resetTempDish,
    saveTempDish,
    tempDish,
} from "../../services/api/api";
import AsyncSelect from 'react-select/async';
import DishComponents from "../Dish/DishComponents";
import vegeImg from "../../assets/img/vege_image.jpg"
import calc3Img from "../../assets/img/calc3.png"
import {faArrowAltCircleLeft} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../Loader/Loader";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import DishNutrition from "../Dish/DishNutrition";
import AuthError from "../../error/authError";
import routes from "../../routes";
import ErrorWithCode from "../../error/errorWithCode";
import PaywallError from "../../error/paywallError";

class CalorieCalculator extends AbstractComponent {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            autocompleteText: "",
            productId: null,
            productAmount: "",
            isLoaded: false,
            tempDish: null,
            saveErrorMessage: null,
            errorMessage: null,
            formErrorMessage: null,
            editedProduct: null,
            asyncSelectValue: null,
            title: "Kalkulator kalorii",
            meal1: false,
            meal2: false,
            meal3: false,
            meal4: false,
            meal5: false,
            notLoggedIn: false,
        };
    };

    componentDidMount = async () => {
        const {title} = this.state

        const {onMetaUpdate} = this.props

        onMetaUpdate({
            metaTitle: title,
            metaCanonical: routes.calorieCalculator,
            metaDescription: 'Skomponuj własną potrawę, oblicz kaloryczność oraz zawartość makroelementów i zapisz ją - jeśli chcesz aby była dostępna w generatorze diet.'
        })

        await this.loadItems()
    }

    loadItems = async () => {
        this.changeState("isLoaded", false);
        const currentState = {
            ...this.state
        };

        try {
            currentState.tempDish = await tempDish();
            currentState.isLoaded = true;
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.isLoaded = true;
                currentState.notLoggedIn = true;
            } else {
                currentState.errorMessage = "pobieranie danych nie powiodło się";
            }
        }
        this.setState(currentState)
    };

    handleAutocomplete = (inputValue) => {
        this.changeState("autocompleteText", inputValue);

        return inputValue;
    };

    handleSelect = value => {
        this.amountInput.focus();
        const currentState = {...this.state};
        currentState.productId = value.value;
        currentState.asyncSelectValue = value;

        this.setState(currentState);
    };

    handleRemoveComponent = async (component) => {
        const {onNotLoggedIn} = this.props
        const currentState = {...this.state}
        try {
            currentState.tempDish = await deleteTempDishComponent(component.product.id)
            currentState.saveErrorMessage = null
            currentState.errorMessage = null
            currentState.formErrorMessage = null
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else {
                currentState.errorMessage = "operacja nie powiodła się";
            }
        }
        this.setState(currentState);
    }

    handleEditComponent = (dishComponent) => {
        const currentState = {...this.state}
        currentState.productId = dishComponent.product.id
        currentState.productAmount = dishComponent.amount
        this.amountInput.focus()
        currentState.editedProduct = dishComponent.product.name
        this.setState(currentState);
    }

    handleSubmit = async event => {
        const {onNotLoggedIn} = this.props
        event.preventDefault();
        const currentState = {...this.state}
        if (!this.state.productId || !this.state.productAmount) {
            currentState.errorMessage = "oba pola są wymagane";
        } else {
            try {
                currentState.tempDish = await addTempDishComponent(parseInt(this.state.productId), parseFloat(this.state.productAmount))
                currentState.productId = null
                currentState.productAmount = ""
                currentState.autocompleteText = null;
                currentState.editedProduct = null;
                currentState.asyncSelectValue = null;
                currentState.saveErrorMessage = null
                currentState.errorMessage = null
                currentState.formErrorMessage = null
            } catch (error) {
                if (error instanceof AuthError) {
                    currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                    onNotLoggedIn()
                } else {
                    currentState.errorMessage = "operacja nie powiodła się";
                }
            }
        }
        this.setState(currentState);
        this.productSelect.focus();
    }

    handleReset = async () => {
        const {onNotLoggedIn} = this.props
        const currentState = {...this.state}
        try {
            currentState.tempDish = await resetTempDish()
            currentState.saveErrorMessage = null
            currentState.errorMessage = null
            currentState.formErrorMessage = null
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else {
                currentState.errorMessage = "operacja nie powiodła się";
            }
        }
        this.setState(currentState);
    }

    handleSaveAsNewDish = async () => {
        const {onNotLoggedIn, onPaywall} = this.props
        const currentState = {...this.state}
        try {
            const categoryMap = {
                meal1: 1,
                meal2: 2,
                meal3: 3,
                meal4: 4,
                meal5: 5,
            }
            let categories = []
            Object.keys(categoryMap).forEach((field) => {
                if (currentState[field] === true) {
                    categories.push(categoryMap[field])
                }
            })

            let slug = await saveTempDish(categories)
            currentState.tempDish = await tempDish()
            this.handleRedirect(routes.recipe + "/" + slug)
        } catch (error) {
            if (error instanceof PaywallError) {
                onPaywall()
            } else if (error instanceof AuthError) {
                currentState.saveErrorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else if (error instanceof ErrorWithCode) {
                if (error.code === "categories_required") {
                    currentState.saveErrorMessage = "proszę wskaż przynajmniej jeden posiłek";
                } else {
                    currentState.saveErrorMessage = "operacja nie powiodła się";
                }

            } else {
                console.log(error)
                currentState.saveErrorMessage = "operacja nie powiodła się";
            }
        }
        this.setState(currentState)
    }

    loadOptions = async (inputValue, callback) => {
        const data = []
        if (inputValue.length > 0) {
            try {
                const products = await productAutocomplete(inputValue);
                Object.keys(products).forEach(function (key) {
                    data.push({
                        value: key,
                        label: products[key]
                    });
                });
            } catch (error) {
                console.log(error)
            }
        }
        callback(data)
    };

    handleCancelEdit = () => {
        const currentState = {...this.state}
        currentState.editedProduct = null
        currentState.productAmount = ""
        this.setState(currentState)
    }

    render = () => {
        const {onNotLoggedIn} = this.props
        const {
            saveErrorMessage,
            errorMessage,
            tempDish,
            isLoaded,
            editedProduct,
            asyncSelectValue,
            title,
            meal1,
            meal2,
            meal3,
            meal4,
            meal5,
            notLoggedIn
        } = this.state
        if (!isLoaded) {
            return errorMessage ? <ErrorMessage message={errorMessage}/> : <Loader/>
        }
        const hasComponents = tempDish && tempDish.components !== null && tempDish.components.length > 0

        return (
            <div className="content_container">
                {this.renderRedirect()}
                <h2>{title}</h2>

                <p className={"seo_text"}>
                    Twojej ulubionej potrawy nie ma w naszej bazie, a chciałbyś wiedzieć ile ma kalorii? Nic prostszego
                    -
                    przygotowaliśmy dla Ciebie kalkulator, który nie tylko policzy kalorie, ale również udostępni
                    informacje
                    na temat tego ile białka, tłuszczu i węglowodanów zawiera.
                </p>
                <p className={"seo_text"}>
                    Jeśli chcesz, możesz następnie zapisać ją na swoim koncie, aby nasz algorytm uwzględnił ją podczas
                    generowania Twoich diet.
                </p>

                <div className="calc1">
                    <div className="vege_calc"
                         style={{background: 'url(' + vegeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="calc2">
                    <div className="container">
                        {notLoggedIn === true ?
                            <div className="row">
                                <p className={"text-center"}>
                                    <button onClick={onNotLoggedIn} className="btn_login">Zaloguj</button>
                                </p>
                            </div> :
                            <div className="row">
                                <div className="col-md-7">
                                    <p>Dodaj składnik</p>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group row">
                                            <div className="col-sm-8">
                                                {editedProduct ?
                                                    (
                                                        <div>
                                                            <FontAwesomeIcon
                                                                icon={faArrowAltCircleLeft}
                                                                onClick={this.handleCancelEdit}
                                                                title={"cofnij"}
                                                                alt={"cofnij"}
                                                            /> &nbsp;
                                                            <strong>Edytujesz ilość produktu: {editedProduct}</strong>
                                                        </div>
                                                    ) :
                                                    <AsyncSelect
                                                        placeholder={"produkt..."}
                                                        ref={(input) => {
                                                            this.productSelect = input;
                                                        }}
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={this.loadOptions}
                                                        onInputChange={this.handleAutocomplete}
                                                        onChange={this.handleSelect}
                                                        value={asyncSelectValue}
                                                    />
                                                }
                                            </div>
                                            <div className="col-sm-4">
                                                <input placeholder={"ilość g/ml"}
                                                       className="form-control"
                                                       id={"prod_amount"}
                                                       type="number"
                                                       data-id={"productAmount"}
                                                       ref={(input) => {
                                                           this.amountInput = input;
                                                       }}
                                                       onChange={this.handleInputChange}
                                                       value={this.state.productAmount}/>
                                            </div>
                                            <div className="col-sm-12">
                                                <small className="text-muted">Brak interesującego Cię produktu? Napisz
                                                    do
                                                    nas na adres <a
                                                        href={"mailto:kontakt@dieterian.com"}>kontakt@dieterian.com</a> -
                                                    zajmiemy się tym!</small>
                                            </div>
                                        </div>
                                        {errorMessage ? <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div> : ''}
                                        <p className={'mt-3'}>
                                            <button className="btn_green">Dodaj</button>
                                        </p>
                                        <hr/>
                                    </form>
                                    <DishComponents
                                        dish={tempDish}
                                        onDeleteComponent={this.handleRemoveComponent}
                                        onEditComponent={this.handleEditComponent}
                                        withNutritionInfo={true}
                                        onNotLoggedIn={onNotLoggedIn}
                                    />
                                    <hr/>
                                    <button className={"btn_orange"} onClick={() => {
                                        if (window.confirm('Czy na pewno zresetować kalkulator?')) this.handleReset()
                                    }}>Resetuj kalkulator
                                    </button>

                                    {hasComponents ? <>
                                        <hr/>
                                        <p className={"text-center"}>
                                            <strong>Jeśli chcesz, możesz zapisać powyższe składniki jako nową
                                                potrawę. <br/>
                                                W tym celu wybierz posiłki, w których generator diet ma ją
                                                uwzględnić.</strong>
                                        </p>

                                        <div className={"row text-center mt-5 mb-5"}>
                                            <div className="col-md-2">
                                                <p className={"text-center"}>
                                                    Posiłki:
                                                </p>
                                            </div>
                                            <div className="col-md-2">
                                                <p className={"text-center"}>
                                                    <label className={"form-check-label"}
                                                           htmlFor="meal1">Śniadanie</label>
                                                    <br/>
                                                    <input type={"checkbox"}
                                                           id={"meal1"}
                                                           data-id={"meal1"}
                                                           checked={meal1}
                                                           onChange={this.handleInputChange}/>
                                                </p>
                                            </div>
                                            <div className="col-md-2">
                                                <p className={"text-center"}>
                                                    <label className={"form-check-label"} htmlFor="meal2">II
                                                        Śniadanie</label>
                                                    <br/>
                                                    <input type={"checkbox"}
                                                           id={"meal2"}
                                                           data-id={"meal2"}
                                                           checked={meal2}
                                                           onChange={this.handleInputChange}/>
                                                </p>
                                            </div>
                                            <div className="col-md-2">
                                                <p className={"text-center"}>
                                                    <label className={"form-check-label"} htmlFor="meal3">Obiad</label>
                                                    <br/>
                                                    <input type={"checkbox"}
                                                           id={"meal3"}
                                                           data-id={"meal3"}
                                                           checked={meal3}
                                                           onChange={this.handleInputChange}/>
                                                </p>
                                            </div>
                                            <div className="col-md-2">
                                                <p className={"text-center"}>
                                                    <label className={"form-check-label"}
                                                           htmlFor="meal4">Podwieczorek</label>
                                                    <br/>
                                                    <input type={"checkbox"}
                                                           id={"meal4"}
                                                           data-id={"meal4"}
                                                           checked={meal4}
                                                           onChange={this.handleInputChange}/>
                                                </p>
                                            </div>
                                            <div className="col-md-2">
                                                <p className={"text-center"}>
                                                    <label className={"form-check-label"}
                                                           htmlFor="meal5">Kolacja</label>
                                                    <br/>
                                                    <input type={"checkbox"}
                                                           id={"meal5"}
                                                           data-id={"meal5"}
                                                           checked={meal5}
                                                           onChange={this.handleInputChange}/>
                                                </p>
                                            </div>
                                        </div>

                                        {saveErrorMessage ? <div className="alert alert-danger" role="alert">
                                            {saveErrorMessage}
                                        </div> : ''}

                                        <button className={"btn_green"} onClick={this.handleSaveAsNewDish}>
                                            Zapisz jako nową potrawę
                                        </button>
                                    </> : ""}
                                </div>
                                <div className="col-md-5">
                                    <div className="mod_calc">
                                        <DishNutrition dish={tempDish}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="calc3">

                    <div className="vege_calc"
                         style={{background: 'url(' + calc3Img + ') no-repeat center center fixed'}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default CalorieCalculator;
