import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import axios from "../../services/axios/axios";
import endpoints from "../../services/api/endpoints";
import DishComponents from "./DishComponents";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loader from "../Loader/Loader";
import PageContent from "../PageContent/PageContent";
import PaywallError from "../../error/paywallError";
import AuthError from "../../error/authError";
import {isAdmin} from "../../services/User/User";
import {Link} from "react-router-dom";
import routes from "../../routes";
import {Badge, Button} from "react-bootstrap";
import NutritionInfo from "../NutritionInfo/NutritionInfo";
import DishCategories from "./DishCategories";
import {tempDishFromDish} from "../../services/api/api";
import FoodPreference from "../FoodPreference/FoodPreference";

class DishDetails extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoaded: false,
            dish: false,
            errorMessage: null,
            isAdmin: isAdmin(),
            isAuthor: false,
            notFound: false,
        };
    };

    componentDidMount = () => {
        const {onNotLoggedIn, onPaywall, onMetaUpdate} = this.props

        const currentState = {
            ...this.state
        };

        axios.get(endpoints.dishBySlug + "/" + this.props.match.params.slug)
            .then((response) => {
                currentState.isAuthor = response.data.is_author;
                currentState.dish = response.data.dish;
                currentState.isLoaded = true;
                this.setState(currentState)
                onMetaUpdate({
                    metaTitle: response.data.dish.name,
                    metaCanonical: routes.recipe + "/" + currentState.dish.slug,
                    metaDescription: "Wartości odżywcze potrawy: Energia: " + currentState.dish.kcal + "kcal. Białko: " + currentState.dish.protein.toFixed(2) + "g. " +
                        "Tłuszcz: " + currentState.dish.fat.toFixed(2) + "g. " +
                        "Węglowodany: " + currentState.dish.carbohydrate.toFixed(2) + "g. " +
                        "Błonnik: " + currentState.dish.fiber.toFixed(2) + "g."
                })
            })
            .catch((error) => {
                if (error instanceof PaywallError) {
                    currentState.errorMessage = "Dostęp wymaga aktywnej subskrypcji.";
                    onPaywall()
                } else if (error instanceof AuthError) {
                    currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                    onNotLoggedIn()
                } else {
                    if (error.response && error.response.status && error.response.status === 404) {
                        currentState.errorMessage = "Potrawa nie znaleziona";
                        currentState.notFound = true;
                    } else {
                        currentState.errorMessage = "podczas pobierania danych o potrawie wystąpił błąd";
                    }
                }
                this.setState(currentState)
            });
    };

    render = () => {
        const {isLoaded, errorMessage, dish, notFound} = this.state;

        if (!isLoaded) {
            if (errorMessage) {
                return <ErrorMessage message={errorMessage} withoutRefreshButton={notFound}/>
            } else {
                return <Loader/>
            }
        }

        return <PageContent disableMetaUpdate={true}
                            title={dish.name}
                            itemScope
                            itemType="http://schema.org/Recipe"
                            seoText={!!dish.active ? "" :
                                <center>
                                    <Badge bg="secondary" className={'text-center'}>
                                        potrawa nieaktywna
                                    </Badge>
                                </center>}
        >
            {this.renderDishContent()}
        </PageContent>
    }


    handleTempDishFromDish = async (id) => {
        const {onNotLoggedIn} = this.props;

        try {
            await tempDishFromDish(id)

            window.location.href = routes.calorieCalculator
        } catch (error) {
            if (error instanceof AuthError) {
                onNotLoggedIn()
            } else {
                console.log("handling food preference failed: " + error);
            }
        }
    }

    renderDishContent() {
        const {dish, isAuthor} = this.state;
        const {onNotLoggedIn} = this.props;
        const admin = isAdmin()

        return <>
            <div className={"dish_recipe"}>
                <FoodPreference objectId={dish.id} objectType={"dish"} onNotLoggedIn={onNotLoggedIn}/>
                <hr/>
                {admin ? <p>
                    <Link className={"btn_orange"} to={routes.adminDishForm + '/' + dish.id}>edytuj</Link><br/>
                    Comment: {dish.comment}
                </p> : ""}
                {isAuthor ? <p>
                    <Link className={"btn_orange"} to={routes.updateDish + '/' + dish.slug}>edytuj nazwę lub
                        przepis</Link>
                </p> : ""}
                <p className={"text-center"}>
                    <h2>Przepis:</h2>
                </p>
                <p dangerouslySetInnerHTML={{__html: dish.recipe}} itemProp="recipeInstructions"></p>
            </div>

            <div className={"dish_components"}>
                <p className={"text-center"}>
                    <strong>Występuje jako:</strong>
                </p>
                <DishCategories dish={dish}/>
            </div>
            <div className={"dish_components"}>
                <p className={"text-center"}>
                    <strong>Składniki (na jedną porcję):</strong>
                </p>
                <DishComponents dish={dish} onNotLoggedIn={onNotLoggedIn}/>
                <hr/>
                <h2>Wartości odżywcze:</h2>
                <NutritionInfo nutritionInfo={dish}/>
            </div>
            <hr/>
            <div className={"dish_components"}>
                {dish.description ?
                    <>
                        <h2>Opis potrawy:</h2>
                        <p dangerouslySetInnerHTML={{__html: dish.description}}></p>
                    </>
                    :
                    ''
                }
            </div>
            <hr/>
            <div className={"dish_components"}>
                <strong>Chciał(a)byś zmodyfikować potrawę tak, aby lepiej pasowała do Twojego gustu? A może potrzebujesz
                    czegoś z większą ilością białka? <br/>
                    Mniej / więcej kalorii? Możesz otworzyć ją w kalkulatorze kalorii i zmodyfikować/dodać składniki, a
                    następnie zapisać ją na liście Twoich potraw! Będą one dostępne w Twoim generatorze diet.</strong>
                <Button className={"btn-warning btn-sm"} onClick={() => {
                    this.handleTempDishFromDish(dish.id)
                }}>otwórz w kalkulatorze kalorii</Button>
            </div>
        </>
    }
}

export default DishDetails;
