import React from 'react'
import DishMenuItem from "../Dish/DishMenuItem";
import NutritionInfo from "../NutritionInfo/NutritionInfo";
import {faListAlt} from "@fortawesome/free-regular-svg-icons/faListAlt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const handlePrint = () => {
    window.print();
};

const MenuDay = (props) => {
    const {menu, readOnly, onNotLoggedIn} = props
    const meals = menu.meals;
    const nutritionInfo = menu.nutrition_info;

    return (
        <>
            {menu.energy_percentages_ok ? "" :
                <div className="alert alert-primary d-flex align-items-center" role="alert">
                    <p className={"text-center"}>
                        Niestety nie udało nam się zmieścić w zakładanych przedziałach energii z białka i tłuszczu.
                        Poniżej znajdziesz najbliższy rezultat, jaki udało nam się uzyskać.
                        Wygenerowana dieta zawiera <strong>{nutritionInfo.protein_energy_percentage}% energii z
                        białka</strong> i <strong>{nutritionInfo.fat_energy_percentage}% energii z tłuszczu</strong>.
                        Jeżeli nie spełnia ona Twoich oczekiwań, spróbuj wygenerować dietę na ten dzień ponownie lub
                        zmień oczekiwane parametry.
                    </p>
                </div>}
            <div>
                {
                    Object.keys(meals).map((key, index) => {
                        return <DishMenuItem
                            key={index}
                            dish={meals[key]}
                            dietId={menu.diet_id}
                            dishSetId={menu.dish_set_id}
                            mealNumber={parseInt(key)}
                            readOnly={readOnly}
                            onNotLoggedIn={onNotLoggedIn}
                        />
                    })
                }
            </div>
            <NutritionInfo nutritionInfo={nutritionInfo}/>
            <button
                className={"btn_orange no-print"}
                onClick={handlePrint}
            >
                <i className="fa-solid fa-print"></i>
                <FontAwesomeIcon
                    icon={faListAlt}
                    className={"text-danger"}
                />&nbsp;
                Drukuj
            </button>
        </>
    )
}

export default MenuDay;