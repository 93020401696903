import React from "react";

const DishCategories = props => {
    const {dish} = props;

    return (
        <ul className="list-group list-group-flush">
            {dish.categories.map((category, idx) =>
                <>{idx > 0 ? ", " : ""}{category.name}</>
            )}
        </ul>
    );
};

export default DishCategories;
