import React from "react";
import PageContent from "./PageContent";
import routes from "../../routes";

const BlogDietetyczny = props => {
    const {onMetaUpdate} = props;

    return (
        <PageContent
            title={"Blog Dietetyczny Dieterian - Twoje Centrum Wiedzy o Zdrowej Diecie i Skutecznym Odchudzaniu"}
            seoText={"Odkryj świat zdrowego odżywiania z Blogiem Dietetycznym Dieterian. Znajdziesz tu praktyczne porady, smaczne przepisy i naukowo poparte artykuły, które pomogą Ci prowadzić zdrowy styl życia i efektywnie schudnąć. Zainspiruj się i zacznij zdrową podróż z Dieterian!"}
            onMetaUpdate={onMetaUpdate}
            route={routes.blog}
        >
            <p>
                Zdrowie zaczyna się na talerzu, a my w Dieterian wierzymy, że zdrowa dieta może być równie smaczna,
                co korzystna dla Twojego organizmu. Nasz Blog Dietetyczny to kompendium wiedzy, które pozwoli Ci
                odkryć tajniki zdrowego stylu życia, dostarczając praktycznych porad, naukowo popartych artykułów
                i apetycznych przepisów. Zanurz się w nasze treści i pozwól, aby każdy posiłek był krokiem ku lepszemu
                samopoczuciu i sylwetce!
            </p>
            <p>
                <ul>
                    <li>
                        <a href={routes.blogZapotrzebowanieKaloryczne}>
                            Jak określić swoje zapotrzebowanie kaloryczne i dlaczego jest to ważne dla skutecznego
                            odchudzania
                        </a>
                    </li>
                    <li>
                        <a href={routes.blogZdrowePrzepisy}>
                            Zdrowe i smaczne przepisy na dietę odchudzającą dostępne na Dieterian.com
                        </a>
                    </li>
                </ul>
            </p>
            <p>
                Dziękujemy za zanurzenie się w świat zdrowych inspiracji z Dieterian.com! Pamiętaj, że zdrowa dieta to
                podróż,
                a nie cel i każdy mały krok, który robisz, jest wartościowy. Dołącz do naszej społeczności na <a
                href={"https://www.facebook.com/dieteriancom"} target={"_blank"}>Facebooku</a> i <a
                href={"https://www.instagram.com/dieteriancom"} target={"_blank"}>Instagramie</a>,
                aby być na bieżąco z najnowszymi przepisami, poradami i inspiracjami. Razem tworzymy miejsce, gdzie
                zdrowy styl życia
                jest prosty i przyjemny. Z nami, zdrowe odżywianie jest łatwe!
            </p>
        </PageContent>
    );
};

export default BlogDietetyczny;