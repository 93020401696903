import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import {generateDiet} from "../../services/api/api";
import routes from "../../routes";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";
import HelpMuted from "../Help/HelpMuted";
import orangeImg from "../../assets/img/oranges.jpg";
import AuthError from "../../error/authError";
import {
    getDietGeneratorParamsFromState,
    getInitialDietGeneratorState
} from "../../services/DietGenerator/DietGenerator";
import Loader from "../Loader/Loader";
import {hasActiveSubscription} from "../../services/User/User";
import ErrorMessageShort from "../ErrorMessage/ErrorMessageShort";
import {Button} from "react-bootstrap";
import ServiceLocator from "../../services/ServiceLocator/ServiceLocator";
import PaywallError from "../../error/paywallError";

class DietGeneratorForm extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            ...getInitialDietGeneratorState(),
            isLoading: false,
            errorMessage: null,
            title: "Generowanie diety",
            hasActiveSubscription: false,
        };
    }

    componentDidMount = async () => {
        const {title} = this.state
        const {onMetaUpdate} = this.props

        onMetaUpdate({
            metaTitle: title,
            metaDescription: 'Jednym kliknięciem utworzysz dietę z możliwością wymiany menu. Wykluczymy za Ciebie alergeny, weźmiemy pod uwagę Twój gust i preferencje makroelementów! Spróbuj za darmo!',
            metaCanonical: routes.generateDiet
        })

        this.changeState("hasActiveSubscription", await hasActiveSubscription())
    }

    handleGenerate = async (event) => {
        event.preventDefault();
        const {kcal} = this.state
        const {onNotLoggedIn, onPaywall} = this.props
        const currentState = {...this.state}
        currentState.isLoading = true;
        this.setState(currentState);

        const dietParams = getDietGeneratorParamsFromState(this.state)

        try {
            currentState.dietData = await generateDiet(kcal, dietParams);
            currentState.redirect = true;
        } catch (error) {
            if (error instanceof AuthError) {
                currentState.errorMessage = 'Aby kontynuować, zaloguj się proszę.'
                onNotLoggedIn()
            } else if (error instanceof PaywallError) {
                onPaywall()
            } else {
                currentState.errorMessage = 'Nie udało się wygenerować diety dla podanych parametrów. Proszę spróbuj ponownie lub zmień parametry diety.'
            }
        }
        currentState.isLoading = false

        this.setState(currentState)
    }

    //render

    render() {
        const {
            kcal,
            errorMessage,
            isLoading,
            lactoseFree,
            glutenFree,
            dairyFree,
            vegetarian,
            vegan,
            overrideDietParams
        } = this.state

        if (isLoading) {
            return <Loader/>
        }

        return (
            <div className="content_container diet_generator">
                {this.renderRedirectToGeneratedDiet()}
                <div className={"row"}>
                    <div className={"container"}>
                        <h1>Generowanie diety</h1>
                        <p className={"seo_text"}>
                            Poniżej znajdziesz formularz, dzięki któremu za pomocą kilku kliknięć wygenerujesz dla
                            siebie
                            zdrową,
                            maksymalnie dopasowaną do Twojego gustu dietę. Dietę będziesz mogła / mógł następnie
                            zapisać na
                            swoim koncie,
                            edytować oraz generować na jej podstawie listę zakupów.
                        </p>
                        <p className={"seo_text"}>
                            Zaawansowana wersja formularza, którą możesz aktywować poniżej, pozwoli Ci ustawić własne
                            parametry
                            diety:
                            ilość białka, węglowodanów i tłuszczu w Twojej diecie, tak aby dopasować ją jeszcze bardziej
                            do celu,
                            który chcesz osiągnąć,
                            niezależnie od tego czy jest nim utrata kilogramów, czy też przeciwnie - budowa masy.
                        </p>
                    </div>
                </div>

                <div className="prl_box_top">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

                <div className="prl_box_middle">
                    <div className="container">
                        <div className="row">
                            <div className="prl_box_mod">
                                <form onSubmit={this.handleGenerate}>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <p>
                                                <label htmlFor={"mealCount"}>Liczba posiłków dziennie</label>
                                                <select data-id="mealCount"
                                                        value={this.state.mealCount}
                                                        onChange={this.handleInputChange}
                                                        className={"form-control"}
                                                >
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </p>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <p>
                                                <label htmlFor={"kcal"}>Kalorie</label>
                                                <input type="number"
                                                       className="form-control"
                                                       id="kcal"
                                                       data-id="kcal"
                                                       aria-describedby="kcalHelp"
                                                       placeholder="Zakładana liczba kalorii"
                                                       onChange={this.handleInputChange}
                                                       value={kcal}
                                                       min={700}
                                                />
                                            </p>
                                            <HelpMuted id={"kcalHelp"}>
                                                Jeśli nie wiesz, ile kalorii wpisać, specjalnie dla Ciebie
                                                przygotowaliśmy <Link
                                                to={routes.tdeeCalculator}>kalkulator TDEE</Link>, który zrobi to za
                                                Ciebie.
                                            </HelpMuted>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-md-4 col-sm-6">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"} htmlFor="glutenFree">Bez
                                                    glutenu</label> <br/>
                                                <input type={"checkbox"}
                                                       id={"glutenFree"}
                                                       data-id={"glutenFree"}
                                                       checked={glutenFree}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"} htmlFor="dairyFree">Bez
                                                    nabiału</label> <br/>
                                                <input type={"checkbox"}
                                                       data-id={"dairyFree"}
                                                       id={"dairyFree"}
                                                       checked={dairyFree}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"} htmlFor="lactoseFree">Bez
                                                    laktozy</label> <br/>
                                                <input type={"checkbox"}
                                                       data-id={"lactoseFree"}
                                                       id={"lactoseFree"}
                                                       checked={lactoseFree}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-md-4 col-sm-6">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"}
                                                       htmlFor="vegetarian">Wegetariańska</label> <br/>
                                                <input type={"checkbox"}
                                                       id={"vegetarian"}
                                                       data-id={"vegetarian"}
                                                       checked={vegetarian}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <p className={"text-center"}>
                                                <label className={"form-check-label"} htmlFor="vegan">Wegańska</label>
                                                <br/>
                                                <input type={"checkbox"}
                                                       id={"vegan"}
                                                       data-id={"vegan"}
                                                       checked={vegan}
                                                       onChange={this.handleInputChange}/>
                                            </p>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className="mx-auto">
                                            <p className={"text-center"}>

                                                <input className={"form-check-input"}
                                                       type={"checkbox"}
                                                       data-id={"overrideDietParams"}
                                                       id={"overrideDietParams"}
                                                       checked={overrideDietParams}
                                                       onChange={this.handleInputChange}/> &nbsp;
                                                <label className={"form-check-label orange-text font-weight-bold"}
                                                       htmlFor="overrideDietParams">Pokaż zaawansowane
                                                    opcje</label>
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        this.renderOverrideDietParamsForm()
                                    }
                                    {errorMessage ?
                                        <ErrorMessageShort short={true} message={errorMessage}
                                                           withoutRefreshButton={true}/> : ''
                                    }
                                    <div className={"row"}>
                                        <button className={"btn_orange"}>Generuj</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="prl_box_bottom">
                    <div className="prl_box_bg"
                         style={{background: 'url(' + orangeImg + ') no-repeat center center fixed'}}>
                    </div>
                </div>

            </div>
        )
    }

    resetAdvancedDietParams = async () => {
        const container = ServiceLocator.getInstance();
        container.dietParamsStorage.setDietParams(null)

        const state = this.getInitialState()
        state.hasActiveSubscription = await hasActiveSubscription()
        this.setState(state)
    }

    renderOverrideDietParamsForm = () => {
        const {
            overrideDietParams,
            fatRangeMax,
            fatRangeMin,
            proteinRangeMax,
            proteinRangeMin,
            hasActiveSubscription
        } = this.state
        if (!overrideDietParams) {
            return null;
        }

        return (
            <>
                <hr/>
                {hasActiveSubscription ? '' :
                    <Link to={routes.subscriptions} className="btn_try" style={{width: '80%', marginTop: 0}}>
                        Kup dostęp już za 7,42 PLN na miesiąc,<br/> aby odblokować zaawansowane opcje
                    </Link>
                }
                <div className="row">
                    <div className="row">
                        <div className="col">
                            <Button variant="outline-secondary" size="sm" onClick={this.resetAdvancedDietParams}>przywróć
                                domyślne</Button>
                        </div>
                    </div>
                    <div className="col">
                        <p>
                            <label htmlFor={"proteinRangeMin"}>min % dziennej energii z białka </label>
                            <input type="number"
                                   data-id="proteinRangeMin"
                                   id="proteinRangeMin"
                                   value={proteinRangeMin}
                                   onChange={this.handleInputChange}
                                   className={"form-control"}
                                   disabled={!hasActiveSubscription}
                            />
                        </p>
                    </div>
                    <div className="col">
                        <p>
                            <label htmlFor={"proteinRangeMax"}>max % dziennej energii z białka </label>
                            <input type="number"
                                   data-id="proteinRangeMax"
                                   id="proteinRangeMax"
                                   value={proteinRangeMax}
                                   onChange={this.handleInputChange}
                                   className={"form-control"}
                                   disabled={!hasActiveSubscription}
                            />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>
                            <label htmlFor={"fatRangeMin"}>min % dziennej energii z tłuszczu </label>
                            <input type="number"
                                   data-id="fatRangeMin"
                                   id="fatRangeMin"
                                   value={fatRangeMin}
                                   onChange={this.handleInputChange}
                                   className={"form-control"}
                                   disabled={!hasActiveSubscription}
                            />
                        </p>
                    </div>
                    <div className="col">
                        <p>
                            <label htmlFor={"fatRangeMax"}>max % dziennej energii z tłuszczu </label>
                            <input type="number"
                                   data-id="fatRangeMax"
                                   id="fatRangeMax"
                                   value={fatRangeMax}
                                   onChange={this.handleInputChange}
                                   className={"form-control"}
                                   disabled={!hasActiveSubscription}
                            />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="alert alert-primary d-flex align-items-center" role="alert">
                            <p className={"text-center"}>
                                pozostała energia będzie pochodziła z węglowododanów
                            </p>
                        </div>
                    </div>
                </div>
            </>
        )
    };

    renderRedirectToGeneratedDiet = () => {
        const {dietData, redirect} = this.state;

        if (redirect) {
            return <Redirect to={routes.generateDiet + "/" + dietData.diet.id}/>
        }
    };
}

export default DietGeneratorForm