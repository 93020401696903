import React from "react";
import {Link} from "react-router-dom";
import routes from "../../routes";
import {getUserName, isAdmin, isTempUser} from "../../services/User/User";

const MenuMobile = props => {
    const {onLogout, onLogin, isLoggedIn, onHideMenu} = props;
    const userName = getUserName()
    const isTemp = isTempUser()
    const isAdminUser = isAdmin()

    return (
        <div className="menu-wrap">
            <nav className="menu-top">
                {isLoggedIn ? <div className="profile">{userName}<br/><Link to={"#"} onClick={onLogout}>
                    [ wyloguj ]
                </Link></div> : <></>}
            </nav>
            <nav className="menu-side main_nav">
                <ul className="main_mobile_menu">
                    <li><Link to={routes.generateDiet} onClick={onHideMenu}>Generuj dietę</Link></li>
                    <li><Link to={routes.recipes} onClick={onHideMenu}>Przepisy</Link></li>
                    <li><Link to={routes.calorieCalculator} onClick={onHideMenu}>Kalkulator kalorii</Link></li>
                    <li><Link to={routes.subscriptions} onClick={onHideMenu}>Cennik</Link></li>
                </ul>
                <ul className="login_mobile">
                    {
                        isLoggedIn ?
                            <>
                                {isAdminUser ?
                                    <>
                                        <li>
                                            <Link to={routes.adminProducts} className="dropdown-item">
                                                Produkty [admin]
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={routes.adminDishForm} className="dropdown-item">
                                                Nowa potrawa [admin]
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={routes.adminUsers} className="dropdown-item">
                                                Użytkownicy [admin]
                                            </Link>
                                        </li>
                                    </> : <></>}
                                {!isTemp ? <>
                                    <li><Link to={routes.diets} onClick={onHideMenu}>Twoje diety</Link></li>
                                    <li><Link to={routes.recipes + '/twoje'} onClick={onHideMenu}>Twoje
                                        przepisy</Link></li>
                                    <li><Link to={routes.progressLog} onClick={onHideMenu}>Wyniki diety</Link></li>
                                    <li><Link to={routes.shoppingLists} onClick={onHideMenu}>Listy zakupów</Link>
                                    </li>
                                    <li><Link to={routes.userSubscriptions} onClick={onHideMenu}>Subskrypcja</Link>
                                    </li>
                                    <li><Link to={routes.changePassword} onClick={onHideMenu}>Zmień hasło</Link>
                                    </li>
                                </> : ''}

                                    <li><Link to={routes.foodPreferences + "/ulubione"}
                                              onClick={onHideMenu}>Ulubione</Link></li>
                                    <li><Link to={routes.foodPreferences + "/nielubiane"}
                                              onClick={onHideMenu}>Nielubiane</Link></li>
                                    <li>&nbsp;</li>
                                </>
                                :
                                <li>
                                    <button className={"action-button action-button-green"} onClick={onLogin}>
                                        Zaloguj
                                    </button>
                                </li>
                        }
                    </ul>
                </nav>
            </div>
        );
    }
;

export default MenuMobile;