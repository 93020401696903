import React from "react";
import FoodPreference from "../FoodPreference/FoodPreference";
import {Link} from "react-router-dom";
import routes from "../../routes";
import DishNutrition from "./DishNutrition";
import DietLabels from "../DietLabels/DietLabels";
import {Badge, Button} from "react-bootstrap";
import {createDishVariant, duplicateDish} from "../../services/api/api";

const DishListItem = props => {
    const {dish, button, button2, onNotLoggedIn, isAdmin} = props;

    const handleDuplicateDish = async (id) => {
        const resp = await duplicateDish(id)

        window.location.href = routes.adminDishForm + "/" + resp.id
    }

    const handleCreateDishVariant = async (id) => {
        const resp = await createDishVariant(id)

        window.location.href = routes.adminDishForm + "/" + resp.id
    }

    return (
        <>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <Link className={"dish_name"}
                              to={routes.recipe + '/' + dish.slug}><strong>{dish.name}</strong>
                        </Link><br/>
                        {!!dish.active ? "" :
                            <Badge bg="secondary" className={'text-center'}>potrawa nieaktywna</Badge>}<br/>
                        {button ? button : ""}
                        {button2 ? button2 : ""}
                        <DietLabels item={dish} min={true}/>
                        <FoodPreference objectId={dish.id} objectType={"dish"} onNotLoggedIn={onNotLoggedIn}/>
                        {isAdmin ? <>
                            <Link className={"btn btn-danger"}
                                  to={routes.adminDishForm + '/' + dish.id}>edytuj
                            </Link>
                            <Button className={"btn-warning"} onClick={() => {
                                handleDuplicateDish(dish.id)
                            }}>duplikuj</Button>
                            <Button className={"btn-info"} onClick={() => {
                                handleCreateDishVariant(dish.id)
                            }}>dodaj wariant</Button><br/>
                            <div className="alert alert-primary d-flex align-items-center" role="alert">
                                <p className={"text-center"}>
                                    Comment: {dish.comment}
                                </p>
                            </div>
                        </> : ""}
                    </div>
                    <div className={"col"}>
                        <DishNutrition dish={dish}/>
                    </div>
                </div>
            </div>
            <hr/>
        </>
    );
};


export default DishListItem;